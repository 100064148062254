import { PersonName } from '@local/api'

export const formatFullName = (name: string): string => {
  const [lastName, firstName] = name.split(',').map((part) => part.trim()) // Split by comma and trim whitespace
  return `${firstName} ${lastName}`
}

export const formatNameObject = ({
  chosen,
  first,
  middle,
  last,
  formatted
}: PersonName) =>
  formatted || [chosen || first, middle, last].filter((s) => s).join(' ')
