import * as React from 'react'
import { Children } from 'react'
import map from 'lodash/map'
import {
  Table,
  Body,
  Head,
  HeadingCell,
  Row,
  Cell
} from '@toasttab/buffet-pui-table'
import cx from 'classnames'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const isWrapped = (el: any, Class: any) => el?.type === Class

type RowProps = {
  children: React.ReactNode
  onClick?: () => void
}

const TableRow = ({ onClick, children }: RowProps) => {
  return (
    <Row onClick={onClick} className='hover:bg-sky-700'>
      {Children.map(children, (item) => {
        if (isWrapped(item, Cell)) return item
        return <Cell>{item}</Cell>
      })}
    </Row>
  )
}

type HeadCellProps = {
  children: React.ReactNode
  isMain?: boolean
}

const HeadCell = ({ children, isMain }: HeadCellProps) => {
  return (
    <HeadingCell
      className={cx('text-secondary type-default', {
        'min-w-table-main-cell': isMain
      })}
    >
      {children}
    </HeadingCell>
  )
}

type Props = {
  columns?: React.ReactNode[]
  children: React.ReactNode
  loading?: boolean
  isPosEmployeeLoading?: boolean
  emptyState?: React.ReactNode
}

type MsgProps = {
  children: React.ReactNode
  colSpan: number
}
const Msg = ({ children, colSpan }: MsgProps) => {
  return (
    <Row>
      <Cell colSpan={colSpan}>
        <div className='flex justify-center py-4'>{children}</div>
      </Cell>
    </Row>
  )
}

const EmployeeTableLegacy = (props: Props) => {
  const { columns, loading, isPosEmployeeLoading, children } = props

  const numColumns = columns?.length || 1

  const displayColumns = map(columns, (column, index) => {
    const key = `quick-table-heading-cell-${column}-${index}`

    if (isWrapped(column, HeadCell)) {
      return <React.Fragment key={key}>{column}</React.Fragment>
    }

    return <HeadCell key={key}>{column}</HeadCell>
  })

  const hasChildren = React.Children.count(children) > 0

  const content =
    loading || isPosEmployeeLoading ? (
      <Msg colSpan={numColumns}>
        <MerryGoRound />
      </Msg>
    ) : (
      hasChildren && children
    )

  return (
    hasChildren && (
      <Table className='table-auto' valign='middle' variant='plain'>
        <Head>
          <Row>{displayColumns}</Row>
        </Head>
        <Body>{content}</Body>
      </Table>
    )
  )
}

EmployeeTableLegacy.Row = TableRow
EmployeeTableLegacy.HeadCell = HeadCell
EmployeeTableLegacy.Cell = Cell

export default EmployeeTableLegacy
