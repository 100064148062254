import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

type Props = {
  text: String
  className: string
  maxWidth: string
}

const TruncatedText = ({ text, className, maxWidth }: Props) => {
  const childRef = useRef<any>(null)
  const [isTruncated, setTruncated] = useState(false)

  useEffect(() => {
    if (!childRef.current) return
    setTruncated(childRef.current?.offsetWidth < childRef.current?.scrollWidth)
  }, [childRef.current?.offsetWidth, setTruncated])

  return (
    <Tooltip accessibility='decorative'>
      <Tooltip.Trigger tabIndex={isTruncated ? 0 : -1}>
        <div
          className={cx('truncate', className)}
          ref={childRef}
          style={{
            maxWidth: maxWidth
          }}
        >
          {text}
        </div>
      </Tooltip.Trigger>
      {isTruncated && <Tooltip.Content>{text}</Tooltip.Content>}
    </Tooltip>
  )
}

export default TruncatedText
