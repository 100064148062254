import React, { useState } from 'react'
import { useEmployeesListInitializeQuery } from '../../data'
import EmployeeListPageLayout from './EmployeeListPageLayout'
import { EmailSendBulkModal, EmployeeListPagination } from '../../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  EmailIcon,
  FilterIcon,
  PayrollEmployeeIcon
} from '@toasttab/buffet-pui-icons'
import EmployeeListFiltersModal from './EmployeeListFiltersModal'
import useEmployeeListFiltersState from './useEmployeeListFiltersState/useEmployeeListFiltersState'
import { useRedirect, useTranslation } from '../../hooks'
import ResponsiveEmployeeTable from './ResponsiveEmployeeTable'
import TeamActions from '../../components/TeamPagesNavBar/TeamActions'
import { Alert } from '@toasttab/buffet-pui-alerts'
import AddEmployeeModalWrapper from '../../AddEmployeeModal/AddEmployeeModalWrapper'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useFeature } from '@toasttab/ec-features'
import { useUser } from '@toasttab/ec-session'
import { PosEmployeeModal } from '@toasttab/ec-employee-management'
import {
  useInvalidateEmployeeList,
  useCombinedEmployeeListQuery
} from '../../data/useCombinedEmployeeListQuery'
import { useSearchParams } from 'react-router-dom'
import { useGeneratePayrollLink } from '../../hooks/useGeneratePayrollLink'
import { useIgnorePosUserInPayrollMutation } from '@local/api'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { v4 as uuidv4 } from 'uuid'
import {
  EmployeeListSelectedProvider,
  useEmployeeListSelected
} from './EmployeeListSelectedContext'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { BulkEmailSendResp } from '../../data/types'

const AlertPosEmployees = ({
  numPosEmployees,
  hrAndAboveUser
}: {
  numPosEmployees: number
  hrAndAboveUser: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Alert
      outlined
      className='w-full'
      variant='error'
      testId={'unmapped-pos-alert'}
    >
      {t(
        numPosEmployees === 1
          ? 'unmappedEmployeeError'
          : 'unmappedEmployeesError',
        {
          docCount: numPosEmployees
        }
      )}
      {hrAndAboveUser && (
        <a
          href={
            'https://central.toasttab.com/s/article/Toast-Payroll-How-to-Use-the-Employee-Mapping-Tool#how_to_map_employees'
          }
          target='_blank'
          rel='noreferrer'
          className='text-default'
        >
          <button className='inline-link-inherit'>{t('learnMore')}</button>
        </a>
      )}
    </Alert>
  )
}

const EmployeeInviteBulkButton = ({ onClick }: { onClick: () => void }) => {
  const { selectedIds } = useEmployeeListSelected()
  const { t } = useTranslation()

  return (
    <Button
      testId={'invite-button-bulk'}
      aria-label='Invite-Email'
      disabled={selectedIds.size === 0}
      onClick={onClick}
      variant='link'
      iconLeft={<EmailIcon accessibility='decorative' size='sm' />}
      className={selectedIds.size === 0 ? '!cursor-default' : undefined}
    >
      {t('employeeTable.actions.invite')}
    </Button>
  )
}

const EmployeeListApp = ({ companyCode }: { companyCode: string }) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const mappingStatusFeature = useFeature('ec-elm-teams-list-mapping-status')
  const ignoreMappingFeature = useFeature('ec-elm-ignore-in-payroll')
  const { isUserSuperAdmin, isUserSuperAdminLight } = useUser()
  const isAdmin = isUserSuperAdmin || isUserSuperAdminLight

  const [ignorePosUser] = useIgnorePosUserInPayrollMutation()

  const [searchParams] = useSearchParams()
  const redirectLink = useGeneratePayrollLink(
    searchParams.get('toastIdentityGuid'),
    searchParams.get('toastRestaurantGuid')
  )

  useRedirect({
    href: redirectLink || '/',
    enabled: redirectLink !== null
  })

  const { invalidateEmployeeList } = useInvalidateEmployeeList()
  const { showSuccessSnackBar, showErrorSnackBar, closeSnackBar } =
    useSnackBar()

  const initializeQuery = useEmployeesListInitializeQuery({ companyCode })

  const initialFilters = initializeQuery.data?.obj?.filters
  const isInitalizing = initializeQuery.isLoading

  const {
    filters,
    setFilters,
    clearFilters,
    defaultFilters,
    numActiveFilters,
    canClearFilters
  } = useEmployeeListFiltersState({ companyCode, initialFilters })

  const positions = initializeQuery.data?.obj.datasources?.positions
  const locations = initializeQuery.data?.obj.datasources?.locations
  const statuses = initializeQuery.data?.obj.datasources?.status

  const listQuery = useCombinedEmployeeListQuery({
    companyCode,
    pageIndex: filters.page,
    locationIds: filters.locationIds,
    positionIds: filters.positionIds,
    statusIds: filters.statusIds,
    pageSize: filters.pageSize,
    from: filters.from,
    until: filters.until,
    enabled: !!initialFilters,
    mappingStatusFeature
  })

  const records = listQuery.data?.obj?.records

  const posEmployeesLength = listQuery.data?.posLength

  const totalRecordsCount = listQuery.data?.obj?.recordsCount

  const pageCount = listQuery.data?.obj?.pageCount

  const [addEmployeeOpen, setAddEmployeeOpen] = useState<boolean>(false)
  const [isPosModalOpen, setIsPosModalOpen] = useState(false)
  const [locationGuid, setLocationGuid] = useState('')
  const [userGuid, setUserGuid] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [bulkSendEmailOpen, setBulkSendEmailOpen] = useState(false)
  const filteringByPositions = filters.positionIds.length > 0
  const [showPosition, setShowPosition] = useState(filteringByPositions)
  const [sentInvites, setSentInvites] = React.useState(new Set<string>())

  React.useEffect(() => {
    if (!showPosition && filteringByPositions) setShowPosition(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringByPositions])

  const DisplayErrorSnackbar = (
    callback: (userGuid: string, displayName: string) => void
  ) => {
    const snackBarId = uuidv4()
    showErrorSnackBar(t('ignoreEmployeeError'), {
      key: snackBarId,
      isDismissable: true,
      action: (
        <button
          onClick={() => {
            closeSnackBar(snackBarId)
            callback(userGuid, displayName)
          }}
        >
          {t('tryAgain')}
        </button>
      )
    })
  }

  const openPosModal = (
    locationGuid: string,
    userGuid: string,
    name: string
  ) => {
    setLocationGuid(locationGuid)
    setUserGuid(userGuid)
    setDisplayName(name)
    setIsPosModalOpen(true)
  }

  const addSentInvites = (items: BulkEmailSendResp) => {
    if (items.emailResults) {
      const results = items.emailResults
      const newSet = new Set<string>([...sentInvites])
      for (const key in results) {
        if (results[key] === 'Sent') {
          newSet.add(key)
        }
      }
      setSentInvites(newSet)
    }
  }

  const loading = isInitalizing || listQuery.isLoading

  const user = useUser()
  const hrAndAboveUser = user.isPureUserHRPlusOrAbove

  const filterButton = (
    <Button
      iconLeft={<FilterIcon accessibility='decorative' />}
      variant='text-link'
      onClick={() => setShowFilters((val) => !val)}
    >
      {t('filters')}
      {!isInitalizing && numActiveFilters ? ` (${numActiveFilters})` : ''}
    </Button>
  )

  const displayFilters = showFilters && (
    <EmployeeListFiltersModal
      filters={filters}
      defaultFilters={defaultFilters}
      onCancel={() => setShowFilters(false)}
      onApply={(filters) => {
        setFilters({ ...filters, page: 1 })
        setShowFilters(false)
      }}
      positions={positions}
      locations={locations}
      statuses={statuses}
    />
  )
  const pagination = (
    <EmployeeListPagination
      page={filters.page}
      pageCount={pageCount}
      onPageChange={(page) => setFilters({ page })}
      totalRecordsCount={totalRecordsCount}
      loading={loading}
    />
  )

  const ignorePosEmployeeInPayroll = async (userGuid: string, name: string) => {
    try {
      setDisplayName(name)
      const { data: ignoreMutationData } = await ignorePosUser({
        variables: { userGuid }
      })
      const ignored = ignoreMutationData?.ignorePosUserInPayroll
      if (ignored) {
        invalidateEmployeeList()
        showSuccessSnackBar(
          t('ignoreEmployeeSuccess', { name: name.split(', ')[1] })
        )
      }
    } catch (e) {
      DisplayErrorSnackbar(ignorePosEmployeeInPayroll)
    }
  }

  return (
    <EmployeeListSelectedProvider>
      <div className='flex flex-col'>
        <div className='mb-6 flex justify-end'>
          <div className='flex items-center mr-4'>
            <span className='text-secondary type-default mr-2'>
              {t('employeeTable.actions.displayPosition')}
            </span>
            <ToggleSwitch
              isActive={showPosition}
              disabled={showPosition && filteringByPositions}
              onChange={() => setShowPosition(!showPosition)}
              aria-label={t('employeeTable.actions.displayPosition')}
              data-metrics-id={`team-page-position-toggle${
                isAdmin ? '-admin' : ''
              }`}
            />
          </div>

          {filterButton}
          {displayFilters}

          <EmployeeInviteBulkButton
            onClick={() =>
              records?.length ? setBulkSendEmailOpen(true) : undefined
            }
          />
          {records?.length > 0 && (
            <EmailSendBulkModal
              companyCode={companyCode}
              open={bulkSendEmailOpen}
              updateSentInvites={addSentInvites}
              onClose={() => setBulkSendEmailOpen(false)}
            />
          )}
        </div>
        {mappingStatusFeature && posEmployeesLength > 0 && (
          <div className='mb-6'>
            <AlertPosEmployees
              numPosEmployees={posEmployeesLength}
              hrAndAboveUser={hrAndAboveUser}
            />
          </div>
        )}

        <EmployeeListPageLayout
          onTitleClick={canClearFilters ? clearFilters : undefined}
        >
          {loading ? (
            <div className='flex items-center justify-center'>
              <MerryGoRound />
            </div>
          ) : records?.length ? (
            <>
              <PosEmployeeModal
                isOpen={isPosModalOpen}
                onCancel={() => setIsPosModalOpen(false)}
                onSuccess={() => {
                  invalidateEmployeeList()
                  showSuccessSnackBar(
                    t('addEmployeeSuccess', {
                      name: displayName.split(', ')[1]
                    })
                  )
                }}
                locationGuid={locationGuid}
                toastUserGuid={userGuid}
              />
              <ResponsiveEmployeeTable
                employees={records}
                companyCode={companyCode}
                isLoading={loading}
                mappingStatusFeature={mappingStatusFeature}
                hrAndAboveUser={hrAndAboveUser}
                ignoreMappingFeature={ignoreMappingFeature}
                hirePosEmployee={(locationGuid, userGuid, name) => {
                  openPosModal(locationGuid, userGuid, name)
                }}
                ignorePosEmployee={(guid, name) =>
                  ignorePosEmployeeInPayroll(guid, name)
                }
                onClickBulkSend={() => setBulkSendEmailOpen(true)}
                showPosition={showPosition}
                sentInvites={sentInvites}
                addSentInvites={addSentInvites}
              />
              {pagination}
            </>
          ) : (
            <>
              <PayrollEmployeeIcon
                accessibility='decorative'
                className='flex items-center justify-center p-4 type-default'
                size='md'
              />
              <div className='flex items-center justify-center pb-2 font-medium type-default'>
                {t('noEmployeeAddedToTeam')}
              </div>
              <div className='flex items-center justify-center pb-6 type-default'>
                {t('noEmployeeAddedToTeamDescription')}
              </div>
              <div className='flex items-center justify-center pb-4'>
                <TeamActions
                  showTeamActionDropdown={false}
                  onOpenAddEmployeeModal={() => setAddEmployeeOpen(true)}
                  isButtonVariantSecondary
                />
                <AddEmployeeModalWrapper
                  isOpen={addEmployeeOpen}
                  onClose={() => setAddEmployeeOpen(false)}
                />
              </div>
            </>
          )}
        </EmployeeListPageLayout>
      </div>
    </EmployeeListSelectedProvider>
  )
}

export default EmployeeListApp
