import { useQuery, useQueryClient } from '@tanstack/react-query'
import { apiCall } from './apiCall'
import type {
  Employee,
  EmployeeListNavigateResp,
  EmployeeRecord
} from './types'
import {
  PosRawEmployee,
  Employee as EmployeeGraph,
  EmployeePaginationResponse
} from '@local/api'
import { POS_UNMAPPED_EMPLOYEES } from '../helpers/pos-unmapped-employees-gql'
import { useCompanyCode } from '@toasttab/ec-session'
import { EMPLOYEES_BY_CUSTOMER_UUID } from '../helpers/employees-by-customer-uuid-gql'

type Id = number

const ACTIVE_STATUS_ID = 1

const applyFiltersToPosRecords = (
  locationIds: number[],
  positionIds: number[],
  statusIds: number[],
  employee: PosRawEmployee
) => {
  if (positionIds.length > 0 || !statusIds.includes(ACTIVE_STATUS_ID)) {
    return false
  }
  if (locationIds.length === 0) {
    return true
  }
  return employee.payrollLocationId
    ? locationIds.includes(employee.payrollLocationId)
    : false
}

const convertPosEmployeesToPayroll = (
  posEmployees: PosRawEmployee[]
): Employee[] => {
  return posEmployees.map((posEmployee, index) => ({
    id: index,
    fullName: `${posEmployee.userName.last}, ${posEmployee.userName.first}`,
    link: '',
    position: '',
    lengthOfService: '',
    email: posEmployee.email,
    phoneNumber: '',
    isPOSEmployee: true,
    restaurantUserGuid: posEmployee.restaurantUserGuid,
    userGuid: posEmployee.userGuid,
    locationGuid: posEmployee.locationGuid,
    payrollLocationId: posEmployee.payrollLocationId
  }))
}

const recordsInfo = (
  response: EmployeeListNavigateResp,
  posEmployees: PosRawEmployee[],
  pageSize: number
) => {
  const recordCount = response.obj
    ? response.obj.recordsCount
    : posEmployees.length

  const posRemainder = posEmployees.length % pageSize
  const posOnlyPages = (posEmployees.length - posRemainder) / pageSize
  const payrollPages = Math.ceil(recordCount / pageSize)
  const pageCountToReturn = payrollPages + posOnlyPages

  return {
    recordsCount: recordCount + posEmployees.length,
    pageCount: pageCountToReturn
  }
}

type Args = {
  companyCode: string | undefined
  pageSize?: number
  pageIndex?: number
  locationIds?: Id[]
  positionIds?: Id[]
  statusIds?: Id[]
  from?: string
  until?: string
  enabled?: boolean
  mappingStatusFeature: boolean
}

// TODO: refactor this to use react query caching
let posEmployeeCache: PosRawEmployee[] | undefined = undefined

type UnmappedPosUsersGraphQLResponse = {
  data: {
    getUnmappedPosUsers: PosRawEmployee[]
  }
}

type EmployeePaginationGraphQLResponse = {
  data: {
    findEmployeesByCustomerUuid: EmployeePaginationResponse
  }
}

const fetchPosEmployees =
  async (): Promise<UnmappedPosUsersGraphQLResponse> => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query:
          POS_UNMAPPED_EMPLOYEES.loc && POS_UNMAPPED_EMPLOYEES.loc.source.body
      })
    } as const

    const fetchResp = await fetch(`/graphql`, options)

    if (fetchResp.ok) {
      return (await fetchResp.json()) as UnmappedPosUsersGraphQLResponse
    }
    throw new Error(fetchResp.statusText || 'Unknown Error')
  }

export const fetchAllEmployees = async (args: Args): Promise<any> => {
  const {
    companyCode,
    pageIndex = 1,
    pageSize = 10,
    locationIds = [],
    positionIds = [],
    statusIds = [],
    from = '',
    until = '',
    mappingStatusFeature
  } = args

  //If we haven't called for the pos employees yet, do so and save results in memory
  if (!posEmployeeCache && mappingStatusFeature) {
    const posResponse = await fetchPosEmployees()
    posEmployeeCache = posResponse.data.getUnmappedPosUsers
  }

  const posEmployeesList = posEmployeeCache
    ? posEmployeeCache.filter((e) =>
        applyFiltersToPosRecords(locationIds, positionIds, statusIds, e)
      )
    : ([] as PosRawEmployee[])

  // Prep a function to easily call in the below scenarios
  const fetchPayrollEmployees = async (pageIndex: number, pageSize: number) => {
    const responseRest = await apiCall<EmployeeListNavigateResp>({
      url: `/mvc/${companyCode}/Team/Dashboard/IndexNavigate`,
      method: 'POST',
      body: {
        pageIndex,
        pageSize,
        searchkey: '',
        sortby: '',
        filters: { from, until, locationIds, positionIds, statusIds }
      }
    })

    if (!responseRest.obj) {
      return responseRest
    }

    // While we are still showing data from both IndexNavigate and GraphQL, merge the results of both queries into one object
    const responseGraph = await fetchPayrollEmployeesGraph(pageIndex, pageSize)
    if (!responseGraph.data?.findEmployeesByCustomerUuid?.employees) {
      return responseRest
    }

    responseRest.obj.records = zipRestAndGraphEmployees(
      responseRest.obj.records,
      responseGraph.data.findEmployeesByCustomerUuid.employees
    )

    return responseRest
  }

  // Replacement function to fetch payroll employees from GraphQL, which should replace `fetchPayrollEmployees`
  const fetchPayrollEmployeesGraph = async (
    pageIndex: number,
    pageSize: number
  ) => {
    // convert date formats to "YYYY-MM-DD"
    const reformattedFrom =
      from != null ? new Date(from).toISOString().split('T')[0] : from
    const reformattedUntil =
      until != null ? new Date(until).toISOString().split('T')[0] : until
    const offset = (pageIndex - 1) * pageSize
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query:
          EMPLOYEES_BY_CUSTOMER_UUID.loc &&
          EMPLOYEES_BY_CUSTOMER_UUID.loc.source.body,
        variables: {
          input: {
            limit: pageSize,
            offset: offset,
            statusIds: statusIds,
            positionIds: positionIds,
            locationIds: locationIds,
            from: reformattedFrom,
            until: reformattedUntil
          }
        }
      })
    } as const

    const fetchResp = await fetch(`/graphql`, options)

    if (fetchResp.ok) {
      return (await fetchResp.json()) as EmployeePaginationGraphQLResponse
    }
    throw new Error(fetchResp.statusText || 'Unknown Error')
  }

  const zipRestAndGraphEmployees = (
    employeesRest: EmployeeRecord[],
    employeesGraph: EmployeeGraph[]
  ): EmployeeRecord[] => {
    return employeesRest.map((employeeRest, index) => {
      employeeRest.employeeGraph = employeesGraph[index]
      return employeeRest
    })
  }

  // Use the list of pos employees to generate a "page"
  const posEmployeesLeft = convertPosEmployeesToPayroll(
    posEmployeesList.slice((pageIndex - 1) * pageSize, pageIndex * pageSize) ||
      []
  )
  const numOfPosEE = posEmployeesList.length || 0

  //Calculate the first page where we will start to see payroll employees
  const minPayrollEmployeeIndex = Math.ceil(numOfPosEE / pageSize)

  if (posEmployeesLeft.length === pageSize) {
    //This will display only POS users
    // If we have enough POS employees to fill a page, still fetch payroll employees to return the correct page count and record count
    const response = await fetchPayrollEmployees(1, pageSize)

    const records = recordsInfo(response, posEmployeesList, pageSize)

    return {
      pageIndex,
      pageSize,
      posLength: posEmployeesList.length,
      obj: {
        pageCount: records.pageCount,
        recordsCount: records.recordsCount,
        records: posEmployeesLeft
      }
    }
  } else if (
    posEmployeesLeft.length > 0 &&
    posEmployeesLeft.length < pageSize
  ) {
    //This will display a mix of POS and payroll users
    const response = await fetchPayrollEmployees(1, pageSize)

    const recordsToReturn = response.obj
      ? response.obj.records.slice(0, pageSize)
      : []

    const records = recordsInfo(response, posEmployeesList, pageSize)

    return {
      pageIndex,
      pageSize,
      posLength: posEmployeesList.length,
      obj: {
        pageCount: records.pageCount,
        recordsCount: records.recordsCount,
        records: [...posEmployeesLeft, ...recordsToReturn]
      }
    }
  } else {
    const posEmployeeCacheLength = posEmployeesList.length === 0

    //This will only display payroll employees
    const updatedIndex = pageIndex - minPayrollEmployeeIndex
    const startingIndex = posEmployeeCacheLength
      ? pageIndex
      : posEmployeeCache && posEmployeeCache.length % pageSize === 0
      ? updatedIndex
      : updatedIndex + 1

    const response = await fetchPayrollEmployees(startingIndex, pageSize)
    const records = recordsInfo(response, posEmployeesList, pageSize)

    return {
      pageIndex,
      pageSize,
      posLength: posEmployeesList.length,
      obj: {
        pageCount: records.pageCount,
        recordsCount: records.recordsCount,
        records: response.obj?.records
      }
    }
  }
}

const useCombinedEmployeeListQuery = (args: Args) => {
  const {
    companyCode,
    pageIndex = 1,
    pageSize = 10,
    locationIds = [],
    positionIds = [],
    statusIds = [],
    from = '',
    until = '',
    enabled,
    mappingStatusFeature
  } = args

  return useQuery({
    queryFn: async () =>
      await fetchAllEmployees({
        companyCode,
        pageIndex,
        locationIds,
        positionIds,
        statusIds,
        pageSize,
        from,
        until,
        enabled,
        mappingStatusFeature
      }),
    queryKey: [
      'company',
      companyCode,
      'employeesList',
      { pageSize, pageIndex, from, until, locationIds, positionIds, statusIds }
    ]
  })
}

const useInvalidateEmployeeList = () => {
  const queryClient = useQueryClient()
  const companyCode = useCompanyCode()

  return {
    invalidateEmployeeList: () => {
      // this item is module scoped so we invalidate it because we changed the values
      posEmployeeCache = undefined
      queryClient.invalidateQueries({
        queryKey: ['company', companyCode, 'employeesList']
      })
    }
  }
}

export { useCombinedEmployeeListQuery, useInvalidateEmployeeList }
