import { EmployeeBadgeIcon } from '@toasttab/buffet-pui-icons'
import EmployeeAvatar from './EmployeeAvatar'
import EmployeeEmail from './EmployeeEmail'
import { Url } from '../redirect-links/helpers'
import TruncatedText from './TruncatedText'

type Props = {
  recordLink?: Url
  /*
   The 'name' prop can be undefined because the employeeGraph is conditional (even though the employeeGraph will always be returned).
   This will be fixed once the project is completed and employeeGraph is not a conditional anymore.
   */
  name: string
  mappingStatusFeature?: boolean
  email: string
  phoneNumber?: string
  employeeNumber?: string
  userInitials: string
  isUnmappedEmployee?: boolean
  isTerminated: boolean
  mobile: boolean
}

const EmployeeName = ({
  recordLink,
  name,
  mappingStatusFeature,
  email,
  phoneNumber,
  employeeNumber,
  userInitials,
  isUnmappedEmployee = false,
  isTerminated,
  mobile
}: Props) => {
  const secondaryTextColor = isTerminated ? 'text-disabled' : 'text-secondary'

  // EmployeeNumbers are usually 8 numerals or fewer. This ensures all typical cases are properly displayed.
  const truncatedNameMaxWidth = () => {
    if (!mobile) {
      return 'calc(3rem + 20vw)'
    }
    if (isUnmappedEmployee || !employeeNumber) {
      return 'calc(8rem + 20vw)'
    }
    const employeeNumberLength = employeeNumber.length
    if (employeeNumberLength >= 8) {
      return 'calc(2rem + 20vw)'
    }
    if (employeeNumberLength >= 6) {
      return 'calc(3rem + 20vw)'
    }
    if (employeeNumberLength >= 4) {
      return 'calc(4rem + 20vw)'
    }
    if (employeeNumberLength >= 2) {
      return 'calc(5rem + 20vw)'
    }
    return 'calc(6rem + 20vw)'
  }

  return (
    <div className='flex items-center'>
      {!mobile && (
        <EmployeeAvatar
          userInitials={userInitials}
          isTerminated={isTerminated}
        />
      )}
      <div className='flex flex-col'>
        <div className='flex items-center'>
          <TruncatedText
            text={name}
            className={`${
              isTerminated ? 'text-disabled' : 'text-default'
            } font-semibold mr-1`}
            maxWidth={truncatedNameMaxWidth()}
          />
          {!isUnmappedEmployee && (
            <>
              <EmployeeBadgeIcon
                accessibility='decorative'
                className={`flex mr-1 ${secondaryTextColor}`}
                size={'xs'}
              />
              <div className={secondaryTextColor}>{employeeNumber}</div>
            </>
          )}
        </div>
        <EmployeeEmail
          mappingStatusFeature={mappingStatusFeature}
          recordLink={recordLink}
          email={email}
          isTerminated={isTerminated}
          isUnmappedEmployee={isUnmappedEmployee}
          mobile={mobile}
        />
        <div className={`${secondaryTextColor} type-subhead`}>
          {isUnmappedEmployee ? 'Unpaid employee' : phoneNumber}
        </div>
      </div>
    </div>
  )
}

export default EmployeeName
