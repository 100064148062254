import * as React from 'react'
import {
  Table,
  Body,
  Head,
  HeadingCell,
  Row,
  Cell,
  HeadingCellProps,
  RowProps
} from '@toasttab/buffet-pui-table'
import cx from 'classnames'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const TableRow = ({
  onClick,
  className,
  ...props
}: RowProps & {
  onClick?: () => void
}) => {
  return (
    <Row
      onClick={onClick}
      {...props}
      className={cx(className, 'hover:bg-sky-700')}
    />
  )
}

const HeadCell = ({ children, className, ...props }: HeadingCellProps) => {
  return (
    <HeadingCell
      {...props}
      noMinWidth
      className={cx(className, 'text-secondary type-default')}
    >
      {children}
    </HeadingCell>
  )
}

const Msg = () => {
  return (
    <Row>
      {/* arbitrary massive colspan so it covers full table regardless of # of
      elements */}
      <Cell colSpan={100}>
        <div className='flex justify-center py-4'>
          <MerryGoRound />
        </div>
      </Cell>
    </Row>
  )
}

const EmployeeTable = ({
  columns,
  children
}: {
  columns: React.ReactNode
  children: React.ReactNode
}) => (
  <Table className='table-fixed' valign='middle' variant='plain'>
    <Head>
      <Row>{columns}</Row>
    </Head>
    <Body>{children}</Body>
  </Table>
)

EmployeeTable.Row = TableRow
EmployeeTable.HeadCell = HeadCell
EmployeeTable.Cell = Cell
EmployeeTable.Loader = Msg

export default EmployeeTable
