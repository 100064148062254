import { gql } from '@apollo/client'

export const EMPLOYEES_BY_CUSTOMER_UUID = gql`
  query FindEmployeesByCustomerUuid($input: EmployeePaginationInput!) {
    findEmployeesByCustomerUuid(employeeInput: $input) {
      totalCount
      hasNextPage
      hasPreviousPage
      employees {
        user {
          id
          name {
            first
            middle
            last
          }
          email
        }
        contact {
          telephoneNumber {
            telephoneNumber
          }
        }
        employment {
          employeeNumber
          totalLengthOfService {
            startDate
            endDate
          }
          employmentStatus {
            __typename
            ... on ActiveEmploymentStatus {
              hireDate
              adjustedHireDate
            }
            ... on TerminatedEmploymentStatus {
              lastDayOfEmployment
            }
          }
        }
        jobAssignments {
          locationName
          locationId
          isPrimary
          name
          payGroupId
        }
        payGroupAssignments {
          payGroupId
          workTaxLocationId
        }
      }
    }
  }
`
