import {
  Fein,
  JobAssignment,
  PayGroupAssignment,
  PayrollLocation
} from '@local/api'

type Props = {
  jobAssignments: JobAssignment[] | undefined
  payGroupAssignments: PayGroupAssignment[] | undefined
  locations: PayrollLocation[] | undefined
  feins: Fein[] | undefined
  isTerminated: boolean
}

const EmployeeJob = (props: Props) => {
  const {
    jobAssignments,
    payGroupAssignments,
    locations,
    feins,
    isTerminated
  } = props

  if (!jobAssignments || jobAssignments.length === 0) {
    return <></>
  }

  const primaryJob =
    jobAssignments.find((jobAssignment) => jobAssignment.isPrimary) ||
    jobAssignments[0]
  const additionalJobCount = jobAssignments.length - 1

  const primaryLocation = primaryJob.locationName

  const primaryPaygroup = payGroupAssignments?.find(
    (payGroupAssignment) =>
      payGroupAssignment.payGroupId === primaryJob.payGroupId
  )

  const primaryBusiness = feins?.find((fein) =>
    fein.workTaxLocationIds.find(
      (locationId) => locationId === primaryPaygroup?.workTaxLocationId
    )
  )

  const primaryJobDisplay = (
    <div className={isTerminated ? 'text-disabled' : 'text-default'}>
      {primaryJob.name} {additionalJobCount > 0 ? `+${additionalJobCount}` : ''}
    </div>
  )

  const secondaryTextColor = isTerminated ? 'text-disabled' : 'text-secondary'

  return (
    <div>
      {primaryJobDisplay}
      {(locations?.length || 1) > 1 && (
        <div className={`${secondaryTextColor} type-default`}>
          {primaryLocation}
        </div>
      )}
      {(feins?.length || 1) > 1 && (
        <div className={`${secondaryTextColor} type-subhead`}>
          {primaryBusiness?.name}
        </div>
      )}
    </div>
  )
}

export default EmployeeJob
