import * as React from 'react'
import { Employee } from '../../data/types'

type SelectedIds = Set<string>

type EmployeeListSelected = {
  selectedIds: SelectedIds
  toggleId: (selected: string, employee: Employee) => void
  toggleIds: (selected: SelectedIds, employees: Employee[]) => void
  clearAllIds: () => void
  // WARNING -- We only add this to the context because we don't currently support a full employee cache,
  // making it difficult to retrieve info about employees on other selected pages.
  // This is a code smell (you shouldn't store more info than you need + it makes the setters less versatile), but we use this for now instead
  // because it's too much refactoring to easily access the full EE cache from just IDs otherwise.
  // This can be deleted once the EE cache is converted to use Apollo.
  selectedEmployeesMap: Record<string, Employee | undefined>
}

const EmployeeListSelectedContext = React.createContext<EmployeeListSelected>({
  selectedIds: new Set(),
  toggleId: () => {},
  toggleIds: () => {},
  clearAllIds: () => {},
  selectedEmployeesMap: {}
})

export const EmployeeListSelectedProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const [selectedIds, setSelectedIds] = React.useState<SelectedIds>(new Set())
  const [selectedEmployeesMap, setSelectedEmployeesMap] = React.useState<
    Record<string, Employee | undefined>
  >({})

  const toggleId = (selected: string, employee: Employee) => {
    const newSelectedIds = new Set(selectedIds)
    const newSelectedEmployeesMap = { ...selectedEmployeesMap }
    if (newSelectedIds.has(selected)) {
      newSelectedIds.delete(selected)
      newSelectedEmployeesMap[selected] = undefined
    } else {
      newSelectedIds.add(selected)
      newSelectedEmployeesMap[selected] = employee
    }

    setSelectedIds(newSelectedIds)
    setSelectedEmployeesMap(newSelectedEmployeesMap)
  }

  const toggleIds = (toggledIds: SelectedIds, employees: Employee[]) => {
    const newSelectedIds = new Set(selectedIds)
    const newSelectedEmployeesMap = { ...selectedEmployeesMap }

    Array.from(toggledIds).forEach((selected, i) => {
      if (newSelectedIds.has(selected)) {
        newSelectedIds.delete(selected)
        newSelectedEmployeesMap[selected] = undefined
      } else {
        newSelectedIds.add(selected)
        newSelectedEmployeesMap[selected] = employees[i]
      }
    })
    setSelectedIds(newSelectedIds)
    setSelectedEmployeesMap(newSelectedEmployeesMap)
  }

  const clearAllIds = () => setSelectedIds(new Set())

  return (
    <EmployeeListSelectedContext.Provider
      value={{
        selectedIds,
        toggleId,
        toggleIds,
        clearAllIds,
        selectedEmployeesMap
      }}
    >
      {children}
    </EmployeeListSelectedContext.Provider>
  )
}

export const useEmployeeListSelected = () =>
  React.useContext(EmployeeListSelectedContext)
