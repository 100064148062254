import { DoneIcon, EmailIcon } from '@toasttab/buffet-pui-icons'
import { IconButtonWithTooltip } from '@toasttab/buffet-pui-tooltip'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useTranslation } from '@local/translations'
import { BulkEmailSendResp } from '../data/types'
import useSendPayrollInviteEmailMutation from '../data/useSendPayrollInviteEmailMutation'
import { isEmailValid } from '../helpers/email'

type Props = {
  email: string
  userUuid: string | undefined
  companyCode: string
  fullName?: string
  sentInvites: Set<string>
  updateSentInvites: (items: BulkEmailSendResp) => void
}

const EmployeeInviteEmail = ({
  email,
  userUuid,
  companyCode,
  fullName,
  sentInvites,
  updateSentInvites
}: Props) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()

  const userIds = userUuid === undefined ? [] : [userUuid]
  const emailValid = isEmailValid(email)
  const alreadySent = (userUuid && sentInvites.has(userUuid)) || false

  const onCompleted = () => {
    showSuccessSnackBar(
      t('sendEmailInviteSuccess', { employeeName: fullName }),
      {
        showIcon: true
      }
    )
  }

  const onError = () => {
    showErrorSnackBar(t('sendEmailInviteFailure', { employeeName: fullName }), {
      showIcon: true
    })
  }

  const sendEmail = useSendPayrollInviteEmailMutation({
    companyCode: companyCode,
    userUuids: userIds,
    onSuccess: onCompleted,
    onError: onError
  })

  const onClickSend = async () => {
    const response = await sendEmail.mutateAsync().catch((e) => console.log(e))
    if (updateSentInvites && response) {
      updateSentInvites(response)
    }
  }

  return (
    <div>
      <IconButtonWithTooltip
        testId={'invite-button'}
        aria-label='Invite-Email'
        data-metrics-id='team-page-email'
        data-ee-user-id={userUuid}
        disabled={!emailValid || alreadySent}
        onClick={onClickSend}
        icon={
          alreadySent ? (
            <DoneIcon
              testId={`email-invite-sent-${email}`}
              accessibility='semantic'
              aria-label='Invite Sent'
              className={'ui-state-disabled text-disabled cursor-default'}
              size='sm'
            />
          ) : (
            <EmailIcon
              testId={`email-invite-${email}`}
              accessibility='semantic'
              aria-label='Invite User'
              className={`${
                !emailValid
                  ? 'ui-state-disabled text-disabled cursor-default'
                  : 'ui-state-active text-secondary'
              }`}
              size='sm'
            />
          )
        }
        tooltipAccessibility='label'
        tooltipContent={
          alreadySent
            ? t('inviteSentTooltip')
            : !emailValid
            ? t('noEmailTooltip')
            : t('payrollInviteTooltip')
        }
        tooltipPlacement='bottom'
        tooltipContentDisabled={false}
      />
    </div>
  )
}

export default EmployeeInviteEmail
