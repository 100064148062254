import * as React from 'react'
import useResizeObserver from 'use-resize-observer'
import {
  EmployeeTable,
  EmployeeName,
  EmployeeViewProfile,
  EmployeeInviteEmail
} from '../../components'
import { useTranslation } from '../../hooks'
import { MoreHorizIcon, WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { BulkEmailSendResp, Employee } from '../../data/types'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import EmployeeJob from '../../components/EmployeeJob'
import { formatFullName } from '../../helpers'
import { useEmployeeListSelected } from './EmployeeListSelectedContext'
import { isEmailValid } from '../../helpers/email'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useCustomerConfigurationLocationsQuery } from '../../hooks/useCustomerConfigurationLocationsQuery'
import { useFeinsQuery } from '../../hooks/useFeinsQuery'

const useNumColumns = (width: number) => {
  if (width >= 500) return 2
  return 1
}

type Props = {
  employees?: Employee[]
  isLoading?: boolean
  mappingStatusFeature?: boolean
  ignoreMappingFeature: boolean
  hrAndAboveUser: boolean
  companyCode: string
  hirePosEmployee: (
    locationGuid: string,
    userGuid: string,
    name: string
  ) => void
  ignorePosEmployee: (guid: string, name: string) => void
  onClickBulkSend: () => void
  showPosition: boolean
  sentInvites: Set<string>
  addSentInvites: (items: BulkEmailSendResp) => void
}

const EmployeeHeaderCells = ({
  mobile,
  employees,
  showPosition
}: {
  mobile: boolean
  showPosition: boolean
  employees?: Employee[]
}) => {
  const { selectedIds, toggleIds } = useEmployeeListSelected()
  const { t } = useTranslation()

  const payrollEmployees = employees?.filter((ee) => !ee.isPOSEmployee)

  const checkedOnPage = !payrollEmployees
    ? []
    : payrollEmployees.filter((ee) =>
        selectedIds.has(ee.employeeGraph?.user?.id!)
      )

  const checkboxState =
    checkedOnPage.length === 0
      ? 'unchecked'
      : checkedOnPage.length === payrollEmployees!.length
      ? 'checked'
      : 'indeterminate'

  const toggleAllEmployees = () => {
    if (!payrollEmployees) {
      // employees still loading, no-op
      return
    }

    if (checkboxState === 'unchecked') {
      toggleIds(
        new Set(payrollEmployees.map((ee) => ee.employeeGraph?.user?.id!)),
        payrollEmployees
      )
    } else {
      toggleIds(
        new Set(checkedOnPage.map((ee) => ee.employeeGraph?.user?.id!)),
        checkedOnPage
      )
    }
  }

  const checkbox = (
    <Checkbox
      aria-labelledby='none'
      aria-label={t('employeeTable.header.checkboxLabel')}
      checked={checkboxState !== 'unchecked'}
      indeterminate={checkboxState === 'indeterminate'}
      onChange={toggleAllEmployees}
      disabled={!payrollEmployees || payrollEmployees.length === 0}
    />
  )

  return mobile ? (
    <>
      <EmployeeTable.HeadCell className='w-[10%]'>
        {checkbox}
      </EmployeeTable.HeadCell>
      <EmployeeTable.HeadCell>
        {t('employeeTable.header.name')}
      </EmployeeTable.HeadCell>
    </>
  ) : (
    <>
      <EmployeeTable.HeadCell className='w-[5%]'>
        {checkbox}
      </EmployeeTable.HeadCell>
      <EmployeeTable.HeadCell className={showPosition ? 'w-4/12' : 'w-5/12'}>
        {t('employeeTable.header.name')}
      </EmployeeTable.HeadCell>
      <EmployeeTable.HeadCell
        className={showPosition ? 'w-3/12' : 'w-5/12'}
        colSpan={showPosition ? 1 : 2}
      >
        <div className='flex items-center'>
          {t('employeeTable.header.job')}{' '}
          <InfoTooltip placement='right'>{t('jobTooltip')}</InfoTooltip>
        </div>
      </EmployeeTable.HeadCell>
      {showPosition && (
        <EmployeeTable.HeadCell className='w-3/12' colSpan={2}>
          <div className='flex items-center'>
            {t('employeeTable.header.position')}
          </div>
        </EmployeeTable.HeadCell>
      )}
    </>
  )
}

const UnmappedEmployeeRow = ({
  id,
  name,
  mobile,
  options,
  showPosition
}: {
  mobile: boolean
  id: number | string
  name: React.JSX.Element
  options: React.JSX.Element
  showPosition: boolean
}) => {
  if (mobile) {
    return (
      <EmployeeTable.Row key={id}>
        <EmployeeTable.Cell>
          <WarningOutlineIcon
            className='text-error'
            accessibility='decorative'
            testId='unmapped-pos-employee-warning-icon'
          />
        </EmployeeTable.Cell>
        <EmployeeTable.Cell>
          <div className='w-full flex flex-row justify-between'>
            {name}
            <div className='flex items-center float-right'>{options}</div>
          </div>
        </EmployeeTable.Cell>
      </EmployeeTable.Row>
    )
  }

  return (
    <EmployeeTable.Row key={id}>
      <EmployeeTable.Cell>
        <WarningOutlineIcon
          className='text-error'
          accessibility='decorative'
          testId='unmapped-pos-employee-warning-icon'
        />
      </EmployeeTable.Cell>
      <EmployeeTable.Cell colSpan={showPosition ? 3 : 2}>
        {name}
      </EmployeeTable.Cell>
      <EmployeeTable.Cell>
        <div className='float-right'>{options}</div>
      </EmployeeTable.Cell>
    </EmployeeTable.Row>
  )
}

const Job = ({ job }: { job: string | React.JSX.Element }) => {
  return <div className='py-1'>{job}</div>
}

const EmployeeRow = ({
  id,
  employee,
  name,
  job,
  view,
  mobile,
  invite,
  showPosition,
  position
}: {
  id: string
  mobile: boolean
  showPosition: boolean
  employee: Employee
  name: React.JSX.Element
  job: string | React.JSX.Element
  view: React.JSX.Element
  invite: React.JSX.Element
  position: string
}) => {
  const { selectedIds, toggleId } = useEmployeeListSelected()
  const isSelected = selectedIds.has(id)

  const checkbox = (
    <Checkbox
      aria-labelledby={`ee-${id}`}
      checked={isSelected}
      onChange={() => toggleId(id, employee)}
    />
  )

  if (mobile) {
    return (
      <>
        <EmployeeTable.Row key={id} className={'border-none'}>
          <EmployeeTable.Cell>{checkbox}</EmployeeTable.Cell>
          <EmployeeTable.Cell style={{ paddingBottom: 0 }}>
            <div className='flex flex-col'>
              <div className='flex flex-row justify-between w-full'>
                <div id={`ee-${id}`}>{name}</div>
                <div className='flex items-center'>
                  {invite}
                  {view}
                </div>
              </div>
              <hr className={'border-t border-gray-25 my-2 w-full'} />
            </div>
          </EmployeeTable.Cell>
        </EmployeeTable.Row>
        <EmployeeTable.Row key={`${id}-job`}>
          <EmployeeTable.Cell></EmployeeTable.Cell>
          <EmployeeTable.Cell style={{ paddingTop: 0 }}>
            <Job job={job} />
            {showPosition && (
              <>
                <hr className={'border-t border-gray-25 my-2 w-full'} />
                <div className='py-1'>{position}</div>
              </>
            )}
          </EmployeeTable.Cell>
        </EmployeeTable.Row>
      </>
    )
  }

  return (
    <EmployeeTable.Row key={id}>
      <EmployeeTable.Cell>{checkbox}</EmployeeTable.Cell>
      <EmployeeTable.Cell id={`ee-${id}`}>{name}</EmployeeTable.Cell>
      <EmployeeTable.Cell>
        <Job job={job} />
      </EmployeeTable.Cell>
      {showPosition && (
        <EmployeeTable.Cell>
          <div className='py-1'>{position}</div>
        </EmployeeTable.Cell>
      )}
      <EmployeeTable.Cell>
        <div className='flex float-right items-center p-0'>
          {invite}
          {view}
        </div>
      </EmployeeTable.Cell>
    </EmployeeTable.Row>
  )
}

const EmployeeRows = ({
  companyCode,
  employees,
  mappingStatusFeature,
  mobile,
  showPosition,
  sentInvites,
  addSentInvites
}: {
  companyCode: string
  employees: Employee[]
  mappingStatusFeature?: boolean
  mobile: boolean
  showPosition: boolean
  sentInvites: Set<string>
  addSentInvites: (items: BulkEmailSendResp) => void
}) => {
  const feins = useFeinsQuery()
  const locations = useCustomerConfigurationLocationsQuery()
  const payrollEmployees = employees?.filter((value) => !value.isPOSEmployee)

  return payrollEmployees.map((employee) => {
    const {
      link,
      email,
      phoneNumber,
      position,
      /*
       The employeeGraph should always be populated. The reason it's conditional is because we are currently fetching the legacy employee data from esx-web first,
       and then augmenting it with the employeeGraph data from GQL. By the time you are accessing the data from ResponsiveEmployeeTable.tsx, it will be populated. 
       When we are finished with this project we can clean up the code so that it isn't conditional anymore.
       */
      employeeGraph
    } = employee
    const employeeName = employeeGraph?.user?.name
    const fullName = [
      employeeName?.first,
      employeeName?.middle,
      employeeName?.last
    ]
      .filter(Boolean)
      .join(' ')
    const firstInitial = employeeName?.first?.[0] || '' // First letter of the first name
    const lastInitial = employeeName?.last?.[0] || '' // First letter of the last name
    const userInitials = `${firstInitial}${lastInitial}`
    const isTerminatedEmployee =
      employeeGraph?.employment?.employmentStatus?.__typename ===
      'TerminatedEmploymentStatus'

    const job = (
      <EmployeeJob
        jobAssignments={employeeGraph?.jobAssignments}
        payGroupAssignments={employeeGraph?.payGroupAssignments}
        locations={locations}
        feins={feins}
        isTerminated={isTerminatedEmployee}
      />
    )

    const view = <EmployeeViewProfile recordLink={link} />

    const invite = (
      <EmployeeInviteEmail
        companyCode={companyCode}
        userUuid={employeeGraph?.user?.id}
        email={email}
        fullName={`${employeeName?.first} ${employeeName?.last}`}
        sentInvites={sentInvites}
        updateSentInvites={addSentInvites}
      />
    )

    const name = (
      <EmployeeName
        mappingStatusFeature={mappingStatusFeature}
        name={fullName}
        recordLink={link}
        email={email}
        phoneNumber={phoneNumber}
        userInitials={userInitials}
        employeeNumber={employeeGraph?.employment?.employeeNumber as string}
        isTerminated={isTerminatedEmployee}
        mobile={mobile}
      />
    )

    const id = employeeGraph?.user?.id!

    return (
      <EmployeeRow
        key={employeeGraph?.user?.id!}
        {...{
          id,
          employee,
          name,
          job,
          view,
          mobile,
          invite,
          showPosition,
          position
        }}
      />
    )
  })
}

const EmployeeRowsPOS = ({
  mobile,
  showPosition,
  ...props
}: Props & {
  mobile: boolean
}) => {
  const { ecNavigate } = useEcProps()
  const { t } = useTranslation()

  const {
    employees,
    companyCode,
    hrAndAboveUser,
    ignoreMappingFeature,
    ignorePosEmployee,
    hirePosEmployee,
    mappingStatusFeature
  } = props

  if (!mappingStatusFeature) return null

  const posEmployees = employees?.filter((value) => value.isPOSEmployee)!

  return posEmployees.map(
    ({
      fullName,
      email: emailAddress,
      restaurantUserGuid,
      userGuid,
      locationGuid
    }) => {
      if (!restaurantUserGuid) return null

      // Get the unmapped employee's initials
      const nameParts = fullName.split(',').map((part) => part.trim())
      const initials = nameParts
        .reverse()
        .map((part) => part.charAt(0))
        .join('')

      const customedName = (fullName: string) => {
        return (
          <div>
            <div
              className='flex flex-wrap items-center space-x-1'
              data-testid={`unmapped-pos-employee-${restaurantUserGuid}`}
            >
              <EmployeeName
                name={formatFullName(fullName)}
                isUnmappedEmployee={true}
                email={emailAddress}
                userInitials={initials}
                isTerminated={false}
                mobile={mobile}
              />
            </div>
          </div>
        )
      }
      const navigateToMapping = () => {
        ecNavigate(`/mvc/${companyCode}/Company/SystemSettings/ToastEmployees`)
      }

      const unmappedEmployeeOptions = (
        <MenuDropdown
          testId={`unmapped-pos-menu-${restaurantUserGuid}`}
          {...props}
          renderToggle={(iconButtonProps) => (
            <IconButton
              icon={
                <MoreHorizIcon
                  className='text-secondary'
                  accessibility='decorative'
                />
              }
              {...iconButtonProps}
            />
          )}
        >
          {hrAndAboveUser && (
            <ListItem
              onClick={() => navigateToMapping()}
              label={t('mergeEmployee')}
              data-testid={`unmapped-pos-merge-employee-${restaurantUserGuid}`}
            />
          )}
          {ignoreMappingFeature && (
            <ListItem
              onClick={() => userGuid && ignorePosEmployee(userGuid, fullName)}
              label={t('ignoreEmployee')}
              data-testid={`unmapped-pos-ignore-employee-${restaurantUserGuid}`}
            />
          )}
          <ListItem
            onClick={() =>
              locationGuid &&
              userGuid &&
              hirePosEmployee(locationGuid, userGuid, fullName)
            }
            label={t('createNewProfile')}
            data-testid={`unmapped-pos-hire-employee-${restaurantUserGuid}`}
          />
        </MenuDropdown>
      )

      return (
        <UnmappedEmployeeRow
          key={restaurantUserGuid}
          {...{
            id: restaurantUserGuid,
            name: customedName(fullName),
            options: unmappedEmployeeOptions,
            mobile,
            showPosition
          }}
        />
      )
    }
  )
}

const ResponsiveEmployeeTable = (props: Props) => {
  const {
    employees,
    isLoading,
    mappingStatusFeature,
    companyCode,
    showPosition,
    sentInvites,
    addSentInvites
  } = props

  const { t } = useTranslation()

  const { selectedIds } = useEmployeeListSelected()

  const { ref, width = 0 } = useResizeObserver({ box: 'border-box' })

  const mobile = useNumColumns(width) === 1

  if (employees?.length === 0) return null

  const employeesWithValidEmail = employees?.filter(
    (ee) => !ee.isPOSEmployee && isEmailValid(ee.email)
  )

  return (
    <div ref={ref}>
      {selectedIds.size > 0 && employeesWithValidEmail && (
        <Alert variant='neutral' showIcon className='w-full'>
          {/*
            This is technically a non-optimal way of handling translations,
            since you should not assume that the number is at the start of the string.
            However, for our supported languages (English and Spanish), it is true...
            ...and currently the <Trans> component is not supported in this repo
            (The transations/ utility folder is out of date and needs to use the updated
            buffet-pui-translation-utilities packages.) Until that update happens,
            this is a simple workaround.
          */}
          <span className='font-semibold'>{selectedIds.size}</span>{' '}
          {t('employeeTable.bulkSelectAlert.employeesSelected')}
          <button
            onClick={props.onClickBulkSend}
            className='inline-link-inherit'
          >
            {t('employeeTable.bulkSelectAlert.invite')}
          </button>
        </Alert>
      )}

      <EmployeeTable
        columns={
          <EmployeeHeaderCells
            showPosition={showPosition}
            mobile={mobile}
            employees={employees}
          />
        }
      >
        {isLoading || !employees ? (
          <EmployeeTable.Loader />
        ) : (
          <>
            <EmployeeRowsPOS
              {...{
                ...props,
                mobile
              }}
            />
            <EmployeeRows
              {...{
                companyCode,
                employees,
                mobile,
                showPosition,
                mappingStatusFeature,
                sentInvites,
                addSentInvites
              }}
            />
          </>
        )}
      </EmployeeTable>
    </div>
  )
}

export default ResponsiveEmployeeTable
