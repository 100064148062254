import { IconButtonWithTooltip } from '@toasttab/buffet-pui-tooltip'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import * as singleSpa from 'single-spa'
import { useTranslation } from '../hooks'
import { getRedirectUrl, Url } from '../redirect-links/helpers'

type Props = {
  recordLink: Url
}

const EmployeeViewProfile = ({ recordLink }: Props) => {
  const { t } = useTranslation()

  const handleClick = async (url: string) => {
    if (!url) return
    const redirectUrl = await getRedirectUrl(url)
    if (redirectUrl) {
      singleSpa.navigateToUrl(redirectUrl)
    } else {
      window.location.href = url
    }
  }

  return (
    <div
      className='flex flex-wrap items-center'
      data-testid={`view-profile-button-${recordLink}`}
    >
      <IconButtonWithTooltip
        onClick={() => {
          if (recordLink) {
            handleClick(recordLink)
          }
        }}
        icon={
          <ChevronRightIcon
            testId='chevron-right'
            accessibility='semantic'
            aria-label='View profile'
            className='text-secondary'
          />
        }
        tooltipAccessibility='label'
        tooltipContent={t('viewProfileTooltip')}
        tooltipPlacement='bottom'
      />
    </div>
  )
}

export default EmployeeViewProfile
