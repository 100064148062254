import { useState } from 'react'
import { useEcProps } from '@toasttab/ec-banquet-props'
import {
  getRedirectUrl,
  Url,
  getEmployeeTabUrl
} from '../redirect-links/helpers'
import { PrefetchLink } from '../redirect-links/PrefetchLink'
import { useTranslation } from '../hooks'
import TruncatedText from './TruncatedText'

type Props = {
  recordLink: Url
  email: String
  mappingStatusFeature?: boolean
  isTerminated: boolean
  isUnmappedEmployee: boolean
  mobile: boolean
}

// Add invalid patterns for emails to the array
const INVALID_PATTERNS = ['@example.com']

const EmployeeEmail = ({
  recordLink,
  email,
  mappingStatusFeature,
  isTerminated,
  isUnmappedEmployee,
  mobile
}: Props) => {
  const [hasBeenHovered, setHasBeenHovered] = useState<boolean>(false)

  const { ecNavigate } = useEcProps()

  const { t } = useTranslation()

  const onMouseEnter = () => {
    if (!hasBeenHovered) setHasBeenHovered(true)
  }

  const foundInvalidPattern = INVALID_PATTERNS.some((INVALID_PATTERNS) =>
    email.includes(INVALID_PATTERNS)
  )
  if (!foundInvalidPattern) {
    const maxWidth = mobile
      ? isUnmappedEmployee
        ? 'calc(10rem + 20vw)'
        : 'calc(8rem + 20vw)'
      : 'calc(3rem + 20vw)'

    return (
      <TruncatedText
        text={email}
        className={isTerminated ? 'text-disabled' : 'text-secondary'}
        // hard coded max-width, ideally this fluctuates based on column width
        // but turns out it's very very finicky
        maxWidth={maxWidth}
      />
    )
  }

  if (isUnmappedEmployee) {
    return null
  }

  const handleClick = async (url: string) => {
    if (!url) return
    const redirectUrl = await getRedirectUrl(url)
    const tabRedirectUrl = getEmployeeTabUrl(redirectUrl, '/account')

    if (tabRedirectUrl) {
      ecNavigate(tabRedirectUrl)
    } else {
      window.location.href = url
    }
  }

  const fallback = (
    <a
      onMouseEnter={onMouseEnter}
      href={recordLink}
      onClick={async (evt) => {
        if (recordLink) {
          evt.preventDefault()
          handleClick(recordLink)
        }
      }}
      className={mappingStatusFeature ? 'text-link' : undefined}
    >
      {t('addEmail')}
    </a>
  )

  if (hasBeenHovered) {
    return (
      <PrefetchLink url={recordLink} fallback={fallback} employeeTab='/account'>
        {t('addEmail')}
      </PrefetchLink>
    )
  }

  return fallback
}

export default EmployeeEmail
