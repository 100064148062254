import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: { input: any; output: any }
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: { input: any; output: any }
  /** A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999". */
  Decimal: { input: any; output: any }
  HashId: { input: any; output: any }
  LegacyId: { input: any; output: any }
  Long: { input: any; output: any }
  SpaDate: { input: any; output: any }
  SpaDateTime: { input: any; output: any }
  join__FieldSet: { input: any; output: any }
  link__Import: { input: any; output: any }
}

export type AchConsent = {
  __typename?: 'ACHConsent'
  employee: Maybe<BankConsentEmployee>
  needsConsent: Scalars['Boolean']['output']
  payPeriodUuid: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
}

export type AbsentPayChangeLogTokenError = {
  __typename?: 'AbsentPayChangeLogTokenError'
  message: Maybe<Scalars['String']['output']>
}

export type AccessibleCustomer = {
  __typename?: 'AccessibleCustomer'
  companyCode: Scalars['String']['output']
  name: Scalars['String']['output']
  shardName: Scalars['String']['output']
}

export type AccessibleCustomersInput = {
  cursor: InputMaybe<Scalars['ID']['input']>
  pageSize: InputMaybe<Scalars['Int']['input']>
  search: InputMaybe<Scalars['String']['input']>
}

export type AccessibleCustomersResult = {
  __typename?: 'AccessibleCustomersResult'
  customers: Array<AccessibleCustomer>
  nextCursor: Maybe<Scalars['ID']['output']>
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type Actor = {
  __typename?: 'Actor'
  /** The ID of the actor */
  id: Scalars['ID']['output']
  /** The actor's primary job name */
  jobName: Maybe<Scalars['String']['output']>
  /** The name of the actor */
  name: Maybe<PersonName>
}

export type ActorPermissionsForEmployee = {
  __typename?: 'ActorPermissionsForEmployee'
  employeeId: Scalars['ID']['output']
  permissions: Array<HumanResourcesPermission>
}

export type AdditionalDocumentInput = {
  fileName: Scalars['String']['input']
  filePath: Scalars['String']['input']
}

export type AdditionalEarningResponse =
  | EarningListEmptyError
  | EmployeeNotFoundError
  | JobAssignmentNotFoundError
  | MissingPayGroupError
  | NextPayPeriodNotFoundError
  | NextPaycheckDateNotFoundError
  | Paycheck

export type AggregateInvoiceResult =
  | AggregatePayrollInvoiceError
  | AggregatePayrollInvoiceSuccess

export type AggregatePayrollInvoice = {
  __typename?: 'AggregatePayrollInvoice'
  caption: Scalars['String']['output']
  invoiceTypeId: Scalars['Int']['output']
  totalCharge: Scalars['Decimal']['output']
  totalEmployeeCount: Scalars['Int']['output']
}

export type AggregatePayrollInvoiceError = QueryError & {
  __typename?: 'AggregatePayrollInvoiceError'
  errors: Array<Error>
  status: ResponseStatus
}

export type AggregatePayrollInvoiceSuccess = QuerySuccess & {
  __typename?: 'AggregatePayrollInvoiceSuccess'
  invoices: Array<AggregatePayrollInvoice>
  status: ResponseStatus
}

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AmountWithPayInterval = {
  __typename?: 'AmountWithPayInterval'
  amount: Money
  interval: PayInterval
}

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type ArchiveError = MutationError & {
  __typename?: 'ArchiveError'
  errors: Array<Error>
  status: ResponseStatus
}

export type ArchiveResponse = ArchiveError | ArchiveSuccess

export type ArchiveSuccess = MutationSuccess & {
  __typename?: 'ArchiveSuccess'
  result: Scalars['ID']['output']
  status: ResponseStatus
}

export type Assignee = 'CUSTOMER' | 'ONBOARDING'

export type Audit = {
  __typename?: 'Audit'
  action: AuditAction
  date: Scalars['SpaDateTime']['output']
  postponedDate: Maybe<Scalars['String']['output']>
  whom: Scalars['String']['output']
}

export type AuditAction = 'Checks' | 'Opened' | 'Posted' | 'Postponed' | 'Reset'

export type AvailabilityRequest = {
  email: InputMaybe<Scalars['String']['input']>
  employeeId: InputMaybe<Scalars['ID']['input']>
  employeeNumber: InputMaybe<Scalars['Int']['input']>
  posAccessCode: InputMaybe<Scalars['Int']['input']>
  ssn: InputMaybe<Scalars['String']['input']>
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  email: Maybe<Scalars['Boolean']['output']>
  emailTakenBy: Maybe<EmploymentStatusType>
  employeeNumber: Maybe<Scalars['Boolean']['output']>
  posAccessCode: Maybe<Scalars['Boolean']['output']>
  ssn: Maybe<Scalars['Boolean']['output']>
}

export type BankAccount = {
  __typename?: 'BankAccount'
  lastFour: Scalars['String']['output']
  name: Scalars['String']['output']
  signatureFileName: Maybe<Scalars['String']['output']>
  uuid: Scalars['String']['output']
}

export type BankAccountConsentInfo = {
  __typename?: 'BankAccountConsentInfo'
  bankAccountUuid: Scalars['ID']['output']
  bankName: Scalars['String']['output']
  consents: Maybe<Array<AchConsent>>
  hasSignature: Scalars['Boolean']['output']
  lastFourAccountNumber: Maybe<Scalars['String']['output']>
  payGroupUuid: Scalars['ID']['output']
}

export type BankConsentEmployee = {
  __typename?: 'BankConsentEmployee'
  employeeNumber: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
}

export type Benefit = {
  __typename?: 'Benefit'
  key: Scalars['String']['output']
  label: Scalars['String']['output']
  options: Array<BenefitOption>
}

export type BenefitOption = {
  __typename?: 'BenefitOption'
  label: Scalars['String']['output']
  value: Scalars['ID']['output']
}

export type Benefits = {
  __typename?: 'Benefits'
  acaPlan: Maybe<Scalars['String']['output']>
  benefitPlan: Maybe<Scalars['String']['output']>
  tafwPolicy: Maybe<Scalars['String']['output']>
  washingtonLaI: Maybe<Scalars['String']['output']>
  workersComp: Maybe<Scalars['String']['output']>
}

export type BenefitsInput = {
  acaPlan: InputMaybe<Scalars['String']['input']>
  benefitPlan: InputMaybe<Scalars['String']['input']>
  tafwPolicy: InputMaybe<Scalars['String']['input']>
  washingtonLaI: InputMaybe<Scalars['String']['input']>
  workersComp: InputMaybe<Scalars['String']['input']>
}

export type BenefitsThirdPartyDataSharingConsent = {
  __typename?: 'BenefitsThirdPartyDataSharingConsent'
  version: Scalars['Float']['output']
}

export type BringBackResponse = ResponseError | ResponseSuccess

export type BulkAddEmployeeResponse = {
  __typename?: 'BulkAddEmployeeResponse'
  status: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type BulkAddResponse = {
  __typename?: 'BulkAddResponse'
  employees: Maybe<Array<BulkAddEmployeeResponse>>
}

export type BulkDeductionMutation = {
  amount: Scalars['Float']['input']
  deductionCodeUuid: Scalars['String']['input']
  employeeUuids: InputMaybe<Array<Scalars['String']['input']>>
  isPercentage: Scalars['Boolean']['input']
}

export type BulkEarningMutation = {
  amount: Scalars['Float']['input']
  checkCodeUuid: Scalars['String']['input']
  earningCodeUuid: Scalars['String']['input']
  employeeUuids: Array<Scalars['String']['input']>
  hours: Scalars['Float']['input']
  week: Scalars['Int']['input']
}

export type BulkNotifyResponse = {
  __typename?: 'BulkNotifyResponse'
  employee: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type CannotChangeLinkedUsername = {
  __typename?: 'CannotChangeLinkedUsername'
  userId: Scalars['ID']['output']
}

export type CannotChangeUnlinkedUsername = {
  __typename?: 'CannotChangeUnlinkedUsername'
  userId: Scalars['ID']['output']
}

export type CashRequirement = {
  __typename?: 'CashRequirement'
  totalEERequirement: EmployeeRequirement
  totalTaxes: Array<PayCheckTaxes>
  vendorNetPays: Array<VendorNetPays>
}

export type ChangeLinkedUsernameResult =
  | CannotChangeUnlinkedUsername
  | InvalidUsername
  | PayrollUser
  | ToastVersionIgnored
  | UserNotFound
  | UsernameExists

export type ChangePayInput = {
  effectiveDate: Scalars['Date']['input']
  rate: MoneyInput
}

export type ChangeSalaryAllocationInput = {
  /** Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date */
  effectiveDate: Scalars['Date']['input']
  /** Id of the salary allocation */
  id: Scalars['ID']['input']
  /** New annual rate for this salary allocation */
  newRate: MoneyInput
}

export type ChangeSalaryResponse =
  | EmployeeNotFoundError
  | EmptySalaryAllocationsError
  | InvalidEffectiveDateError
  | Salary
  | SalaryAllocationNotFoundError
  | SalaryNotFoundError

export type ChangeUnlinkedUsernameResult =
  | CannotChangeLinkedUsername
  | InvalidUsername
  | PayrollUser
  | UserNotFound
  | UsernameExists

export type CheckCode = {
  __typename?: 'CheckCode'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CheckCodesConfigQuerySuccess = QuerySuccess & {
  __typename?: 'CheckCodesConfigQuerySuccess'
  checkCodes: Array<CheckCode>
  status: ResponseStatus
}

export type CheckCodesConfigResponse =
  | CheckCodesConfigQuerySuccess
  | QueryErrorResponse

export type CheckPackage = {
  __typename?: 'CheckPackage'
  numberOfChecks: Scalars['Int']['output']
  shippingAddress: ShippingAddress
  workTaxLocations: Array<WorkTaxLocation>
}

export type ChecklistState = {
  __typename?: 'ChecklistState'
  customerUuid: Scalars['ID']['output']
  items: Array<Item>
}

export type CompanyProfile = {
  __typename?: 'CompanyProfile'
  allowedToPost: Scalars['Boolean']['output']
  customerStatus: CustomerStatus
  feins: Array<CustomerFein>
  firstPayrollDate: Scalars['SpaDate']['output']
  numActiveEmployees: Scalars['Int']['output']
  numFeins: Scalars['Int']['output']
  numPayGroups: Scalars['Int']['output']
  numRestaurants: Scalars['Int']['output']
}

export type CompanySummary = {
  __typename?: 'CompanySummary'
  companyProfile: CompanyProfile
  integrationSettings: IntegrationSettings
  payGroups: Array<PayGroupSetting>
  payrollSettings: PayrollSettings
}

export type CompleteDirectDepositEmployee = {
  __typename?: 'CompleteDirectDepositEmployee'
  allocations: Array<DirectDepositAllocation>
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
}

export type CompleteDirectDepositsResponse = {
  __typename?: 'CompleteDirectDepositsResponse'
  employees: Array<CompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type ConfirmVerificationEmailError =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type ConfirmVerificationEmailInput = {
  confirmationKey: Scalars['String']['input']
}

export type ConfirmVerificationEmailResult =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailConfirmed
  | VerificationEmailNotSent

export type ConfirmationExpired = {
  __typename?: 'ConfirmationExpired'
  message: Maybe<Scalars['String']['output']>
}

export type ConfirmedEvent = {
  __typename?: 'ConfirmedEvent'
  emailHash: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  sequence: Scalars['Int']['output']
  toastIdentityGuid: Scalars['String']['output']
}

export type Connection = 'ALTERNATE' | 'DEFAULT'

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']['output']
  telephoneNumber: Scalars['String']['output']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  displayName: Scalars['String']['output']
  isoCode: Scalars['String']['output']
}

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']['input']
  jobTagId: Scalars['ID']['input']
  managementSetId: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
  restaurantJobId: Scalars['ID']['input']
  selectedJobTagValue: Scalars['String']['input']
}

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']['input']
  name: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

/** An `ISO-4217` compliant Currency. */
export type Currency =
  /** Canadian Dollars (CAD). */
  | 'CAD'
  /** Euro (EUR). */
  | 'EUR'
  /** United Kingdom Pounds (GBP). */
  | 'GBP'
  /** United States Dollars (USD). */
  | 'USD'

export type CustomDocumentFieldInput = {
  fieldId: Scalars['Int']['input']
  id: Scalars['ID']['input']
  value: Scalars['String']['input']
}

export type CustomDocumentFields = {
  __typename?: 'CustomDocumentFields'
  documentId: Scalars['ID']['output']
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type CustomDocumentFieldsV2 = {
  __typename?: 'CustomDocumentFieldsV2'
  documentIds: Array<Scalars['ID']['output']>
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Customer = {
  __typename?: 'Customer'
  customerId: Scalars['String']['output']
  /** application features as defined in ToastWeb, related to entitlements */
  enabledFeatures: Array<Scalars['String']['output']>
  feins: Array<PayrollFein>
  id: Scalars['ID']['output']
  locations: Array<Location>
  name: Scalars['String']['output']
  status: CustomerStatus
  /** @deprecated Field no longer supported */
  uuid: Scalars['ID']['output']
}

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration'
  customerPositions: Array<CustomerPosition>
  defaultEarningsIds: Maybe<DefaultEarningsId>
  /** customerUuid */
  id: Scalars['ID']['output']
  locations: Array<PayrollLocation>
  payGroups: Array<PayGroupWithFrequency>
  payGroupsV2: Array<PayGroup>
  payrollSecurityRoles: Array<PayrollSecurityRole>
  workTaxLocations: Array<PayrollWorkTaxLocation>
}

export type CustomerFein = {
  __typename?: 'CustomerFein'
  dayOfWeek: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  isPayrollPayor: IsPayrollPayor
  name: Scalars['String']['output']
  taxFilingEndDate: Maybe<Scalars['SpaDate']['output']>
  taxFilingStartDate: Maybe<Scalars['SpaDate']['output']>
  tin: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']['input']
}

export type CustomerPosition = {
  __typename?: 'CustomerPosition'
  checkCode: PayrollCheckCode
  fein: PayrollFein
  id: Scalars['ID']['output']
  isExempt: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  payGroup: PayGroup
  salaryType: Scalars['String']['output']
}

export type CustomerSetting = {
  __typename?: 'CustomerSetting'
  id: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  BLENDED_OT: Scalars['Boolean']['output']
  CHECK_PRINTING_PAPER_CHECKS_TYPE: Scalars['Int']['output']
  CHECK_PRINTING_SUFFIX: Scalars['String']['output']
  CREDIT_CARD_TIPS_EARNING_CODE: Scalars['Int']['output']
  DEADLINE_ENFORCEMENT: Scalars['Boolean']['output']
  DECLARED_EARNINGS_TIPS_EARNINGCODE: Scalars['Int']['output']
  EEO_ENABLED_IN_NEW_HIRE_ONBOARDING: Scalars['Boolean']['output']
  ENFORCE_TWO_FACTOR_AUTHENTICATION: Scalars['String']['output']
  HAS_PREVIOUS_PAYROLL_PROVIDER: Scalars['Boolean']['output']
  PAYROLL_POST_DEADLINE: Scalars['Int']['output']
  SECONDARY_RATES_CODE_LEVEL: Scalars['Int']['output']
  SECONDARY_RATES_CONSIDER_UNIQUE_LEVELS_SEQUENCE: Scalars['Boolean']['output']
  SECONDARY_RATES_PER_LEVEL: Scalars['Boolean']['output']
  SINGLE_SIGN_ON: Scalars['Boolean']['output']
  TIP_POOLING_CASH_EARNING_CODE: Scalars['Int']['output']
  TIP_POOLING_CASH_GRATUITY_EARNING_CODE: Scalars['Int']['output']
  TIP_POOLING_NON_CASH_EARNING_CODE: Scalars['Int']['output']
  TIP_POOLING_NON_CASH_GRATUITY_EARNING_CODE: Scalars['Int']['output']
}

export type CustomerStatus =
  | 'active'
  | 'conversion'
  | 'demo_company'
  | 'inactive'
  | 'new_client_clean_quarter'
  | 'new_client_mid_quarter'
  | 'none'
  | 'prospect'
  | 'suspect'
  | 'unverified'

export type CustomerW2Settings = {
  __typename?: 'CustomerW2Settings'
  feins: Array<W2Fein>
  printingStatus: W2PrintingStatus
  /** shippingAddress may be null if the current printing status is SELF_PRINTED */
  shippingAddress: Maybe<W2ShippingAddress>
  toastPrintedCost: Scalars['Float']['output']
  /** Total number of feins containing employees with W2s, feins field will include those you have access to */
  totalFeins: Scalars['Int']['output']
}

export type DataExceptionError = {
  __typename?: 'DataExceptionError'
  message: Maybe<Scalars['String']['output']>
}

export type DateRange = {
  __typename?: 'DateRange'
  endDate: Maybe<Scalars['Date']['output']>
  startDate: Scalars['Date']['output']
}

export type DefaultEarningsId = {
  __typename?: 'DefaultEarningsId'
  contractorHourly: Maybe<Scalars['ID']['output']>
  contractorSalary: Maybe<Scalars['ID']['output']>
  hourly: Scalars['ID']['output']
  salary: Scalars['ID']['output']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type DeliveryMethod =
  | 'Invalid'
  | 'NotRecorded'
  | 'PayYourOwnWay'
  | 'SelfPrinted'
  | 'ToastPrinted'

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type DepositAccountType =
  | 'CHECKING'
  | 'MONEYMARKET'
  | 'PAYCARD'
  | 'SAVING'

export type DirectDepositAllocation = {
  __typename?: 'DirectDepositAllocation'
  amount: Scalars['Float']['output']
  depositAccountType: DepositAccountType
  sequence: Scalars['Int']['output']
  splitDepositType: SplitDepositType
}

export type DirectDepositBulkEmailResponse = {
  __typename?: 'DirectDepositBulkEmailResponse'
  id: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type DirectDepositNotification = {
  __typename?: 'DirectDepositNotification'
  email: Maybe<Scalars['String']['output']>
  lastContactedUtc: Maybe<Scalars['DateTime']['output']>
  status: Maybe<DirectDepositNotificationStatus>
}

export type DirectDepositNotificationStatus =
  | 'FAILURE_TO_SEND'
  | 'SUCCESSFULLY_SENT'

export type DirectDepositPaginationInput = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type DisabilityStatus = 'DOES_NOT_WISH_TO_ANSWER' | 'NO' | 'YES'

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']['output']
  number: Scalars['String']['output']
  state: StateInUnitedStates
}

export type DuplicateRequest = {
  __typename?: 'DuplicateRequest'
  message: Maybe<Scalars['String']['output']>
}

export type EarningCode = {
  __typename?: 'EarningCode'
  isHourly: Scalars['Boolean']['output']
  isOT: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type EarningListEmptyError = {
  __typename?: 'EarningListEmptyError'
  message: Maybe<Scalars['String']['output']>
}

export type EcPayrollResponse = ResponseError | ResponseSuccess

export type EeoClassification = {
  __typename?: 'EeoClassification'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type EeoClassificationInput = {
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export type Ein = {
  __typename?: 'Ein'
  effectiveDate: Scalars['Date']['output']
  ein: Scalars['String']['output']
  frequency: Maybe<EinPaymentFrequency>
  id: Scalars['ID']['output']
  sutaRates: Maybe<Array<SutaRate>>
  taxName: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EinPaymentFrequency = {
  __typename?: 'EinPaymentFrequency'
  name: Scalars['String']['output']
}

export type EinState = {
  __typename?: 'EinState'
  code: Scalars['String']['output']
  eins: Array<Ein>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type EmailAlreadyVerified = {
  __typename?: 'EmailAlreadyVerified'
  message: Maybe<Scalars['String']['output']>
}

export type EmailChangedEvent = {
  __typename?: 'EmailChangedEvent'
  emailHash: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  sequence: Scalars['Int']['output']
  toastIdentityGuid: Scalars['String']['output']
}

export type EmailHasBeenUpdated = {
  __typename?: 'EmailHasBeenUpdated'
  message: Maybe<Scalars['String']['output']>
}

export type EmailInvalidError = {
  __typename?: 'EmailInvalidError'
  message: Maybe<Scalars['String']['output']>
}

export type EmailNotFound = {
  __typename?: 'EmailNotFound'
  message: Maybe<Scalars['String']['output']>
}

export type EmailSendingError = {
  __typename?: 'EmailSendingError'
  message: Maybe<Scalars['String']['output']>
}

export type EmailVerification = {
  __typename?: 'EmailVerification'
  isVerified: Maybe<Scalars['Boolean']['output']>
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime: Maybe<Scalars['DateTime']['output']>
}

export type EmailVerificationError =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationEvent =
  | ConfirmedEvent
  | EmailChangedEvent
  | EmailVerificationFailed
  | VerificationEmailSentEvent

export type EmailVerificationEventsInput = {
  toastIdentityGuid: Scalars['String']['input']
}

export type EmailVerificationFailed = {
  __typename?: 'EmailVerificationFailed'
  message: Maybe<Scalars['String']['output']>
}

export type EmailVerificationMutation = {
  __typename?: 'EmailVerificationMutation'
  confirmVerificationEmail: ConfirmVerificationEmailResult
  sendVerificationEmail: SendVerificationEmailResult
}

export type EmailVerificationMutationConfirmVerificationEmailArgs = {
  input: InputMaybe<ConfirmVerificationEmailInput>
}

export type EmailVerificationMutationSendVerificationEmailArgs = {
  input: InputMaybe<SendVerificationEmailInput>
}

export type EmailVerificationQuery = {
  __typename?: 'EmailVerificationQuery'
  events: Array<EmailVerificationEvent>
  verification: Maybe<EmailVerificationResult>
}

export type EmailVerificationQueryEventsArgs = {
  input: EmailVerificationEventsInput
}

export type EmailVerificationResult =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerification
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationSourceType = 'PAYROLL' | 'SCHEDULING'

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isPrimary: Maybe<Scalars['Boolean']['output']>
  lastName: Scalars['String']['output']
  relation: Scalars['String']['output']
  telephoneNumber: Maybe<Scalars['String']['output']>
}

export type Employee = {
  __typename?: 'Employee'
  benefits: Maybe<Benefits>
  companyCode: Maybe<Scalars['String']['output']>
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment: Maybe<Employment>
  hasSsn: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']['output']
  jobAssignmentPayChangeErrors: Array<JobAssignment>
  jobAssignments: Array<JobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  payHistory: EmployeePayHistory
  payrollSecurityRole: Maybe<PayrollSecurityRole>
  salary: Maybe<Salary>
  salaryChangeErrors: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']['output']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId: Maybe<LevelId>
  payrollLocationName: Maybe<Scalars['String']['output']>
  posEmail: Maybe<Scalars['String']['output']>
  posLocationGuid: Maybe<Scalars['ID']['output']>
  posMostRecentClockInDate: Maybe<Scalars['Date']['output']>
  posRestaurantUserGuid: Maybe<Scalars['ID']['output']>
  posStatus: Maybe<Scalars['String']['output']>
  posUserName: Maybe<PersonName>
}

export type EmployeeComparison = {
  __typename?: 'EmployeeComparison'
  added: Array<PayrollEmployee>
  removed: Array<PayrollEmployee>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress: Maybe<PostalAddress>
  mailingAddress: Maybe<PostalAddress>
  telephoneNumber: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth: Maybe<Scalars['Date']['output']>
  disability: Maybe<DisabilityStatus>
  ethnicity: Maybe<Ethnicity>
  gender: Maybe<Gender>
  maritalStatus: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeDocumentUploadRequest = {
  fileName: Scalars['String']['input']
}

export type EmployeeEarning = {
  __typename?: 'EmployeeEarning'
  amount: Scalars['Decimal']['output']
  employeeUuid: Scalars['String']['output']
  quarter: Scalars['Int']['output']
  year: Scalars['Int']['output']
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']['output']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId: Maybe<LevelId>
  payrollRateOfPay: Maybe<Money>
  payrollTitle: Maybe<Scalars['String']['output']>
  posGuid: Maybe<Scalars['ID']['output']>
  posRateOfPay: Maybe<Money>
  posTitle: Maybe<Scalars['String']['output']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNetPays = {
  __typename?: 'EmployeeNetPays'
  amt: Scalars['Float']['output']
  uuid: Scalars['ID']['output']
}

export type EmployeeNewHireDocument = {
  __typename?: 'EmployeeNewHireDocument'
  fileName: Scalars['String']['output']
  filePath: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type EmployeeNotFoundError = {
  __typename?: 'EmployeeNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type EmployeePaginationInput = {
  from: InputMaybe<Scalars['String']['input']>
  limit: Scalars['Int']['input']
  locationIds: InputMaybe<Array<Scalars['Int']['input']>>
  offset: Scalars['Int']['input']
  positionIds: InputMaybe<Array<Scalars['Int']['input']>>
  statusIds: InputMaybe<Array<Scalars['Int']['input']>>
  until: InputMaybe<Scalars['String']['input']>
}

export type EmployeePaginationResponse = {
  __typename?: 'EmployeePaginationResponse'
  employees: Array<Employee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck: Maybe<Scalars['String']['output']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck: Maybe<Scalars['String']['output']>
}

export type EmployeePaymentMethodEmailResponse = {
  __typename?: 'EmployeePaymentMethodEmailResponse'
  messageId: Maybe<Scalars['String']['output']>
}

export type EmployeeRequirement = {
  __typename?: 'EmployeeRequirement'
  employeesPay: Array<EmployeeNetPays>
  invoiceItems: Array<InvoiceItem>
  voidNetPayment: Array<PayCheck>
}

export type EmployeeStatusTypeCount = {
  __typename?: 'EmployeeStatusTypeCount'
  active: Maybe<Scalars['Int']['output']>
  applicant: Maybe<Scalars['Int']['output']>
  deleted: Maybe<Scalars['Int']['output']>
  demo: Maybe<Scalars['Int']['output']>
  dormant: Maybe<Scalars['Int']['output']>
  implementation: Maybe<Scalars['Int']['output']>
  leaveOfAbsence: Maybe<Scalars['Int']['output']>
  neverEmployed: Maybe<Scalars['Int']['output']>
  notSpecified: Maybe<Scalars['Int']['output']>
  pendingActive: Maybe<Scalars['Int']['output']>
  terminated: Maybe<Scalars['Int']['output']>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']['output']
  job: Maybe<Scalars['String']['output']>
  location: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type EmployeeTaxes = {
  __typename?: 'EmployeeTaxes'
  amount: Scalars['Decimal']['output']
  employeeUuid: Scalars['String']['output']
  quarter: Scalars['Int']['output']
  year: Scalars['Int']['output']
}

export type Employment = {
  __typename?: 'Employment'
  eeoClassification: EeoClassification
  employeeNumber: Maybe<Scalars['String']['output']>
  employmentStatus: AllEmploymentStatuses
  employmentType: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  overtimeEligible: Maybe<Scalars['Boolean']['output']>
  totalLengthOfService: Maybe<DateRange>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  employeeNumber: Scalars['Int']['output']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['output']
}

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']['input']
  eeoClassification: InputMaybe<EeoClassificationInput>
  employeeNumber: Scalars['Int']['input']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['input']
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']['output']
  employeeId: Scalars['ID']['output']
}

export type EmploymentStatus = {
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type EmploymentStatusType =
  | 'ACTIVE'
  | 'APPLICANT'
  | 'DELETED'
  | 'DEMO'
  | 'IMPLEMENTATION'
  | 'LEAVE_OF_ABSENCE'
  | 'NEVER_EMPLOYED'
  | 'NOT_SPECIFIED'
  | 'PENDING_ACTIVE'
  | 'SEASONAL'
  | 'TERMINATED'

export type EmploymentTaxType = 'K1' | 'UNKNOWN' | 'W2' | '_1099'

export type EmptySalaryAllocationsError = {
  __typename?: 'EmptySalaryAllocationsError'
  message: Maybe<Scalars['String']['output']>
}

export type EnumAmountType = 'Currency' | 'Percent' | 'Undefined' | 'Value'

export type Error = {
  __typename?: 'Error'
  message: Maybe<Scalars['String']['output']>
}

export type Ethnicity =
  | 'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
  | 'ASIAN'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'DO_NOT_WISH_TO_IDENTIFY'
  | 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND'
  | 'HISPANIC_OR_LATINO'
  | 'TWO_OR_MORE_RACES'
  | 'WHITE'

export type ExpenseReport = {
  __typename?: 'ExpenseReport'
  approveDateTime: Maybe<Scalars['SpaDateTime']['output']>
  customerUuid: Scalars['String']['output']
  employeeFirstName: Scalars['String']['output']
  employeeLastName: Scalars['String']['output']
  employeeUuid: Scalars['String']['output']
  payPeriodUuid: Maybe<Scalars['String']['output']>
  totalAmount: Scalars['Float']['output']
}

export type Fein = {
  __typename?: 'Fein'
  address: FeinAddress
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  states: Array<EinState>
  taxFilingEndDate: Maybe<Scalars['Date']['output']>
  taxFilingStartDate: Maybe<Scalars['Date']['output']>
  tin: Scalars['String']['output']
  workTaxLocationIds: Array<Scalars['String']['output']>
}

export type FeinAddress = {
  __typename?: 'FeinAddress'
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  state: Scalars['String']['output']
  streetLine1: Scalars['String']['output']
  streetLine2: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type FeinMissingSsnEmployee = {
  __typename?: 'FeinMissingSsnEmployee'
  /** The employees with missing SSN that the current user has access to in this specific FEIN, doesn't necessarily matching the total field */
  employees: Array<MissingSsnEmployee>
  feinUuid: Scalars['String']['output']
  /** The total number of employees with missing SSN in this specific FEIN  */
  total: Scalars['Int']['output']
}

export type FeinOption = {
  __typename?: 'FeinOption'
  id: Scalars['LegacyId']['output']
  name: Scalars['String']['output']
}

export type FindUserEventsByUserIdInput = {
  fromVersion: InputMaybe<Scalars['Long']['input']>
  maxNumResults: InputMaybe<Scalars['Int']['input']>
  userId: Scalars['ID']['input']
}

export type Form8655 = {
  __typename?: 'Form8655'
  dateSigned: Maybe<Scalars['Date']['output']>
  feinUuid: Scalars['String']['output']
  form8655Details: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['String']['output']>
  s3Filename: Maybe<Scalars['String']['output']>
}

export type Form8655Error = MutationError & {
  __typename?: 'Form8655Error'
  errors: Array<Error>
  status: ResponseStatus
}

export type Form8655Input = {
  contact: Scalars['String']['input']
  fax: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
  signature: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type Form8655Response = Form8655Error | Form8655Success

export type Form8655Success = MutationSuccess & {
  __typename?: 'Form8655Success'
  result: Scalars['ID']['output']
  status: ResponseStatus
}

export type Gender = 'DOES_NOT_WISH_TO_IDENTIFY' | 'FEMALE' | 'MALE'

export type GenericResponse = {
  __typename?: 'GenericResponse'
  message: Scalars['String']['output']
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']['input']
  hours: Scalars['Decimal']['input']
  rate: MoneyInput
}

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']['output']
  payChangeDifference: AmountWithPayInterval
  retroPay: Money
}

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  jobAssignmentId: Scalars['ID']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type HourlyRetroPayResponse =
  | HourlyRetroPay
  | InvalidStartDateError
  | JobAssignmentNotFoundError
  | TimesheetRetrievalError

export type HumanResourcesPermission =
  | 'CHANGE_UNVERIFIED_USER_EMAIL'
  | 'CHANGE_VERIFIED_USER_EMAIL'
  | 'CUSTOMER_CONFIGURATION_EDIT'
  | 'CUSTOMER_CONFIGURATION_VIEW'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_EDIT'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_VIEW'
  | 'EDIT_MY_PROFILE'
  | 'EMPLOYEE_ACCESS_ALL'
  | 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER'
  | 'EMPLOYEE_EDIT'
  | 'EMPLOYEE_MAPPING_EDIT'
  | 'EMPLOYEE_VIEW'
  | 'EMPLOYMENT_EDIT'
  | 'EMPLOYMENT_STATUS_EDIT'
  | 'EMPLOYMENT_STATUS_VIEW'
  | 'EMPLOYMENT_VIEW'
  | 'JOB_ASSIGNMENT_EDIT'
  | 'JOB_ASSIGNMENT_VIEW'
  | 'MIGRATE_USERS'
  | 'PAY_CARD_AUDIT_VIEW'
  | 'PAY_CHANGE_HISTORY_VIEW'
  | 'PAY_EDIT'
  | 'PAY_HISTORY_VIEW'
  | 'SSN_EDIT'
  | 'SSN_EXISTS_VIEW'
  | 'VIEW_MY_PROFILE'
  | 'VIEW_PERMISSIONS'

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type IncompleteDirectDepositEmployee = {
  __typename?: 'IncompleteDirectDepositEmployee'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
  notification: Maybe<DirectDepositNotification>
}

export type IncompleteDirectDepositInput = {
  direction: SortDirection
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  sort: IncompleteDirectDepositSortColumn
}

export type IncompleteDirectDepositSortColumn = 'LAST_CONTACTED' | 'NAME'

export type IncompleteDirectDepositsResponse = {
  __typename?: 'IncompleteDirectDepositsResponse'
  employees: Array<IncompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type InputCannotBeEmpty = {
  __typename?: 'InputCannotBeEmpty'
  message: Maybe<Scalars['String']['output']>
}

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings'
  active: Scalars['Boolean']['output']
  syncEmployees: Scalars['Boolean']['output']
  tipsType: Maybe<TipsType>
}

export type IntercomClientType = 'ANDROID' | 'IOS' | 'WEB'

export type IntercomSessionDataResponse = {
  __typename?: 'IntercomSessionDataResponse'
  intercomUserHash: Maybe<Scalars['String']['output']>
  user: Maybe<User>
}

export type InvalidEffectiveDateError = {
  __typename?: 'InvalidEffectiveDateError'
  message: Maybe<Scalars['String']['output']>
}

export type InvalidPayChangeLogTokenError = {
  __typename?: 'InvalidPayChangeLogTokenError'
  message: Maybe<Scalars['String']['output']>
}

export type InvalidStartDateError = {
  __typename?: 'InvalidStartDateError'
  message: Maybe<Scalars['String']['output']>
}

export type InvalidUsername = {
  __typename?: 'InvalidUsername'
  username: Scalars['String']['output']
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  caption: Maybe<Scalars['String']['output']>
  employeeCount: Maybe<Scalars['Int']['output']>
  invoiceType: Maybe<Scalars['String']['output']>
  total: Scalars['Float']['output']
  uuid: Scalars['ID']['output']
}

export type IsPayrollPayor = 'no' | 'yesall' | 'yesnameonly'

export type IssueSeverity = 'ERROR' | 'INFO' | 'WARN'

export type IssueType =
  | 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS'
  | 'JOB_GUID_IS_MALFORMED'
  | 'JOB_IS_MISSING_IN_POS'
  | 'JOB_NOT_MAPPED'
  | 'LOCATION_GUID_IS_MALFORMED'
  | 'LOCATION_IS_MISSING_IN_POS'
  | 'LOCATION_NOT_MAPPED'
  | 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY'
  | 'PAYROLL_JOBS_MISMATCH_POS_JOBS'
  | 'POS_EE_MAPPING_IGNORED'
  | 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID'
  | 'RU_COUNTERPART_IS_MISSING_IN_POS'
  | 'UNMAPPED_PAYROLL_SPECIALTY'
  | 'UNMAPPED_POS_RU'

export type Item = {
  __typename?: 'Item'
  assignedTo: Assignee
  key: Scalars['ID']['output']
  status: TaskStatus
  subItems: Array<Item>
  title: Scalars['String']['output']
}

export type Job = {
  __typename?: 'Job'
  exportId: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
  locationName: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId: Maybe<Scalars['ID']['output']>
  pendingPayChange: Maybe<PendingPayChange>
}

export type JobAssignmentNotFoundError = {
  __typename?: 'JobAssignmentNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type JobInput = {
  restaurantJobGuid: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type JobTag = {
  __typename?: 'JobTag'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  modifiedByUserGuid: Maybe<Scalars['ID']['output']>
  modifiedDate: Maybe<Scalars['Date']['output']>
  name: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type JobTagAssignment = {
  __typename?: 'JobTagAssignment'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobTagId: Scalars['ID']['output']
  jobTagName: Scalars['String']['output']
  managementSetId: Scalars['ID']['output']
  modifiedByUserGuid: Maybe<Scalars['ID']['output']>
  modifiedDate: Maybe<Scalars['Date']['output']>
  restaurantId: Scalars['ID']['output']
  restaurantJobId: Scalars['ID']['output']
  selectedJobTagValue: Scalars['String']['output']
}

export type JobType = {
  __typename?: 'JobType'
  isOrphan: Maybe<Scalars['Boolean']['output']>
  name: Maybe<Scalars['String']['output']>
  uuid: Scalars['ID']['output']
}

export type KeyDoesNotMatch = {
  __typename?: 'KeyDoesNotMatch'
  message: Maybe<Scalars['String']['output']>
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
  leaveType: Maybe<Scalars['String']['output']>
  scheduledLeave: Maybe<DateRange>
}

export type LevelConfig = {
  __typename?: 'LevelConfig'
  name: Scalars['String']['output']
  sequence: Scalars['Int']['output']
  type: Scalars['String']['output']
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']['output']
  sequenceId: Scalars['Int']['output']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']['input']
  sequenceId: Scalars['Int']['input']
}

export type LevelOption = {
  __typename?: 'LevelOption'
  sequenceId: Scalars['Int']['output']
  uuid: Scalars['String']['output']
}

export type LevelUuidSequence = {
  sequenceId: Scalars['Int']['input']
  uuid: Scalars['String']['input']
}

export type LinkIfMappedResult =
  | LinkingNotAvailable
  | MappedUserMismatchedUsername
  | PayrollUser
  | UserAlreadyLinked
  | UserNotMapped

export type LinkUserResult = PayrollUser | UserNotFound

export type LinkingNotAvailable = {
  __typename?: 'LinkingNotAvailable'
  toastIdentityGuid: Scalars['ID']['output']
}

export type LocalizableMessage = {
  __typename?: 'LocalizableMessage'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type Location = {
  __typename?: 'Location'
  name: Maybe<Scalars['String']['output']>
  restaurant: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']['output']
}

export type LocationInput = {
  restaurantGuid: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']['output']
}

export type LocationMappingStatusCode =
  | 'NoStatus'
  | 'RestaurantGuidConnected'
  | 'RestaurantGuidDoubleMapped'
  | 'RestaurantGuidEmpty'
  | 'RestaurantGuidInvalid'

export type LumpSumEarningInput = {
  amount: MoneyInput
  earningCodeId: Scalars['ID']['input']
}

export type MappedUserMismatchedUsername = {
  __typename?: 'MappedUserMismatchedUsername'
  username: Scalars['String']['output']
}

export type MappingAssignment = {
  locationGuid: Scalars['ID']['input']
  restaurantUserGuid: Scalars['ID']['input']
}

export type MappingCondition =
  | 'MAPPED_INVALID_GUID'
  | 'MAPPED_NO_POS_COUNTERPART'
  | 'MAPPED_USER_MAPPED'
  | 'MAPPED_USER_MAPPING_IGNORED'
  | 'MAPPED_USER_NOT_MAPPED'
  | 'NOT_MAPPED'
  | 'POS_ONLY'

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export type MaritalStatus =
  | 'DIVORCED'
  | 'HEAD_OF_HOUSEHOLD'
  | 'MARRIED'
  | 'SINGLE'
  | 'WIDOW'

export type Message = {
  __typename?: 'Message'
  action: MessageActionType
  amount: Scalars['Float']['output']
  amountType: EnumAmountType
  author: Scalars['String']['output']
  datePosted: Scalars['SpaDateTime']['output']
  name: Scalars['String']['output']
  whom: Scalars['String']['output']
}

export type MessageActionType = 'approved' | 'recorded' | 'scheduled'

export type MissingPayGroupError = {
  __typename?: 'MissingPayGroupError'
  message: Maybe<Scalars['String']['output']>
}

export type MissingSsnEmployee = {
  __typename?: 'MissingSsnEmployee'
  employeeNumber: Scalars['String']['output']
  firstName: Scalars['String']['output']
  firstPayrollDate: Maybe<Scalars['Date']['output']>
  isSsnDeclined: Scalars['Boolean']['output']
  lastName: Scalars['String']['output']
  ssnDeclinedAt: Maybe<Scalars['DateTime']['output']>
  uuid: Scalars['String']['output']
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']['output']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']['input']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']['output']
  archive: ArchiveResponse
  archivePayPeriod: Maybe<Scalars['String']['output']>
  automationResetPayroll: ResponseStatus
  bringBackPayroll: BringBackResponse
  /** @deprecated Please use directDepositBulkEmail */
  bulkNotify: Array<BulkNotifyResponse>
  changeHelloWorldResponse: Maybe<Response>
  changeHourlyPay: Maybe<JobAssignment>
  /** Change salary pay: provide a list of the updated SalaryAllocations and their new annual pay rate */
  changeSalaryAllocations: ChangeSalaryResponse
  contactOnboarding: Maybe<GenericResponse>
  createBulkDeductions: Maybe<BulkAddResponse>
  createBulkEarnings: Maybe<BulkAddResponse>
  createJobTag: Maybe<JobTag>
  createJobTagAssignment: Maybe<JobTagAssignment>
  /** @deprecated please use updatePendingPayChangeStatuses */
  deletePendingPayChange: Maybe<Scalars['ID']['output']>
  directDepositBulkEmail: Array<DirectDepositBulkEmailResponse>
  emailVerification: Maybe<EmailVerificationMutation>
  ignorePosUserInPayroll: Maybe<Scalars['Boolean']['output']>
  open: OpenResponse
  payrollImport: PayrollImportResponse
  payrollUser: Maybe<PayrollUserMutations>
  post: PostResponse
  reactivateAdminUser: Scalars['Boolean']['output']
  rehireEmployeeNoChanges: Scalars['ID']['output']
  rehireEmployeeWithChanges: Scalars['ID']['output']
  reopenTasks: TasksReopenResponse
  reset: Maybe<Scalars['String']['output']>
  resyncTasks: ChecklistState
  resyncTaxPrepChecklist: TaxPrepChecklistState
  rolloverPayrolls: EcPayrollResponse
  saveAdditionalEarnings: Maybe<AdditionalEarningResponse>
  saveDeduction: SaveDeductionResponse
  saveEarning: SaveEarningResponse
  saveEmployeeSsn: SaveEmployeeSsnResponse
  saveForm8655: Form8655Response
  saveNewEmployee: Maybe<SaveNewEmployeeResponse>
  saveTaxFilingEndDate: EcPayrollResponse
  sendJobMappingRequest: Maybe<Scalars['String']['output']>
  sendUpdateEmployeePaymentMethodEmail: Maybe<UpdateEmployeePaymentMethodEmailResponse>
  setHasPreviousPayrollProvider: GenericResponse
  updateBenefitsConsent: BenefitsThirdPartyDataSharingConsent
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
  updatePayCardCustomerSettings: UpdatePayCardCustomerSettingsResponse
  updatePendingPayChangeStatuses: Maybe<UpdatePendingPayChangeStatusResponse>
  updateTask: ChecklistState
  updateTaxPrepTask: TaxPrepChecklistState
  updateW2Settings: W2PrintingStatusResponse
  userMigration: Maybe<UserMigrationMutation>
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']['input']
  payrollEmployeeId: Scalars['ID']['input']
  restaurantUserId: InputMaybe<Scalars['ID']['input']>
}

export type MutationArchiveArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationArchivePayPeriodArgs = {
  payrollUuid: Scalars['ID']['input']
}

export type MutationAutomationResetPayrollArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationBringBackPayrollArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationBulkNotifyArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']['input']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']['input']
  jobAssignmentId: Scalars['ID']['input']
}

export type MutationChangeSalaryAllocationsArgs = {
  changeSalaryAllocations: Array<ChangeSalaryAllocationInput>
  employeeId: Scalars['ID']['input']
}

export type MutationContactOnboardingArgs = {
  emailContent: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateBulkDeductionsArgs = {
  input: BulkDeductionMutation
  payrollUuid: Scalars['ID']['input']
}

export type MutationCreateBulkEarningsArgs = {
  input: BulkEarningMutation
  payrollUuid: Scalars['ID']['input']
}

export type MutationCreateJobTagArgs = {
  jobTagInput: CreateJobTagInput
}

export type MutationCreateJobTagAssignmentArgs = {
  jobTagAssignmentInput: CreateJobTagAssignmentInput
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']['input']
  pendingPayChangeId: Scalars['ID']['input']
}

export type MutationDirectDepositBulkEmailArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationIgnorePosUserInPayrollArgs = {
  userGuid: Scalars['ID']['input']
}

export type MutationOpenArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationPayrollImportArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationPostArgs = {
  consentBankAccountUuids: InputMaybe<Array<Scalars['String']['input']>>
  payPeriodUuid: Scalars['String']['input']
}

export type MutationReactivateAdminUserArgs = {
  email: Scalars['String']['input']
}

export type MutationRehireEmployeeNoChangesArgs = {
  request: RehireEmployeeNoChangesInput
}

export type MutationRehireEmployeeWithChangesArgs = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
  request: NewEmployeeInput
}

export type MutationReopenTasksArgs = {
  tasksRows: Array<TasksRow>
}

export type MutationResetArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type MutationRolloverPayrollsArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type MutationSaveAdditionalEarningsArgs = {
  employeeId: Scalars['ID']['input']
  hourlyEarnings?: Array<HourlyEarningInput>
  jobAssignmentId: Scalars['ID']['input']
  lumpSumEarnings?: Array<LumpSumEarningInput>
}

export type MutationSaveDeductionArgs = {
  deduction: SaveDeduction
  deductionUuid: InputMaybe<Scalars['String']['input']>
  payPeriodUuid: Scalars['String']['input']
}

export type MutationSaveEarningArgs = {
  earning: SaveEarning
  earningUuid: InputMaybe<Scalars['String']['input']>
  payPeriodUuid: Scalars['String']['input']
}

export type MutationSaveEmployeeSsnArgs = {
  employeeUuid: Scalars['String']['input']
  ssn: Scalars['String']['input']
}

export type MutationSaveForm8655Args = {
  feinUuid: Scalars['String']['input']
  input: Form8655Input
}

export type MutationSaveNewEmployeeArgs = {
  request: NewEmployeeInput
}

export type MutationSaveTaxFilingEndDateArgs = {
  customerFeinUuid: Scalars['String']['input']
  taxFilingEndDate: InputMaybe<Scalars['Date']['input']>
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote: InputMaybe<Scalars['String']['input']>
  employeeUuid: Scalars['ID']['input']
  payrollJobId: InputMaybe<LevelIdInput>
  payrollLocationId: InputMaybe<LevelIdInput>
}

export type MutationSendUpdateEmployeePaymentMethodEmailArgs = {
  employeeId: Scalars['ID']['input']
}

export type MutationSetHasPreviousPayrollProviderArgs = {
  hasPreviousPayrollProvider: Scalars['Boolean']['input']
}

export type MutationUpdateBenefitsConsentArgs = {
  version: Scalars['Float']['input']
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']['input']
}

export type MutationUpdatePayCardCustomerSettingsArgs = {
  input: UpdatePayCardCustomerSettingsInput
}

export type MutationUpdatePendingPayChangeStatusesArgs = {
  employeeId: Scalars['ID']['input']
  updatePendingPayChangeStatusInput?: Array<PendingPayChangeStatusInput>
}

export type MutationUpdateTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']['input']
}

export type MutationUpdateTaxPrepTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']['input']
}

export type MutationUpdateW2SettingsArgs = {
  w2Settings: W2SettingsInput
}

export type MutationError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type MutationSuccess = {
  status: ResponseStatus
}

export type NavigableTaxAccount = {
  __typename?: 'NavigableTaxAccount'
  companyCode: Scalars['String']['output']
  legalName: Scalars['String']['output']
  shardName: Scalars['String']['output']
  tin: Scalars['String']['output']
}

export type NavigableTaxAccountResult = {
  __typename?: 'NavigableTaxAccountResult'
  nextPageNumber: Maybe<Scalars['Int']['output']>
  taxAccounts: Array<NavigableTaxAccount>
}

export type NavigableTaxAccountsInput = {
  pageNumber: InputMaybe<Scalars['Int']['input']>
  pageSize: InputMaybe<Scalars['Int']['input']>
  search: InputMaybe<Scalars['String']['input']>
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  matchUrls: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  pageType: Maybe<PageType>
  path: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']['output']
}

export type NavigationInput = {
  params: InputMaybe<Array<NavigationParam>>
  path: InputMaybe<Scalars['String']['input']>
}

export type NavigationParam = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type NewEmployeeInput = {
  additionalDocuments: Array<AdditionalDocumentInput>
  benefits: BenefitsInput
  customDocumentFields: Array<CustomDocumentFieldInput>
  documents: Array<Scalars['ID']['input']>
  employment: EmploymentDetailsInput
  jobsAndPay: NewJobsAndPayInput
  overtimeEligible: Scalars['Boolean']['input']
  person: PersonInput
  securityRoleId: Scalars['ID']['input']
}

export type NewHireDocument = {
  __typename?: 'NewHireDocument'
  assignedByPosition: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type NewJobAssignment = {
  __typename?: 'NewJobAssignment'
  hourlyRate: Maybe<Money>
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
}

export type NewJobAssignmentInput = {
  hourlyRate: InputMaybe<MoneyInput>
  jobId: Scalars['ID']['input']
  locationId: Scalars['ID']['input']
}

export type NewJobsAndPay = {
  __typename?: 'NewJobsAndPay'
  annualSalary: Maybe<Money>
  jobs: Array<NewJobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  salaryPerPayPeriod: Maybe<Money>
  salaryRateOfPay: Maybe<Money>
}

export type NewJobsAndPayInput = {
  annualSalary: InputMaybe<MoneyInput>
  jobs: Array<NewJobAssignmentInput>
  mappings: Array<MappingAssignment>
  payGroupAssignments: Array<PayGroupAssignmentInput>
  salaryPerPayPeriod: InputMaybe<MoneyInput>
  salaryRateOfPay: InputMaybe<MoneyInput>
}

export type NextPayPeriodNotFoundError = {
  __typename?: 'NextPayPeriodNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type NextPaycheckDateNotFoundError = {
  __typename?: 'NextPaycheckDateNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type NonCashRequirement = {
  __typename?: 'NonCashRequirement'
  employeesManualPay: Array<EmployeeNetPays>
  vendorChecksNetPays: Array<VendorNetPays>
  voidNetPayment: Array<PayCheck>
}

export type NotAToastUser = {
  __typename?: 'NotAToastUser'
  message: Maybe<Scalars['String']['output']>
}

export type OnboardingCheckListPermission =
  | 'EDIT_PAYROLL_ONBOARDING'
  | 'VIEW_PAYROLL_ONBOARDING'

export type OpenError = MutationError & {
  __typename?: 'OpenError'
  errors: Array<Error>
  status: ResponseStatus
}

export type OpenResponse = OpenError | OpenSuccess

export type OpenSuccess = MutationSuccess & {
  __typename?: 'OpenSuccess'
  status: ResponseStatus
}

export type PageType = 'HEADER_ONLY' | 'LINK_ONLY'

export type Paged = {
  __typename?: 'Paged'
  pageCount: Scalars['Int']['output']
  pageIndex: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type PagedResult = {
  pageSize: Scalars['Int']['output']
  /**  the next page token, useful for infinite scroll or show more based UX  */
  pageToken: Scalars['String']['output']
  totalRecords: Scalars['Int']['output']
}

export type Parallel = {
  __typename?: 'Parallel'
  checkDate: Scalars['Date']['output']
  endDate: Scalars['Date']['output']
  feinName: Scalars['String']['output']
  feinTin: Scalars['String']['output']
  feinUuid: Scalars['String']['output']
  startDate: Scalars['Date']['output']
  totalAmount: Scalars['Decimal']['output']
}

export type PastPayroll = {
  __typename?: 'PastPayroll'
  dueDate: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  isManual: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  payDate: Maybe<Scalars['Date']['output']>
  payGroups: Array<PastPayrollPayGroup>
  postByUser: Maybe<PastPayrollUser>
  postDate: Maybe<Scalars['DateTime']['output']>
  startDate: Scalars['Date']['output']
  status: PayrollStatusV2
  total: Money
}

export type PastPayrollPagedResult = PagedResult & {
  __typename?: 'PastPayrollPagedResult'
  pageIndex: Maybe<Scalars['Int']['output']>
  pageSize: Scalars['Int']['output']
  pageToken: Scalars['String']['output']
  records: Array<PastPayroll>
  totalRecords: Scalars['Int']['output']
}

export type PastPayrollPayGroup = {
  __typename?: 'PastPayrollPayGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PastPayrollUser = {
  __typename?: 'PastPayrollUser'
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Maybe<Scalars['String']['output']>
  username: Scalars['String']['output']
}

export type PayCardAdminSettings = {
  __typename?: 'PayCardAdminSettings'
  payCardAdminPayoutsLocked: Maybe<Scalars['Boolean']['output']>
}

export type PayCardBooleanAccessFactor = 'FALSE' | 'TRUE' | 'UNKNOWN'

export type PayCardBusinessAccess = {
  __typename?: 'PayCardBusinessAccess'
  businessId: Maybe<Scalars['ID']['output']>
  existsInPayroll: Scalars['Boolean']['output']
  fein: Scalars['String']['output']
  isContractComplete: Scalars['Boolean']['output']
  restaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccess = {
  __typename?: 'PayCardCustomerAccess'
  businesses: Array<PayCardBusinessAccess>
  customerUuid: Scalars['ID']['output']
  detachedRestaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']['input']
}

export type PayCardCustomerSettings = {
  __typename?: 'PayCardCustomerSettings'
  payCardAdminSettings: Maybe<PayCardAdminSettings>
  payCardPayoutConfiguration: Maybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: Maybe<Scalars['Boolean']['output']>
  payCardTipsConfiguration: Maybe<PayCardTipsConfiguration>
}

/** Represents one atomic change to customer settings. */
export type PayCardCustomerSettingsAuditEntry = {
  __typename?: 'PayCardCustomerSettingsAuditEntry'
  change: PayCardCustomerSettingsAuditHistoryChangeContent
  /** The timestamp in which the change was made */
  datetime: Scalars['DateTime']['output']
  /** User that made the change */
  user: PayCardCustomerSettingsAuditUser
}

/**
 * Content that represents what the change was that occurred.
 * Note we only return a message key here not the actual string.
 * The front end will need to resolve the actual localized
 * string.
 */
export type PayCardCustomerSettingsAuditHistoryChangeContent = {
  __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent'
  messageKey: Scalars['String']['output']
}

export type PayCardCustomerSettingsAuditUser = {
  __typename?: 'PayCardCustomerSettingsAuditUser'
  displayName: Scalars['String']['output']
}

export type PayCardEmployeeAccess = {
  __typename?: 'PayCardEmployeeAccess'
  accessFactors: PayCardEmployeeAccessFactors
  accessLevel: PayCardEmployeeAccessLevel
  employeeUuid: Scalars['ID']['output']
}

export type PayCardEmployeeAccessFactors = {
  __typename?: 'PayCardEmployeeAccessFactors'
  cardActivated: Maybe<PayCardBooleanAccessFactor>
  highestRestaurantAccessLevel: Maybe<PayCardRestaurantAccessLevel>
  isEmployed: Maybe<Scalars['Boolean']['output']>
  isLocEnabled: Maybe<PayCardBooleanAccessFactor>
  isWorkTaxLocationStateEligible: Maybe<Scalars['Boolean']['output']>
}

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']['input']
  skipCache: Scalars['Boolean']['input']
}

export type PayCardEmployeeAccessLevel =
  | 'NONE'
  | 'PAYCARD'
  | 'PAYOUTS'
  | 'SIGNUP'

export type PayCardEmployerAccessFactors =
  | 'ENROLLMENT_FEATURE_FLAG'
  | 'FEIN_IN_PAYROLL'
  | 'HAS_COMPLETED_CONTRACT'
  | 'PAYOUT_ENTITLEMENT'
  | 'PAY_CARD_ENTITLEMENT'
  | 'SDP_FEATURE_FLAG'

export type PayCardEmployerAccessLevel =
  | 'EMPLOYEE_PAYOUTS'
  | 'EMPLOYEE_SIGNUP'
  | 'NONE'
  | 'ONBOARDING'

export type PayCardPayoutConfiguration =
  | 'TIPS_ONLY'
  | 'WAGES_AND_TIPS'
  | 'WAGES_ONLY'

export type PayCardRestaurantAccess = {
  __typename?: 'PayCardRestaurantAccess'
  accessFactors: Array<PayCardEmployerAccessFactors>
  accessLevel: PayCardEmployerAccessLevel
  restaurantGuid: Scalars['ID']['output']
}

export type PayCardRestaurantAccessLevel = 'NONE' | 'PAYOUTS' | 'SIGNUP'

export type PayCardTipsConfiguration = 'TIPS_ALL' | 'TIPS_WITH_WITHHOLDING'

export type PayChangeEvent = {
  __typename?: 'PayChangeEvent'
  /** Information about the actor that initiated the pay change event. If null, the actor is unknown. */
  actor: Maybe<Actor>
  /** The date and time the pay change event was initiated. If null, the date is unknown. */
  createdAt: Maybe<Scalars['DateTime']['output']>
  /** The current pay rate for the job to which the pay change event is referencing. */
  currentRate: AmountWithPayInterval
  /** The date and time the pay change did / will take effect. */
  effectiveAt: Maybe<Scalars['DateTime']['output']>
  /** The ID of the pay change event */
  id: Scalars['ID']['output']
  /** The job name to which the pay change event is referencing */
  jobName: Scalars['String']['output']
  /** The location name for the job to which the pay change event is referencing */
  locationName: Scalars['String']['output']
  /**
   * The previous pay rate for the job to which the pay change event is referencing.
   * If null, this pay change event is not tied to a previous pay, i.e. for new jobs
   */
  previousRate: Maybe<AmountWithPayInterval>
  /** The current status of the pay change even. */
  status: PayChangeEventStatus
}

export type PayChangeEventStatus =
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'NEW_JOB'
  | 'SCHEDULED'
  | 'UPDATED'

export type PayChangeLog = {
  __typename?: 'PayChangeLog'
  /**
   * Page token used to retrieve the current page of pay change events. Passing this value through as the pageToken in
   * subsequent requests will result in the same pay change events being retrieved.
   */
  currentPageToken: Maybe<Scalars['String']['output']>
  /** The employee's pay change events, default sort is via createdDate newest -> oldest */
  data: Maybe<Array<PayChangeEvent>>
  /**
   * Page token used to retrieve the next page of pay change events. When this field is null that indicates that
   * [currentPageToken] was the last page in the result set.
   */
  nextPageToken: Maybe<Scalars['String']['output']>
  /**
   * Page token used to retrieve the previous page of pay change events. When the field is null that indicates that indicates
   * that the [currentPageToken] was the first page in the result set.
   */
  previousPageToken: Maybe<Scalars['String']['output']>
  /** The total count of this employee's pay change events */
  totalResultCount: Scalars['Int']['output']
}

export type PayChangeLogResponse =
  | AbsentPayChangeLogTokenError
  | InvalidPayChangeLogTokenError
  | PayChangeLog

export type PayChangeLogSortField = 'CREATE_DATE'

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField
}

export type PayCheck = {
  __typename?: 'PayCheck'
  netEarnings: Scalars['Float']['output']
}

export type PayCheckDeduction = {
  __typename?: 'PayCheckDeduction'
  amount: Scalars['Float']['output']
  deductionCodeUuid: Scalars['String']['output']
  isPercentage: Scalars['Boolean']['output']
  isReadOnly: Scalars['Boolean']['output']
  legacyDeductionCodeId: Scalars['String']['output']
  legacyId: Scalars['ID']['output']
  name: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type PayCheckDeliverySettings = {
  __typename?: 'PayCheckDeliverySettings'
  method: DeliveryMethod
  needsPdfFile: Scalars['Boolean']['output']
  usingToastCheckStock: Scalars['Boolean']['output']
}

export type PayCheckEarning = {
  __typename?: 'PayCheckEarning'
  amount: Scalars['Float']['output']
  baseRate: Scalars['Float']['output']
  checkCode: CheckCode
  earningCode: EarningCode
  hours: Scalars['Float']['output']
  isFirstWeek: Scalars['Boolean']['output']
  job: Scalars['String']['output']
  legacyEarningCodeId: Scalars['String']['output']
  legacyId: Scalars['ID']['output']
  location: Scalars['String']['output']
  name: Scalars['String']['output']
  rate: Scalars['Float']['output']
  uuid: Scalars['String']['output']
}

export type PayCheckTaxes = {
  __typename?: 'PayCheckTaxes'
  amount: Scalars['Float']['output']
  isCollectedByToast: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type PayCheckV2 = {
  __typename?: 'PayCheckV2'
  checkCode: CheckCode
  deductions: Array<PayCheckDeduction>
  earnings: Array<PayCheckEarning>
  isManual: Scalars['Boolean']['output']
  isReadOnly: Scalars['Boolean']['output']
  isVoid: Scalars['Boolean']['output']
  legacyId: Maybe<Scalars['ID']['output']>
  number: Scalars['Int']['output']
  paidByCheck: Scalars['Boolean']['output']
  uuid: Scalars['String']['output']
}

export type PayDetails = {
  __typename?: 'PayDetails'
  checkDate: Maybe<Scalars['SpaDate']['output']>
  gross: Scalars['Float']['output']
  net: Scalars['Float']['output']
  payStubs: Array<PayStub>
  taxes: Scalars['Float']['output']
}

export type PayFrequencies = {
  __typename?: 'PayFrequencies'
  active: Scalars['Boolean']['output']
  calcMethod: Scalars['String']['output']
  default: Scalars['Boolean']['output']
  frequency: Scalars['String']['output']
  name: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type PayFrequency =
  | 'ANNUAL'
  | 'BIWEEKLY'
  | 'MONTHLY'
  | 'NONE'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'SEMIMONTHLY'
  | 'WEEKLY'

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayGroupAssignment = {
  __typename?: 'PayGroupAssignment'
  checkCodeId: Scalars['ID']['output']
  locationIds: Array<Scalars['ID']['output']>
  payGroupId: Scalars['ID']['output']
  positionId: Scalars['ID']['output']
  workTaxLocationId: Scalars['ID']['output']
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']['input']
  locationIds: Array<Scalars['ID']['input']>
  payGroupId: Scalars['ID']['input']
  positionId: InputMaybe<Scalars['ID']['input']>
  workTaxLocationId: Scalars['ID']['input']
}

export type PayGroupCalendar = {
  __typename?: 'PayGroupCalendar'
  payGroupId: Scalars['String']['output']
  payPeriods: Array<PayPeriodCalendar>
}

export type PayGroupCalendarResult = PayGroupCalendar | Unauthorized

export type PayGroupSetting = {
  __typename?: 'PayGroupSetting'
  bankAccount: Maybe<BankAccount>
  name: Scalars['String']['output']
  payFrequencies: Array<PayFrequencies>
  weekStartDay: Maybe<Scalars['String']['output']>
}

export type PayGroupWithFrequency = {
  __typename?: 'PayGroupWithFrequency'
  checkCodeId: Scalars['ID']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId: Scalars['ID']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayHistoryAudit = {
  __typename?: 'PayHistoryAudit'
  paging: Paged
  records: Maybe<Array<PayHistoryAuditRecord>>
}

export type PayHistoryAuditRecord = {
  __typename?: 'PayHistoryAuditRecord'
  action: Scalars['String']['output']
  modifiedBy: Scalars['String']['output']
  modifiedDate: Scalars['SpaDateTime']['output']
  payPeriod: Scalars['String']['output']
}

export type PayHistoryFilters = {
  feinId: InputMaybe<Scalars['Int']['input']>
  from: InputMaybe<Scalars['SpaDateTime']['input']>
  to: InputMaybe<Scalars['SpaDateTime']['input']>
}

export type PayHistoryPage = {
  __typename?: 'PayHistoryPage'
  FeinList: Array<FeinOption>
  employeeUuid: Scalars['ID']['output']
  latest: Maybe<PayDetails>
  paging: Paged
  payHistory: Maybe<Array<PayDetails>>
}

export type PayHistoryReportType = 'DETAIL' | 'SUMMARY'

export type PayInterval = 'ANNUAL' | 'HOUR'

export type PayPeriod = {
  __typename?: 'PayPeriod'
  adjustmentUrl: Scalars['String']['output']
  checkCode: CheckCode
  checkCodeName: Scalars['String']['output']
  checkCodeUuid: Scalars['String']['output']
  checkDate: Scalars['SpaDate']['output']
  checkDateOverride: Maybe<Scalars['SpaDate']['output']>
  checkPackages: Maybe<Array<Maybe<CheckPackage>>>
  dashboardUrl: Scalars['String']['output']
  datePosted: Maybe<Scalars['SpaDate']['output']>
  debitDate: Maybe<Scalars['SpaDate']['output']>
  deliveryMethod: DeliveryMethod
  dueDate: Maybe<Scalars['SpaDateTime']['output']>
  employeesSpreadsheetUrl: Scalars['String']['output']
  endDate: Scalars['SpaDate']['output']
  finalCheckDate: Maybe<Scalars['SpaDate']['output']>
  forceLiveChecks: Scalars['Boolean']['output']
  isManual: Scalars['Boolean']['output']
  isReadOnly: Maybe<Scalars['Boolean']['output']>
  listUrl: Scalars['String']['output']
  messages: Array<Message>
  name: Scalars['String']['output']
  paperChecksSettingsUrl: Scalars['String']['output']
  payGroupUuids: Array<Scalars['String']['output']>
  /** @deprecated Replaced by combination of dueDate and postDeadline. */
  payrollDate: Maybe<Scalars['SpaDateTime']['output']>
  postDeadline: Scalars['Int']['output']
  postDeadlineText: PostDeadline
  preflightUrl: Scalars['String']['output']
  previewUrl: Scalars['String']['output']
  previousPayPeriodEndDate: Maybe<Scalars['SpaDate']['output']>
  previousPayPeriodStartDate: Maybe<Scalars['SpaDate']['output']>
  previousPayPeriodUuid: Maybe<Scalars['String']['output']>
  previousProviderPayrollType: Maybe<PreviousProviderPayrollType>
  reviewUrl: Scalars['String']['output']
  startDate: Scalars['SpaDate']['output']
  status: Scalars['Int']['output']
  taxDebitDate: Scalars['SpaDate']['output']
  taxesImportUrl: Scalars['String']['output']
  timesheetsImportUrl: Scalars['String']['output']
  timesheetsImportUrlAloha: Maybe<Scalars['String']['output']>
  timesheetsUrl: Scalars['String']['output']
  toastPrintedChecks: Maybe<ToastPrinted>
  uuid: Scalars['String']['output']
}

export type PayPeriodCalendar = {
  __typename?: 'PayPeriodCalendar'
  checkDate: Scalars['Date']['output']
  isArchived: Scalars['Boolean']['output']
  startDate: Scalars['Date']['output']
  status: PayrollStatusV2
}

export type PayStub = {
  __typename?: 'PayStub'
  /** @deprecated This function causes contention on the ec-web servers. Use the downloadUrl and pdfDownloadUrl instead */
  DownloadURL: Scalars['String']['output']
  business: Maybe<Scalars['String']['output']>
  checkDate: Scalars['SpaDate']['output']
  checkFile: Scalars['String']['output']
  downloadUrl: Scalars['String']['output']
  employeeUuid: Scalars['ID']['output']
  /** @deprecated if you want the name, use business. If you want fein number, use feinNumber */
  fein: Maybe<Scalars['String']['output']>
  feinNumber: Maybe<Scalars['String']['output']>
  payPeriod: Scalars['String']['output']
  payPeriodId: Scalars['LegacyId']['output']
  payStubNumber: Scalars['Int']['output']
  pdfDownloadUrl: Scalars['String']['output']
}

export type PayStubDownloadUrlArgs = {
  isPDF: InputMaybe<Scalars['Boolean']['input']>
}

export type Paycheck = {
  __typename?: 'Paycheck'
  date: Maybe<Scalars['Date']['output']>
}

export type PaymentFrequencyAudit = {
  __typename?: 'PaymentFrequencyAudit'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  effectiveDate: Scalars['Date']['output']
  email: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type Payroll = {
  __typename?: 'Payroll'
  dueDate: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  payGroups: Array<PayrollPayGroup>
  startDate: Scalars['Date']['output']
  status: PayrollStatusV2
}

export type PayrollCheckCode = {
  __typename?: 'PayrollCheckCode'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayrollCustomerUser = {
  __typename?: 'PayrollCustomerUser'
  companyCode: Scalars['String']['output']
  customerId: Scalars['ID']['output']
  customerName: Scalars['String']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  shardName: Scalars['String']['output']
  toastIdentityGuid: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollCustomerUserSource = 'DB' | 'DEX'

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource
  toastIdentityGuid: InputMaybe<Scalars['ID']['input']>
  userId: InputMaybe<Scalars['ID']['input']>
  username: InputMaybe<Scalars['String']['input']>
}

/**
 * Represents employees tied to a payroll with a paycheck in a payperiod.
 * Corresponds to PayrollEmployeeSummary type in ec-api.
 */
export type PayrollEmployee = {
  __typename?: 'PayrollEmployee'
  employeeNumber: Scalars['String']['output']
  employeeUuid: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']['output']
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber: Maybe<Scalars['String']['output']>
}

export type PayrollEmployeePosition = {
  __typename?: 'PayrollEmployeePosition'
  isPrimary: Scalars['Boolean']['output']
  levels: Array<Maybe<PayrollEmployeePositionLevel>>
  uuid: Scalars['String']['output']
}

export type PayrollEmployeePositionLevel = {
  __typename?: 'PayrollEmployeePositionLevel'
  sequenceId: Scalars['Int']['output']
  uuid: Maybe<Scalars['String']['output']>
}

/**
 * Used to represent data for all salaried or hourly employees, regardless of whether they will really appear in paycheck.
 * The type is only used internally, and should be converted to PayrollEmployee if being served publicly.
 */
export type PayrollEmployeeScheduled = {
  __typename?: 'PayrollEmployeeScheduled'
  employeeNumber: Scalars['String']['output']
  employeeUuid: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']['output']
}

export type PayrollEmployeeV2 = {
  __typename?: 'PayrollEmployeeV2'
  employeeNumber: Scalars['String']['output']
  employeeUuid: Scalars['String']['output']
  legacyEmployeeId: Scalars['String']['output']
  legacyId: Scalars['ID']['output']
  name: Scalars['String']['output']
  payPeriodUuid: Scalars['String']['output']
  paystubs: Array<PayCheckV2>
}

export type PayrollEmploymentStatus =
  | 'ACTIVE'
  | 'LEAVE_OF_ABSENCE'
  | 'OTHER'
  | 'TERMINATED'

export type PayrollFein = {
  __typename?: 'PayrollFein'
  benefits: Array<ThirdPartyBenefit>
  fein: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollImportResponse = ResponseError | ResponseSuccess

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']['output']
  uuid: Scalars['ID']['output']
}

export type PayrollLevelOption = {
  __typename?: 'PayrollLevelOption'
  isOrphan: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  sequenceId: Scalars['Int']['output']
  uuid: Scalars['String']['output']
}

export type PayrollLocation = {
  __typename?: 'PayrollLocation'
  exportId: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  jobs: Array<Job>
  name: Scalars['String']['output']
}

export type PayrollPayGroup = {
  __typename?: 'PayrollPayGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollPermission =
  | 'EDIT_PAYROLL'
  | 'EDIT_TAX_CENTER'
  | 'VIEW_PAYROLL'
  | 'VIEW_TAX_CENTER'

export type PayrollRequirement = {
  __typename?: 'PayrollRequirement'
  cashRequirement: CashRequirement
  nonCashRequirement: NonCashRequirement
}

export type PayrollSecurityRole = {
  __typename?: 'PayrollSecurityRole'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollSettings = {
  __typename?: 'PayrollSettings'
  /** @deprecated shouldn't be used in payroll setting domain only customer */
  customerPrintsW2: Maybe<Scalars['Boolean']['output']>
  deadlineEnforcement: Maybe<Scalars['Boolean']['output']>
  importTimesheetEarningsByWeek: Maybe<Scalars['Boolean']['output']>
  matchTimesheetToPayperiod: Maybe<Scalars['Boolean']['output']>
  payrollPayor: Maybe<IsPayrollPayor>
  payrollPostDeadline: PostDeadline
  useBlendedOT: Maybe<Scalars['Boolean']['output']>
}

export type PayrollStatus =
  | 'calculated'
  | 'created'
  | 'editing'
  | 'generated_ach'
  | 'import'
  | 'opened'
  | 'posted'
  | 'posting'
  | 'processing'
  | 'scheduled'
  | 'scheduling'
  | 'unknown'
  | 'verified'

export type PayrollStatusV2 =
  | 'CALCULATED'
  | 'CREATED'
  | 'EDITING'
  | 'EMPLOYEE_MAINTENANCE'
  | 'G2N'
  | 'GENERATED_ACH'
  | 'IMPORT'
  | 'OPENED'
  | 'POSTED'
  | 'POSTING'
  | 'POST_PAYROLL'
  | 'PROCESSING'
  | 'PR_MAINTENANCE'
  | 'REPORTS'
  | 'SCHEDULED'
  | 'SCHEDULING'
  | 'SPREADSHEET'
  | 'STATUS_CHANGES'
  | 'UNKNOWN'
  | 'VACATION'
  | 'VERIFIED'

export type PayrollTotalRequirements = {
  __typename?: 'PayrollTotalRequirements'
  cashRequirement: Scalars['Float']['output']
  nonCashRequirement: Scalars['Float']['output']
  nonDebitedTaxes: Scalars['Float']['output']
}

export type PayrollUser = {
  __typename?: 'PayrollUser'
  customerId: Scalars['ID']['output']
  email: Maybe<Scalars['String']['output']>
  employeeId: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  toastIdentityGuid: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollUserMutations = {
  __typename?: 'PayrollUserMutations'
  changeLinkedUsername: Array<ChangeLinkedUsernameResult>
  changeUnlinkedUsername: ChangeUnlinkedUsernameResult
  link: LinkUserResult
  linkIfMapped: Array<LinkIfMappedResult>
  unlink: UnlinkUserResult
}

export type PayrollUserMutationsChangeLinkedUsernameArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsChangeUnlinkedUsernameArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsLinkArgs = {
  customerId: Scalars['ID']['input']
  toastIdentityGuid: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollUserMutationsUnlinkArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollWorkTaxLocation = {
  __typename?: 'PayrollWorkTaxLocation'
  address: PayrollWorkTaxLocationAddress
  fein: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollWorkTaxLocationAddress = {
  __typename?: 'PayrollWorkTaxLocationAddress'
  city: Maybe<Scalars['String']['output']>
  country: Maybe<Scalars['String']['output']>
  gnisCode: Maybe<Scalars['String']['output']>
  line1: Maybe<Scalars['String']['output']>
  line2: Maybe<Scalars['String']['output']>
  line3: Maybe<Scalars['String']['output']>
  state: Maybe<Scalars['String']['output']>
  zipCode: Maybe<Scalars['String']['output']>
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  rate: Money
  status: PendingPayChangeStatus
}

export type PendingPayChangeNotFoundError = {
  __typename?: 'PendingPayChangeNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type PendingPayChangeNotFoundErrors = {
  __typename?: 'PendingPayChangeNotFoundErrors'
  pendingPayChangeNotFoundErrors: Array<PendingPayChangeNotFoundError>
}

export type PendingPayChangeStatus =
  | 'APPLIED'
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'PENDING'

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']['input']
  pendingPayChangeStatus: PendingPayChangeStatus
}

export type PendingPayChangeStatusResponse = {
  __typename?: 'PendingPayChangeStatusResponse'
  pendingPayChanges: Array<PendingPayChange>
}

export type Person = {
  __typename?: 'Person'
  chosenName: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Maybe<Scalars['String']['output']>
  posAccessCode: Scalars['Int']['output']
  ssn: Maybe<Scalars['String']['output']>
}

export type PersonInput = {
  chosenName: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: InputMaybe<Scalars['String']['input']>
  posAccessCode: InputMaybe<Scalars['Int']['input']>
  ssn: InputMaybe<Scalars['String']['input']>
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen: Maybe<Scalars['String']['output']>
  first: Maybe<Scalars['String']['output']>
  formatted: Maybe<Scalars['String']['output']>
  last: Maybe<Scalars['String']['output']>
  middle: Maybe<Scalars['String']['output']>
}

export type PinOnlyUserMergeFailed = {
  __typename?: 'PinOnlyUserMergeFailed'
  message: Scalars['String']['output']
}

export type PinOnlyUserMergeResult =
  | PinOnlyUserMergeFailed
  | PinOnlyUserMergeSuccess

export type PinOnlyUserMergeSuccess = {
  __typename?: 'PinOnlyUserMergeSuccess'
  toastIdentityGuid: Array<Scalars['ID']['output']>
}

export type PosAvailabilityRequest = {
  exportIds: Array<Scalars['ID']['input']>
  posAccessCode: Scalars['Int']['input']
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  externalEmployeeId: Maybe<Scalars['String']['output']>
  mappedToEmployee: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate: Maybe<Scalars['Date']['output']>
  restaurantUserGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosRawEmployee = {
  __typename?: 'PosRawEmployee'
  archived: Scalars['Boolean']['output']
  disabled: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  locationGuid: Scalars['ID']['output']
  payrollLocationId: Scalars['Int']['output']
  restaurantUserGuid: Scalars['ID']['output']
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosUser = {
  __typename?: 'PosUser'
  email: Scalars['String']['output']
  restaurantUsers: Array<RestaurantUser>
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PostDeadline =
  | 'DayOfPayDate'
  | 'FiveDaysBefore'
  | 'FourDaysBefore'
  | 'NotAllowedToPost'
  | 'OneDayBefore'
  | 'ThreeDaysBefore'
  | 'TwoDaysBefore'

export type PostError = MutationError & {
  __typename?: 'PostError'
  errors: Array<Error>
  status: ResponseStatus
}

export type PostResponse = PostError | PostSuccess

export type PostSuccess = MutationSuccess & {
  __typename?: 'PostSuccess'
  status: ResponseStatus
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']['output']
  country: Country
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
  streetAddress2: Maybe<Scalars['String']['output']>
}

export type PreviousPayrollProviderData = {
  __typename?: 'PreviousPayrollProviderData'
  parallels: Array<Parallel>
  priors: Array<Priors>
}

export type PreviousProviderPayrollType =
  | 'current_quarter_prior'
  | 'none'
  | 'parallel'

export type PreviousYearUnprocessedPayrolls = {
  __typename?: 'PreviousYearUnprocessedPayrolls'
  canWriteToAllPayrolls: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
  unprocessedPayrolls: Maybe<Array<UnprocessedPayroll>>
}

export type PreviousYearUnprocessedPayrollsQuerySuccess = QuerySuccess & {
  __typename?: 'PreviousYearUnprocessedPayrollsQuerySuccess'
  payrolls: PreviousYearUnprocessedPayrolls
  status: ResponseStatus
}

export type PreviousYearUnprocessedPayrollsResponse =
  | PreviousYearUnprocessedPayrollsQuerySuccess
  | QueryErrorResponse

export type Priors = {
  __typename?: 'Priors'
  earnings: Array<EmployeeEarning>
  feinName: Scalars['String']['output']
  feinTin: Scalars['String']['output']
  feinUuid: Scalars['String']['output']
  taxes: Array<EmployeeTaxes>
}

export type Query = {
  __typename?: 'Query'
  PayHistory: PayHistoryPage
  PayHistoryAudit: PayHistoryAudit
  PayHistoryReport: Scalars['String']['output']
  PayHistoryReportPDF: Scalars['String']['output']
  accessibleCustomers: AccessibleCustomersResult
  activeEarningCodes: Array<EarningCode>
  actorPermissionsForEmployees: Array<ActorPermissionsForEmployee>
  aggregatedInvoices: AggregateInvoiceResult
  audit: Array<Audit>
  bankConsentStatus: Maybe<Array<BankAccountConsentInfo>>
  checkAvailability: AvailabilityResponse
  checkCodes: Array<CheckCode>
  checkCodesConfig: CheckCodesConfigResponse
  checkPosAvailability: Maybe<Scalars['Boolean']['output']>
  companySummary: CompanySummary
  completeDirectDeposits: CompleteDirectDepositsResponse
  customer: Customer
  customerBenefits: Maybe<Array<Maybe<Benefit>>>
  customerConfiguration: Maybe<CustomerConfiguration>
  customerSettings: CustomerSettings
  directDepositEmailTemplate: Maybe<Scalars['String']['output']>
  downloadForm8655: Form8655Response
  downloadForm8655ByDateRange: Form8655Response
  downloadForm8655ByFeins: Form8655Response
  earningCodes: Array<EarningCode>
  emailVerification: Maybe<EmailVerificationQuery>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeeDocumentUploadUrl: EmployeeNewHireDocument
  employeePositions: Array<PayrollEmployeePosition>
  employeeStatusTypeCount: EmployeeStatusTypeCount
  enforceDeadline: Scalars['Boolean']['output']
  estimatedWithdrawal: PayrollRequirement
  feins: Array<Fein>
  findCustomerSettings: Array<CustomerSetting>
  findEmployeeById: Maybe<Employee>
  findEmployeeForRestaurantByToastIdentityGuid: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay: Maybe<EmployeePay>
  findEmployeesByCustomerUuid: EmployeePaginationResponse
  findJobMappingByJobId: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollEarningLevels: Array<LevelOption>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findUserByEmployeeId: Maybe<User>
  findUserById: Maybe<User>
  findUserEventsByUserId: Array<UserEvent>
  /** @deprecated Please use findUserById */
  findUsers: Maybe<Users>
  firstPayrollDate: Maybe<Scalars['Date']['output']>
  getAllForm8655Data: Array<Form8655>
  getApprovedExpenseReports: Array<ExpenseReport>
  getEmployeeComparison: EmployeeComparison
  getEmployeeMappingStatus: EmployeeMappingStatus
  getLevel: Array<PayrollLevelOption>
  /**
   * ALL hourly and salaried associated with a payroll
   * Will only include salaried employees; and hourly employees with a timesheet in the pay period
   * Should NOT be used for periods prior to the latest/currently open, as those will not properly incorporate employees from timesheets
   * See getEmployeeComparison for that use case instead
   */
  getPayrollEmployees: Array<PayrollEmployee>
  getPayrollEmployeesPosted: Array<PayrollEmployee>
  getPosMappingOptions: Array<PosEmployee>
  getPosUser: Array<PosRawEmployee>
  getPosUserV2: Maybe<PosUser>
  getUnmappedPosUsers: Array<PosRawEmployee>
  helloFromBenefits: Scalars['String']['output']
  helloWorld: Response
  incompleteDirectDeposits: IncompleteDirectDepositsResponse
  intercomSessionData: Maybe<IntercomSessionDataResponse>
  isW2Ready: Maybe<Scalars['Boolean']['output']>
  jobAssignmentsById: Array<JobAssignment>
  jobs: Array<JobType>
  levelsConfig: Array<LevelConfig>
  loadTaxChecklist: TaxPrepChecklistState
  missingSsnEmployees: Array<FeinMissingSsnEmployee>
  navigableTaxAccounts: NavigableTaxAccountResult
  navigation: NavigationResult
  newHireRequiredDocumentsForAssignedFields: Array<NewHireDocument>
  nextAvailableEmployeeNumber: Maybe<Scalars['Int']['output']>
  numRunPayrollsInThePastYear: Maybe<Scalars['Int']['output']>
  pastPayrolls: PastPayrollPagedResult
  payCardCustomerAccess: PayCardCustomerAccess
  payCardCustomerSettings: PayCardCustomerSettings
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  payCardCustomerSettingsLastEvent: Maybe<PayCardCustomerSettingsAuditEntry>
  payCardEmployeeAccess: PayCardEmployeeAccess
  /** Pay change log for the specified employee. */
  payChangeLog: Maybe<PayChangeLogResponse>
  payGroupCalendarByDateRange: PayGroupCalendarResult
  /** retrieves the paycheck shipping settings for a customer */
  paycheckDeliverySettings: PayCheckDeliverySettings
  paymentFrequencyAudit: Array<PaymentFrequencyAudit>
  payperiod: PayPeriod
  payrollCustomer: Customer
  payrollCustomerUsers: Array<PayrollCustomerUser>
  payrollEmployeeList: Array<PayrollEmployeeV2>
  payrollOnboardingPermissions: Array<OnboardingCheckListPermission>
  payrollPermissions: Array<PayrollPermission>
  payrollRequirement: PayrollTotalRequirements
  payrollSettings: PayrollSettings
  /** @deprecated Use payrollsByYearQuarter instead */
  payrollsByQuarter: Array<Payroll>
  payrollsByYearQuarter: Array<Payroll>
  previousPayrollProviderData: Maybe<PreviousPayrollProviderData>
  previousYearUnprocessedPayrolls: PreviousYearUnprocessedPayrollsResponse
  requiredDocumentationCustomFields: Array<CustomDocumentFields>
  requiredDocumentationCustomFieldsV2: Array<CustomDocumentFieldsV2>
  restaurant: Maybe<Restaurant>
  retroPayForHourlyPayChange: Maybe<HourlyRetroPayResponse>
  retroPayForSalaryChange: Maybe<SalaryRetroPayResponse>
  sections: SectionResult
  sutaRateAudit: Array<SutaRateAudit>
  taxPackages: Array<TaxPackage>
  timesheetAggregates: TimesheetAggregateResponse
  /**
   * Gets the timesheet data for a payperiod.
   * NOTE: The consumer is responsible for any necessary filtering
   * (e.g. checking if timesheets have been imported when considering previous payrolls)
   */
  timesheets: Array<TimesheetByPayrollAggregate>
  tipsDetails: Maybe<Array<TipsPerDay>>
  tipsIntegrationSettings: TipsIntegrationSettings
  /** @deprecated Use upcomingPayrolls instead */
  upcoming: Array<UpcomingPayroll>
  upcomingPayrolls: Array<UpcomingPayroll>
  userConnectionByEmail: UserConnectionByEmailQuery
  userMigration: Maybe<UserMigrationQuery>
  w2DownloadUrl: Scalars['String']['output']
  w2Packets: Array<W2Packet>
  w2Settings: CustomerW2Settings
}

export type QueryPayHistoryArgs = {
  employeeUuid: Scalars['ID']['input']
  filters: InputMaybe<PayHistoryFilters>
  first: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
}

export type QueryPayHistoryAuditArgs = {
  employeeUuid: Scalars['ID']['input']
  first: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
}

export type QueryPayHistoryReportArgs = {
  employeeUuid: Scalars['ID']['input']
  feinId: InputMaybe<Scalars['LegacyId']['input']>
  from: InputMaybe<Scalars['SpaDateTime']['input']>
  to: InputMaybe<Scalars['SpaDateTime']['input']>
  type: PayHistoryReportType
}

export type QueryPayHistoryReportPdfArgs = {
  employeeUuid: Scalars['ID']['input']
  feinId: InputMaybe<Scalars['LegacyId']['input']>
  from: InputMaybe<Scalars['SpaDateTime']['input']>
  to: InputMaybe<Scalars['SpaDateTime']['input']>
  type: PayHistoryReportType
}

export type QueryAccessibleCustomersArgs = {
  input: InputMaybe<AccessibleCustomersInput>
}

export type QueryActiveEarningCodesArgs = {
  employmentTaxType: InputMaybe<EmploymentTaxType>
}

export type QueryActorPermissionsForEmployeesArgs = {
  employeeIds: Array<Scalars['ID']['input']>
}

export type QueryAggregatedInvoicesArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryAuditArgs = {
  payrollId: Scalars['ID']['input']
}

export type QueryBankConsentStatusArgs = {
  payPeriodId: InputMaybe<Scalars['ID']['input']>
}

export type QueryCheckAvailabilityArgs = {
  request: AvailabilityRequest
}

export type QueryCheckPosAvailabilityArgs = {
  request: PosAvailabilityRequest
}

export type QueryCompleteDirectDepositsArgs = {
  completeDirectDepositInput: DirectDepositPaginationInput
}

export type QueryDownloadForm8655ByDateRangeArgs = {
  endDate: Scalars['Date']['input']
  startDate: Scalars['Date']['input']
}

export type QueryDownloadForm8655ByFeinsArgs = {
  feinUuids: Array<Scalars['String']['input']>
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryEmployeeDocumentUploadUrlArgs = {
  request: EmployeeDocumentUploadRequest
}

export type QueryEmployeePositionsArgs = {
  employeeUuid: Scalars['String']['input']
  payPeriodUuid: Scalars['String']['input']
}

export type QueryEnforceDeadlineArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryEstimatedWithdrawalArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryFindCustomerSettingsArgs = {
  settings: Array<Scalars['Int']['input']>
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindEmployeeForRestaurantByToastIdentityGuidArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  toastRestaurantGuid: Scalars['ID']['input']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryFindEmployeesByCustomerUuidArgs = {
  employeeInput: EmployeePaginationInput
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']['input']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindPayrollEarningLevelsArgs = {
  payCheckEarningUuid: Scalars['String']['input']
  payPeriodUuid: Scalars['String']['input']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']['input']
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryFindUserEventsByUserIdArgs = {
  input: FindUserEventsByUserIdInput
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']['input']
  employeeUuid: Scalars['ID']['input']
}

export type QueryFirstPayrollDateArgs = {
  feinUuid: Scalars['String']['input']
}

export type QueryGetApprovedExpenseReportsArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryGetEmployeeComparisonArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryGetLevelArgs = {
  levelUuid: Scalars['String']['input']
  sequenceId: Scalars['Int']['input']
}

export type QueryGetPayrollEmployeesArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryGetPayrollEmployeesPostedArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']['input']
  locationUuid: Scalars['ID']['input']
}

export type QueryGetPosUserArgs = {
  userGuid: Scalars['ID']['input']
}

export type QueryGetPosUserV2Args = {
  userGuid: Scalars['ID']['input']
}

export type QueryIncompleteDirectDepositsArgs = {
  incompleteDirectDepositInput: IncompleteDirectDepositInput
}

export type QueryIntercomSessionDataArgs = {
  intercomClientType: IntercomClientType
}

export type QueryIsW2ReadyArgs = {
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryJobAssignmentsByIdArgs = {
  employeeId: Scalars['ID']['input']
  jobAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryJobsArgs = {
  parentLevelUuid: InputMaybe<Scalars['String']['input']>
}

export type QueryLoadTaxChecklistArgs = {
  datetime: InputMaybe<Scalars['String']['input']>
}

export type QueryMissingSsnEmployeesArgs = {
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryNavigableTaxAccountsArgs = {
  input: InputMaybe<NavigableTaxAccountsInput>
}

export type QueryNavigationArgs = {
  input: InputMaybe<NavigationInput>
}

export type QueryNewHireRequiredDocumentsForAssignedFieldsArgs = {
  request: RequiredDocumentsRequest
}

export type QueryPastPayrollsArgs = {
  pageIndex: InputMaybe<Scalars['Int']['input']>
  pageSize: Scalars['Int']['input']
  pageToken: InputMaybe<Scalars['String']['input']>
}

export type QueryPayCardCustomerAccessArgs = {
  input: PayCardCustomerAccessInput
}

export type QueryPayCardEmployeeAccessArgs = {
  input: PayCardEmployeeAccessInput
}

export type QueryPayChangeLogArgs = {
  employeeId: Scalars['ID']['input']
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Array<PayChangeLogSortInput>>
}

export type QueryPayGroupCalendarByDateRangeArgs = {
  endDate: InputMaybe<Scalars['Date']['input']>
  payGroupId: Scalars['ID']['input']
  startDate: InputMaybe<Scalars['Date']['input']>
}

export type QueryPaymentFrequencyAuditArgs = {
  einUuid: Scalars['String']['input']
}

export type QueryPayperiodArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryPayrollCustomerUsersArgs = {
  input: InputMaybe<PayrollCustomerUsersInput>
}

export type QueryPayrollEmployeeListArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryPayrollRequirementArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryPayrollsByQuarterArgs = {
  quarter: Scalars['Int']['input']
}

export type QueryPayrollsByYearQuarterArgs = {
  quarter: Scalars['Int']['input']
  year: Scalars['Int']['input']
}

export type QueryPreviousPayrollProviderDataArgs = {
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryPreviousYearUnprocessedPayrollsArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']['input']
}

export type QueryRetroPayForHourlyPayChangeArgs = {
  hourlyRetroPayInput: HourlyRetroPayInput
}

export type QueryRetroPayForSalaryChangeArgs = {
  salaryRetroPayInput: SalaryRetroPayInput
}

export type QuerySectionsArgs = {
  input: InputMaybe<SectionsInput>
}

export type QuerySutaRateAuditArgs = {
  einUuid: Scalars['String']['input']
  taxCode: Scalars['String']['input']
}

export type QueryTaxPackagesArgs = {
  quarter: InputMaybe<Scalars['Int']['input']>
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryTimesheetAggregatesArgs = {
  payPeriodUuid: Scalars['String']['input']
}

export type QueryTimesheetsArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryTipsDetailsArgs = {
  payPeriodUuid: Scalars['ID']['input']
}

export type QueryUserConnectionByEmailArgs = {
  input: UserConnectionByEmailInput
}

export type QueryW2DownloadUrlArgs = {
  w2PacketUuid: Scalars['String']['input']
}

export type QueryW2PacketsArgs = {
  year: Scalars['Int']['input']
}

export type QueryW2SettingsArgs = {
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type QueryErrorResponse = QueryError & {
  __typename?: 'QueryErrorResponse'
  errors: Array<Error>
  status: ResponseStatus
}

export type QuerySuccess = {
  status: ResponseStatus
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  dismissalPeriod: Maybe<DateRange>
  hireDate: Maybe<Scalars['String']['output']>
  rehireDate: Maybe<Scalars['String']['output']>
}

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']['input']
  securityRoleId: Scalars['ID']['input']
  workTaxLocationIds: Array<Scalars['ID']['input']>
}

export type Response = {
  __typename?: 'Response'
  response: Maybe<Scalars['String']['output']>
}

export type ResponseError = MutationError & {
  __typename?: 'ResponseError'
  errors: Array<Error>
  status: ResponseStatus
}

export type ResponseStatus = 'FAILED' | 'SUCCESS'

export type ResponseSuccess = MutationSuccess & {
  __typename?: 'ResponseSuccess'
  status: ResponseStatus
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']['output']
  name: Maybe<Scalars['String']['output']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: RestaurantJobStatusCode
}

export type RestaurantJobStatusCode =
  | 'Available'
  /** Restaurant job does not exist */
  | 'Deleted'

export type RestaurantUser = {
  __typename?: 'RestaurantUser'
  archived: Scalars['Boolean']['output']
  jobs: Array<Scalars['ID']['output']>
  locationGuid: Scalars['ID']['output']
  restaurantUserGuid: Scalars['ID']['output']
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  payFrequency: Maybe<PayFrequency>
  pendingPayChange: Maybe<PendingPayChange>
  salaryAllocations: Array<SalaryAllocation>
}

export type SalaryAllocation = {
  __typename?: 'SalaryAllocation'
  id: Scalars['ID']['output']
  jobAssignment: JobAssignment
  rate: Money
}

export type SalaryAllocationNotFoundError = {
  __typename?: 'SalaryAllocationNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type SalaryNotFoundError = {
  __typename?: 'SalaryNotFoundError'
  message: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyDoesNotExistError = {
  __typename?: 'SalaryPayFrequencyDoesNotExistError'
  message: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyNoneError = {
  __typename?: 'SalaryPayFrequencyNoneError'
  message: Maybe<Scalars['String']['output']>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: AmountWithPayInterval
  payFrequency: PayFrequency
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type SalaryRetroPayResponse =
  | InvalidStartDateError
  | SalaryPayFrequencyDoesNotExistError
  | SalaryPayFrequencyNoneError
  | SalaryRetroPay
  | TimesheetRetrievalError

export type SalaryType =
  | 'commission'
  | 'draw'
  | 'hourly'
  | 'salary'
  | 'ten99'
  | 'unknown'
  | 'w2'

export type SaveDeduction = {
  amount: Scalars['Float']['input']
  checkCodeUuid: Scalars['String']['input']
  deductionCodeUuid: Scalars['String']['input']
  employeeUuid: Scalars['String']['input']
  isPercentage: Scalars['Boolean']['input']
  paystub: Scalars['Int']['input']
}

export type SaveDeductionError = MutationError & {
  __typename?: 'SaveDeductionError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveDeductionResponse = SaveDeductionError | SaveDeductionSuccess

export type SaveDeductionSuccess = MutationSuccess & {
  __typename?: 'SaveDeductionSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveEarning = {
  amount: Scalars['Float']['input']
  baseRate: Scalars['Float']['input']
  checkCodeUuid: Scalars['String']['input']
  earningCodeUuid: Scalars['String']['input']
  employeeUuid: Scalars['String']['input']
  hours: Scalars['Float']['input']
  levels: InputMaybe<Array<LevelUuidSequence>>
  paystub: Scalars['Int']['input']
  positionUuid: Scalars['String']['input']
  rate: Scalars['Float']['input']
  week: Scalars['Int']['input']
}

export type SaveEarningError = MutationError & {
  __typename?: 'SaveEarningError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveEarningResponse = SaveEarningError | SaveEarningSuccess

export type SaveEarningSuccess = MutationSuccess & {
  __typename?: 'SaveEarningSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveEmployeeSsnResponse = ResponseError | ResponseSuccess

export type SaveNewEmployeeResponse = {
  __typename?: 'SaveNewEmployeeResponse'
  employeeId: Scalars['ID']['output']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  name: Scalars['String']['output']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  name: InputMaybe<Scalars['String']['input']>
  params: InputMaybe<Array<NavigationParam>>
}

export type SecurityRole =
  | 'APPLICANT'
  | 'CUSTOM'
  | 'CUSTOMER'
  | 'EMPLOYEE'
  | 'FIRED'
  | 'HR_EXECUTIVE'
  | 'HR_PLUS'
  | 'MANAGER'
  | 'NEW_HIRE'
  | 'PAYROLL'
  | 'PAYROLL_OPERATIONS'
  | 'SUPERUSER'
  | 'SUPER_ADMIN'
  | 'SUPER_ADMIN_LIGHT'

export type SendEmailVerificationError =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch

export type SendVerificationEmailInput = {
  idempotencyKey: Scalars['String']['input']
  source: InputMaybe<EmailVerificationSourceType>
}

export type SendVerificationEmailResult =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailSent

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  addressLine1: Scalars['String']['output']
  addressLine2: Scalars['String']['output']
  attention: Scalars['String']['output']
  city: Scalars['String']['output']
  recipientCompany: Scalars['String']['output']
  shippingAddressUuid: Scalars['String']['output']
  state: Scalars['String']['output']
  stateName: Scalars['String']['output']
  trackingNumber: Maybe<Scalars['String']['output']>
  zipCode: Scalars['String']['output']
}

export type SortDirection = 'ASC' | 'DESC'

export type SplitDepositType = 'DOLLAR' | 'PERCENTAGE'

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']['output']
  fipsCode: Scalars['Int']['output']
  fullName: Scalars['String']['output']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job: Maybe<Scalars['String']['output']>
  location: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type SutaRate = {
  __typename?: 'SutaRate'
  code: Scalars['String']['output']
  effectiveDate: Maybe<Scalars['Date']['output']>
  excludeFromCombinedRate: Scalars['Boolean']['output']
  isExempt: Scalars['Boolean']['output']
  isMajor: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  rate: Maybe<Scalars['Float']['output']>
  updatedAt: Maybe<Scalars['DateTime']['output']>
}

export type SutaRateAudit = {
  __typename?: 'SutaRateAudit'
  createdAt: Maybe<Scalars['DateTime']['output']>
  effectiveDate: Scalars['Date']['output']
  email: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  isExempt: Maybe<Scalars['Boolean']['output']>
  name: Maybe<Scalars['String']['output']>
  rate: Maybe<Scalars['Float']['output']>
}

export type TaskReopenColumn = 'Account' | 'Fein' | 'State'

export type TaskReopenErrorDetails = {
  __typename?: 'TaskReopenErrorDetails'
  column: Maybe<TaskReopenColumn>
  details: TaskReopenErrorType
}

export type TaskReopenErrorType = 'Invalid' | 'Missing' | 'NotFound' | 'Other'

export type TaskStatus =
  | 'CANCELED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'LOCKED'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SKIPPED'

export type TasksReopenError = {
  __typename?: 'TasksReopenError'
  account: Scalars['String']['output']
  errors: Maybe<Array<TaskReopenErrorDetails>>
  key: Scalars['String']['output']
  state: Scalars['String']['output']
  tin: Scalars['String']['output']
}

export type TasksReopenResponse = {
  __typename?: 'TasksReopenResponse'
  errors: Maybe<Array<TasksReopenError>>
  status: ResponseStatus
}

export type TasksRow = {
  account: Scalars['String']['input']
  key: Scalars['String']['input']
  state: Scalars['String']['input']
  tin: Scalars['String']['input']
}

export type TaxPackage = {
  __typename?: 'TaxPackage'
  fein: Scalars['String']['output']
  fileName: Scalars['String']['output']
  presignedLink: Scalars['String']['output']
  tin: Scalars['String']['output']
}

export type TaxPrepChecklistState = {
  __typename?: 'TaxPrepChecklistState'
  feins: Maybe<Array<TaxPrepFein>>
  quarter: Maybe<TaxPrepQuarter>
  status: TaxPrepChecklistStatus
  year: Maybe<Scalars['Int']['output']>
}

export type TaxPrepChecklistStatus =
  /**  Tax Status is in progress based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  | 'IN_PROGRESS'
  /**  Loading state, background-job running for tasks creation considerations  */
  | 'LOADING'
  /**  Tax Status is COMPLETED based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  | 'READY'
  /**  Server is not available, race conditions events, etc  */
  | 'UNAVAILABLE'

export type TaxPrepFein = {
  __typename?: 'TaxPrepFein'
  id: Scalars['ID']['output']
  /** Returns false if filing start date is null or in future quarter, or filing end date is in past quarter  */
  isActive: Scalars['Boolean']['output']
  /** Derived permissions from Pay Group will rule this field  */
  isRestricted: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  /** Tasks by State requiring completion. */
  states: Array<TaxPrepState>
  /** Tasks directly associated to this FEIN requiring completion. Tasks that are security restricted will be filtered out of this list. Use tasksCount for the unrestricted total. */
  tasks: Array<TaxPrepTask>
  /** Number of total tasks directly associated to this FEIN Accurate regardless of security restrictions. It will ignore tasks derived by State, check states field for that */
  tasksCount: Scalars['Int']['output']
  tin: Scalars['String']['output']
}

export type TaxPrepQuarter = 'Q1' | 'Q2' | 'Q3' | 'Q4'

export type TaxPrepState = {
  __typename?: 'TaxPrepState'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** Tasks directly associated to this State requiring completion. Tasks that are security restricted will be filtered out of this list. Use tasksCount for the unrestricted total. */
  tasks: Array<TaxPrepTask>
  /** Number of total tasks directly associated to this State in this FEIN Accurate regardless of security restrictions. */
  tasksCount: Scalars['Int']['output']
}

export type TaxPrepTask = {
  __typename?: 'TaxPrepTask'
  isReopened: Scalars['Boolean']['output']
  key: Scalars['String']['output']
  status: TaskStatus
  type: TaxTaskType
}

export type TaxTaskType =
  | 'TAXCENTER_FORM_8655'
  | 'TAXCENTER_MISSING_SSN'
  | 'TAXCENTER_PREVIOUS_PROVIDER'
  | 'TAXCENTER_STATE_PFML_TPA'
  | 'TAXCENTER_STATE_SIT_ACCOUNT'
  | 'TAXCENTER_STATE_SIT_FREQUENCY'
  | 'TAXCENTER_STATE_SIT_TPA'
  | 'TAXCENTER_STATE_SUTA_ACCOUNT'
  | 'TAXCENTER_STATE_SUTA_RATE'
  | 'TAXCENTER_STATE_SUTA_TPA'

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']['output']>
  hireDate: Maybe<Scalars['String']['output']>
  lastDayOfEmployment: Maybe<Scalars['String']['output']>
  lastDayToReceiveBenefits: Maybe<Scalars['String']['output']>
}

export type ThirdPartyBenefit = {
  __typename?: 'ThirdPartyBenefit'
  benefitType: ThirdPartyBenefitType
}

export type ThirdPartyBenefitType = 'DENTAL' | 'LIFE' | 'MEDICAL' | 'VISION'

export type TimeSheetStatus =
  | 'approved'
  | 'deleted'
  | 'denied'
  | 'open'
  | 'pending_approval'
  | 'returned'
  | 'unknown'

export type TimeSheetStatusV2 =
  | 'approved'
  | 'deleted'
  | 'denied'
  | 'open'
  | 'pending_approval'
  | 'returned'
  | 'unknown'

export type TimeSheetTiedToPayroll = 'all' | 'no' | 'ot' | 'tafwot'

export type TimesheetAggregateError = QueryError & {
  __typename?: 'TimesheetAggregateError'
  errors: Array<Error>
  status: ResponseStatus
}

export type TimesheetAggregateResponse =
  | TimesheetAggregateError
  | TimesheetAggregateSuccess

export type TimesheetAggregateSuccess = QuerySuccess & {
  __typename?: 'TimesheetAggregateSuccess'
  status: ResponseStatus
  timesheetAggregates: Array<TimesheetByPayrollAggregateV2>
}

export type TimesheetByPayrollAggregate = {
  __typename?: 'TimesheetByPayrollAggregate'
  earningEntries: Array<TimesheetEarningEntry>
  employeeUuid: Scalars['String']['output']
  isImported: Scalars['Boolean']['output']
  status: Maybe<TimeSheetStatus>
}

export type TimesheetByPayrollAggregateV2 = {
  __typename?: 'TimesheetByPayrollAggregateV2'
  earningEntries: Array<TimesheetEarningEntryV2>
  employeeUuid: Scalars['String']['output']
  isImported: Scalars['Boolean']['output']
  status: Maybe<TimeSheetStatusV2>
}

export type TimesheetEarningEntry = {
  __typename?: 'TimesheetEarningEntry'
  amount: Maybe<Scalars['Float']['output']>
  date: Maybe<Scalars['SpaDate']['output']>
  earningCode: TimesheetEntryEarningCode
  hoursWorked: Maybe<Scalars['Float']['output']>
  isOvertime: Scalars['Boolean']['output']
}

export type TimesheetEarningEntryV2 = {
  __typename?: 'TimesheetEarningEntryV2'
  amount: Maybe<Scalars['Float']['output']>
  date: Maybe<Scalars['Date']['output']>
  earningCode: TimesheetEntryEarningCodeV2
  hoursWorked: Maybe<Scalars['Float']['output']>
  isOvertime: Scalars['Boolean']['output']
  uuid: Maybe<Scalars['String']['output']>
}

export type TimesheetEntryEarningCode = {
  __typename?: 'TimesheetEntryEarningCode'
  isHourly: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  timeAwaySetupId: Scalars['Int']['output']
  uuid: Scalars['ID']['output']
}

export type TimesheetEntryEarningCodeV2 = {
  __typename?: 'TimesheetEntryEarningCodeV2'
  isHourly: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  timeAwaySetupId: Scalars['Int']['output']
  uuid: Scalars['ID']['output']
}

export type TimesheetRetrievalError = {
  __typename?: 'TimesheetRetrievalError'
  message: Maybe<Scalars['String']['output']>
}

export type TipsIntegrationSettings = 'NONE' | 'NORMAL' | 'POOLED'

export type TipsPerDay = {
  __typename?: 'TipsPerDay'
  amount: Scalars['Float']['output']
  date: Scalars['Date']['output']
  tipsReadyStatus: TipsReadyStatus
}

export type TipsReadyStatus =
  | 'inProgress'
  | 'noTips'
  | 'notMissingTips'
  | 'ready'

export type TipsType = 'none' | 'normal' | 'pooled'

export type ToastPrinted = {
  __typename?: 'ToastPrinted'
  totalChecks: Scalars['Int']['output']
  totalEmployees: Scalars['Int']['output']
}

export type ToastVersionIgnored = {
  __typename?: 'ToastVersionIgnored'
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type Unauthorized = {
  __typename?: 'Unauthorized'
  message: Scalars['String']['output']
}

export type UnlinkUserResult = PayrollUser | UserNotFound | UserNotLinked

export type UnprocessedPayroll = {
  __typename?: 'UnprocessedPayroll'
  checkDate: Scalars['Date']['output']
  endDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  payFrequency: PayFrequency
  startDate: Scalars['Date']['output']
}

export type UpcomingPayroll = {
  __typename?: 'UpcomingPayroll'
  dueDate: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['Date']['output']
  hasTips: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  manual: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  payDate: Scalars['Date']['output']
  startDate: Scalars['Date']['output']
  status: PayrollStatusV2
  timesheetsCount: Scalars['Int']['output']
  unApprovedTimesheetsCount: Scalars['Int']['output']
  unReceivedTips: Scalars['Int']['output']
}

export type UpdateEmployeePaymentMethodEmailResponse =
  | EmailInvalidError
  | EmailSendingError
  | EmployeeNotFoundError
  | EmployeePaymentMethodEmailResponse

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings: InputMaybe<UpdatePayCardAdminSettingsInput>
  payCardPayoutConfiguration: InputMaybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: InputMaybe<Scalars['Boolean']['input']>
  payCardTipsConfiguration: InputMaybe<PayCardTipsConfiguration>
}

export type UpdatePayCardCustomerSettingsResponse = {
  __typename?: 'UpdatePayCardCustomerSettingsResponse'
  payCardCustomerSettings: PayCardCustomerSettings
}

export type UpdatePendingPayChangeStatusResponse =
  | EmployeeNotFoundError
  | PendingPayChangeNotFoundErrors
  | PendingPayChangeStatusResponse

export type User = {
  __typename?: 'User'
  email: Maybe<Scalars['String']['output']>
  employeeUuid: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isEmailVerified: Scalars['Boolean']['output']
  name: PersonName
  toastIdentityGuid: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type UserAlreadyLinked = {
  __typename?: 'UserAlreadyLinked'
  username: Scalars['String']['output']
}

export type UserConnectionByEmailInput = {
  email: Scalars['String']['input']
}

export type UserConnectionByEmailQuery = {
  __typename?: 'UserConnectionByEmailQuery'
  connection: Connection
}

export type UserEvent =
  | UserLinkChanged
  | UserLinked
  | UserRoleAdded
  | UserRoleRemoved
  | UserUnlinked
  | UsernameChanged
  | UsernameChangedWithConflict

export type UserLinkChanged = {
  __typename?: 'UserLinkChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  newToastIdentityGuid: Scalars['ID']['output']
  oldToastIdentityGuid: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserLinked = {
  __typename?: 'UserLinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserMigrationChooseAnotherUsername = {
  __typename?: 'UserMigrationChooseAnotherUsername'
  message: Scalars['String']['output']
}

export type UserMigrationConfirmAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailConfirmationFailed
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationDefaultEmailSource = 'NEW' | 'PAYROLL' | 'TOAST'

export type UserMigrationDefaults = {
  __typename?: 'UserMigrationDefaults'
  canChangeEmail: Scalars['Boolean']['output']
  email: Maybe<Scalars['String']['output']>
  source: UserMigrationDefaultEmailSource
}

export type UserMigrationDefaultsResult =
  | UserMigrationDefaults
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationEmailConfirmationFailed = {
  __typename?: 'UserMigrationEmailConfirmationFailed'
  error: ConfirmVerificationEmailError
}

export type UserMigrationEmailExistsInCustomer = {
  __typename?: 'UserMigrationEmailExistsInCustomer'
  customerId: Scalars['ID']['output']
}

export type UserMigrationEmailNotVerified = {
  __typename?: 'UserMigrationEmailNotVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationEmailVerified = {
  __typename?: 'UserMigrationEmailVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime: Scalars['DateTime']['output']
}

export type UserMigrationFailed = {
  __typename?: 'UserMigrationFailed'
  message: Scalars['String']['output']
}

export type UserMigrationMigrateResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSuccess
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationMutation = {
  __typename?: 'UserMigrationMutation'
  confirmAccountVerificationEmail: UserMigrationConfirmAccountVerificationEmailResult
  mergePinOnlyUsers: PinOnlyUserMergeResult
  migrate: UserMigrationMigrateResult
  sendAccountVerificationEmail: UserMigrationSendAccountVerificationEmailResult
}

export type UserMigrationMutationConfirmAccountVerificationEmailArgs = {
  confirmationKey: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type UserMigrationMutationMigrateArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type UserMigrationMutationSendAccountVerificationEmailArgs = {
  email: Scalars['String']['input']
  idempotencyKey: Scalars['String']['input']
  source: InputMaybe<EmailVerificationSourceType>
}

export type UserMigrationNotEnabled = {
  __typename?: 'UserMigrationNotEnabled'
  message: Scalars['String']['output']
}

export type UserMigrationQuery = {
  __typename?: 'UserMigrationQuery'
  defaults: UserMigrationDefaultsResult
  settings: UserMigrationSettingsResult
  userRegistrationStatus: UserMigrationRegistrationStatusResult
  verifyAccountEmail: UserMigrationVerifyAccountEmailResult
}

export type UserMigrationQueryUserRegistrationStatusArgs = {
  companyCode: Scalars['String']['input']
  registrationId: Scalars['ID']['input']
}

export type UserMigrationQueryVerifyAccountEmailArgs = {
  email: Scalars['String']['input']
}

export type UserMigrationRegistrationCheckFailed = {
  __typename?: 'UserMigrationRegistrationCheckFailed'
  message: Scalars['String']['output']
  registrationId: Scalars['ID']['output']
}

export type UserMigrationRegistrationExpired = {
  __typename?: 'UserMigrationRegistrationExpired'
  registrationId: Scalars['ID']['output']
}

export type UserMigrationRegistrationIdNotFound = {
  __typename?: 'UserMigrationRegistrationIdNotFound'
  registrationId: Scalars['ID']['output']
}

export type UserMigrationRegistrationStatus = {
  __typename?: 'UserMigrationRegistrationStatus'
  usernameExists: Scalars['Boolean']['output']
}

export type UserMigrationRegistrationStatusResult =
  | UserMigrationFailed
  | UserMigrationRegistrationCheckFailed
  | UserMigrationRegistrationExpired
  | UserMigrationRegistrationIdNotFound
  | UserMigrationRegistrationStatus
  | UserMigrationRegistrationToastUserNotFound
  | UserMigrationUserAlreadyRegistered

export type UserMigrationRegistrationToastUserNotFound = {
  __typename?: 'UserMigrationRegistrationToastUserNotFound'
  registrationId: Scalars['ID']['output']
}

export type UserMigrationSendAccountVerificationEmailFailed = {
  __typename?: 'UserMigrationSendAccountVerificationEmailFailed'
  error: Maybe<SendEmailVerificationError>
}

export type UserMigrationSendAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSendAccountVerificationEmailFailed
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound
  | UserMigrationVerificationEmailSent

export type UserMigrationSettings = {
  __typename?: 'UserMigrationSettings'
  canDefer: Scalars['Boolean']['output']
}

export type UserMigrationSettingsResult =
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSettings

export type UserMigrationSuccess = {
  __typename?: 'UserMigrationSuccess'
  companyCodes: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationToastAccountType = 'CREATED' | 'EXISTING' | 'MAPPED'

export type UserMigrationToastUserHasCredentials = {
  __typename?: 'UserMigrationToastUserHasCredentials'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationUserAlreadyMigrated = {
  __typename?: 'UserMigrationUserAlreadyMigrated'
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationUserAlreadyRegistered = {
  __typename?: 'UserMigrationUserAlreadyRegistered'
  registrationId: Scalars['ID']['output']
}

export type UserMigrationUserNotFound = {
  __typename?: 'UserMigrationUserNotFound'
  userId: Scalars['ID']['output']
}

export type UserMigrationVerificationEmailSent = {
  __typename?: 'UserMigrationVerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type UserMigrationVerifyAccountEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserNotFound = {
  __typename?: 'UserNotFound'
  userId: Scalars['ID']['output']
}

export type UserNotLinked = {
  __typename?: 'UserNotLinked'
  userId: Scalars['ID']['output']
}

export type UserNotMapped = {
  __typename?: 'UserNotMapped'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserRoleAdded = {
  __typename?: 'UserRoleAdded'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserRoleRemoved = {
  __typename?: 'UserRoleRemoved'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserType = 'admin' | 'customer'

export type UserUnlinked = {
  __typename?: 'UserUnlinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChanged = {
  __typename?: 'UsernameChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername: Maybe<Scalars['String']['output']>
  toastIdentityGuid: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChangedWithConflict = {
  __typename?: 'UsernameChangedWithConflict'
  actorId: Scalars['ID']['output']
  conflictingUserId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername: Maybe<Scalars['String']['output']>
  toastIdentityGuid: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameExists = {
  __typename?: 'UsernameExists'
  username: Scalars['String']['output']
}

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VendorNetPays = {
  __typename?: 'VendorNetPays'
  amount: Scalars['Float']['output']
}

export type VerificationEmailConfirmed = {
  __typename?: 'VerificationEmailConfirmed'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
}

export type VerificationEmailNotSent = {
  __typename?: 'VerificationEmailNotSent'
  message: Maybe<Scalars['String']['output']>
}

export type VerificationEmailSent = {
  __typename?: 'VerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type VerificationEmailSentEvent = {
  __typename?: 'VerificationEmailSentEvent'
  emailHash: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
  sequence: Scalars['Int']['output']
  toastIdentityGuid: Scalars['String']['output']
}

export type VeteranStatus =
  | 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN'
  | 'ARMED_FORCES_SERVICE_MEDAL_VETERAN'
  | 'DISABLED_VETERAN'
  | 'NOT_A_VETERAN'
  | 'RECENTLY_SEPARATED_VETERAN'
  | 'SPECIAL_DISABLED_VETERAN'
  | 'VETERAN'
  | 'VIETNAM_ERA_VETERAN'

export type W2Employee = {
  __typename?: 'W2Employee'
  employmentStatus: PayrollEmploymentStatus
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  printSetting: W2EmployeeSetting
  user: Maybe<W2EmployeeUser>
}

export type W2EmployeeSetting = 'ELECTRONIC' | 'PAPER' | 'UNKNOWN'

export type W2EmployeeUser = {
  __typename?: 'W2EmployeeUser'
  email: Scalars['String']['output']
  hasLoggedIn: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  isEmailVerified: Scalars['Boolean']['output']
  username: Scalars['String']['output']
}

export type W2Fein = {
  __typename?: 'W2Fein'
  employees: Array<W2Employee>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  tin: Scalars['String']['output']
  /** This represents the total number of employees receiving W2s, the employees field may vary because will expose those you have access to. */
  totalEmployees: Scalars['Int']['output']
}

export type W2Packet = {
  __typename?: 'W2Packet'
  feinName: Scalars['String']['output']
  feinUuid: Scalars['String']['output']
  legal: Array<W2PacketData>
  letter: Array<W2PacketData>
}

export type W2PacketData = {
  __typename?: 'W2PacketData'
  filename: Scalars['String']['output']
  type: W2EmployeeSetting
  uuid: Scalars['String']['output']
}

export type W2PrintingStatus = 'SELF_PRINTED' | 'TOAST_PRINTED'

export type W2PrintingStatusError = MutationError & {
  __typename?: 'W2PrintingStatusError'
  errors: Array<Error>
  status: ResponseStatus
}

export type W2PrintingStatusResponse =
  | W2PrintingStatusError
  | W2PrintingStatusSuccess

export type W2PrintingStatusSuccess = MutationSuccess & {
  __typename?: 'W2PrintingStatusSuccess'
  result: W2PrintingStatus
  status: ResponseStatus
}

export type W2SettingsInput = {
  printingStatus: W2PrintingStatus
  shippingAddress: InputMaybe<W2ShippingAddressInput>
}

export type W2ShippingAddress = {
  __typename?: 'W2ShippingAddress'
  businessName: Scalars['String']['output']
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  id: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2: Scalars['String']['output']
  recipientName: Scalars['String']['output']
  state: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export type W2ShippingAddressInput = {
  businessName: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: InputMaybe<Scalars['String']['input']>
  line1: Scalars['String']['input']
  line2: InputMaybe<Scalars['String']['input']>
  recipientName: InputMaybe<Scalars['String']['input']>
  state: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export type WorkTaxLocation = {
  __typename?: 'WorkTaxLocation'
  customerUuid: Scalars['String']['output']
  name: Scalars['String']['output']
  shippingAddressUuid: Scalars['String']['output']
}

export type Join__Graph =
  | 'CUSTOMER'
  | 'EC_HUMAN_RESOURCES'
  | 'EC_JOBS'
  | 'EC_PAYROLL'
  | 'EC_PAYROLL_ONBOARDING'
  | 'EC_PAYROLL_OPERATIONS'
  | 'EC_WEB'
  | 'NAVIGATION'
  | 'PAYROLL'
  | 'PAYROLL_BENEFITS'
  | 'SDP'

export type Link__Purpose =
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  | 'EXECUTION'
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  | 'SECURITY'

export type PageItemsQueryVariables = Exact<{
  input: NavigationInput
}>

export type PageItemsQuery = {
  __typename?: 'Query'
  navigation: {
    __typename?: 'NavigationResult'
    result: {
      __typename?: 'Navigation'
      path: string
      name: string
      url: string
      matchUrls: Array<string>
      labelNew: {
        __typename?: 'LocalizableMessage'
        key: string
        message: string
      }
      children: Array<{
        __typename?: 'Navigation'
        path: string
        name: string
        url: string
        matchUrls: Array<string>
        labelNew: {
          __typename?: 'LocalizableMessage'
          key: string
          message: string
        }
      }>
    } | null
    errors: Array<{
      __typename?: 'NavigationErrors'
      path: string
      errors: Array<{
        __typename?: 'NavigationError'
        type: string
        message: string
      }>
    }>
  }
}

export type IgnorePosUserInPayrollMutationVariables = Exact<{
  userGuid: Scalars['ID']['input']
}>

export type IgnorePosUserInPayrollMutation = {
  __typename?: 'Mutation'
  ignorePosUserInPayroll: boolean | null
}

export type GetCustomerConfigurationQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCustomerConfigurationQuery = {
  __typename?: 'Query'
  customerConfiguration: {
    locations: Array<PayrollLocation>
  }
}

export type GetCustomerFeinsQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerFeinsQuery = {
  __typename?: 'Query'
  feins: Array<Fein>
}

export type FindPayrollIdsQueryVariables = Exact<{
  toastRestaurantGuid: Scalars['ID']['input']
  toastIdentityGuid: Scalars['ID']['input']
}>

export type FindPayrollIdsQuery = {
  __typename?: 'Query'
  findEmployeeForRestaurantByToastIdentityGuid: {
    __typename?: 'Employee'
    id: string
    companyCode: string | null
  } | null
}

export type GetUnmappedPosUsersQueryVariables = Exact<{ [key: string]: never }>

export type GetUnmappedPosUsersQuery = {
  __typename?: 'Query'
  getUnmappedPosUsers: Array<{
    __typename?: 'PosRawEmployee'
    email: string
    userGuid: string
    restaurantUserGuid: string
    locationGuid: string
    archived: boolean
    disabled: boolean
    payrollLocationId: number
    userName: {
      __typename?: 'PersonName'
      first: string | null
      last: string | null
    }
  }>
}

export const PageItemsDocument = gql`
  query PageItems($input: NavigationInput!) {
    navigation(input: $input) {
      result {
        path
        name
        labelNew {
          key
          message
        }
        url
        matchUrls
        children {
          path
          name
          labelNew {
            key
            message
          }
          url
          matchUrls
        }
      }
      errors {
        path
        errors {
          type
          message
        }
      }
    }
  }
`

/**
 * __usePageItemsQuery__
 *
 * To run a query within a React component, call `usePageItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageItemsQuery(
  baseOptions: Apollo.QueryHookOptions<PageItemsQuery, PageItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PageItemsQuery, PageItemsQueryVariables>(
    PageItemsDocument,
    options
  )
}
export function usePageItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PageItemsQuery,
    PageItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PageItemsQuery, PageItemsQueryVariables>(
    PageItemsDocument,
    options
  )
}
export type PageItemsQueryHookResult = ReturnType<typeof usePageItemsQuery>
export type PageItemsLazyQueryHookResult = ReturnType<
  typeof usePageItemsLazyQuery
>
export type PageItemsQueryResult = Apollo.QueryResult<
  PageItemsQuery,
  PageItemsQueryVariables
>
export const IgnorePosUserInPayrollDocument = gql`
  mutation IgnorePosUserInPayroll($userGuid: ID!) {
    ignorePosUserInPayroll(userGuid: $userGuid)
  }
`
export type IgnorePosUserInPayrollMutationFn = Apollo.MutationFunction<
  IgnorePosUserInPayrollMutation,
  IgnorePosUserInPayrollMutationVariables
>

/**
 * __useIgnorePosUserInPayrollMutation__
 *
 * To run a mutation, you first call `useIgnorePosUserInPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePosUserInPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePosUserInPayrollMutation, { data, loading, error }] = useIgnorePosUserInPayrollMutation({
 *   variables: {
 *      userGuid: // value for 'userGuid'
 *   },
 * });
 */
export function useIgnorePosUserInPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnorePosUserInPayrollMutation,
    IgnorePosUserInPayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IgnorePosUserInPayrollMutation,
    IgnorePosUserInPayrollMutationVariables
  >(IgnorePosUserInPayrollDocument, options)
}
export type IgnorePosUserInPayrollMutationHookResult = ReturnType<
  typeof useIgnorePosUserInPayrollMutation
>
export type IgnorePosUserInPayrollMutationResult =
  Apollo.MutationResult<IgnorePosUserInPayrollMutation>
export type IgnorePosUserInPayrollMutationOptions = Apollo.BaseMutationOptions<
  IgnorePosUserInPayrollMutation,
  IgnorePosUserInPayrollMutationVariables
>
export const getCustomerConfigurationDocument = gql`
  query GetCustomerConfiguration {
    customerConfiguration {
      locations {
        exportId
        id
        jobs {
          id
          name
          exportId
        }
        name
      }
    }
  }
`

/**
 * __useGetCustomerConfigurationQuery
 *
 * To run a query within a React component, call `useGetCustomerConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerConfigurationQuery,
    GetCustomerConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomerConfigurationQuery,
    GetCustomerConfigurationQueryVariables
  >(getCustomerConfigurationDocument, options)
}
export function useGetCustomerConfigurationLazyQuery(
  baseOptions: Apollo.LazyQueryHookOptions<
    GetCustomerConfigurationQuery,
    GetCustomerConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerConfigurationQuery,
    GetCustomerConfigurationQueryVariables
  >(getCustomerConfigurationDocument, options)
}
export type GetCustomerConfigurationQueryHookResult = ReturnType<
  typeof useGetCustomerConfigurationQuery
>
export type GetCustomerConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetCustomerConfigurationLazyQuery
>
export type GetCustomerConfigurationQueryResult = Apollo.QueryResult<
  GetCustomerConfigurationQuery,
  GetCustomerConfigurationQueryVariables
>
export const getCustomerFeinsDocument = gql`
  query GetCustomerFeins {
    feins {
      id
      name
      workTaxLocationIds
    }
  }
`

/**
 * __useGetCustomerFeinsQuery
 *
 * To run a query within a React component, call `useGetCustomerFeinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFeinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFeinsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerFeinsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerFeinsQuery,
    GetCustomerFeinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerFeinsQuery, GetCustomerFeinsQueryVariables>(
    getCustomerFeinsDocument,
    options
  )
}
export function useGetCustomerFeinsLazyQuery(
  baseOptions: Apollo.LazyQueryHookOptions<
    GetCustomerFeinsQuery,
    GetCustomerFeinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerFeinsQuery,
    GetCustomerFeinsQueryVariables
  >(getCustomerFeinsDocument, options)
}
export type GetCustomerFeinsQueryHookResult = ReturnType<
  typeof useGetCustomerFeinsQuery
>
export type GetCustomerFeinsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerFeinsLazyQuery
>
export type GetCustomerFeinsQueryResult = Apollo.QueryResult<
  GetCustomerFeinsQuery,
  GetCustomerFeinsQueryVariables
>
export const FindPayrollIdsDocument = gql`
  query findPayrollIds($toastRestaurantGuid: ID!, $toastIdentityGuid: ID!) {
    findEmployeeForRestaurantByToastIdentityGuid(
      toastRestaurantGuid: $toastRestaurantGuid
      toastIdentityGuid: $toastIdentityGuid
    ) {
      id
      companyCode
    }
  }
`

/**
 * __useFindPayrollIdsQuery__
 *
 * To run a query within a React component, call `useFindPayrollIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPayrollIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPayrollIdsQuery({
 *   variables: {
 *      toastRestaurantGuid: // value for 'toastRestaurantGuid'
 *      toastIdentityGuid: // value for 'toastIdentityGuid'
 *   },
 * });
 */
export function useFindPayrollIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPayrollIdsQuery,
    FindPayrollIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindPayrollIdsQuery, FindPayrollIdsQueryVariables>(
    FindPayrollIdsDocument,
    options
  )
}
export function useFindPayrollIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPayrollIdsQuery,
    FindPayrollIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindPayrollIdsQuery, FindPayrollIdsQueryVariables>(
    FindPayrollIdsDocument,
    options
  )
}
export type FindPayrollIdsQueryHookResult = ReturnType<
  typeof useFindPayrollIdsQuery
>
export type FindPayrollIdsLazyQueryHookResult = ReturnType<
  typeof useFindPayrollIdsLazyQuery
>
export type FindPayrollIdsQueryResult = Apollo.QueryResult<
  FindPayrollIdsQuery,
  FindPayrollIdsQueryVariables
>
export const GetUnmappedPosUsersDocument = gql`
  query GetUnmappedPosUsers {
    getUnmappedPosUsers {
      userName {
        first
        last
      }
      email
      userGuid
      restaurantUserGuid
      locationGuid
      archived
      disabled
      payrollLocationId
    }
  }
`

/**
 * __useGetUnmappedPosUsersQuery__
 *
 * To run a query within a React component, call `useGetUnmappedPosUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnmappedPosUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnmappedPosUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnmappedPosUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnmappedPosUsersQuery,
    GetUnmappedPosUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUnmappedPosUsersQuery,
    GetUnmappedPosUsersQueryVariables
  >(GetUnmappedPosUsersDocument, options)
}
export function useGetUnmappedPosUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnmappedPosUsersQuery,
    GetUnmappedPosUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUnmappedPosUsersQuery,
    GetUnmappedPosUsersQueryVariables
  >(GetUnmappedPosUsersDocument, options)
}
export type GetUnmappedPosUsersQueryHookResult = ReturnType<
  typeof useGetUnmappedPosUsersQuery
>
export type GetUnmappedPosUsersLazyQueryHookResult = ReturnType<
  typeof useGetUnmappedPosUsersLazyQuery
>
export type GetUnmappedPosUsersQueryResult = Apollo.QueryResult<
  GetUnmappedPosUsersQuery,
  GetUnmappedPosUsersQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AdditionalEarningResponse: [
      'EarningListEmptyError',
      'EmployeeNotFoundError',
      'JobAssignmentNotFoundError',
      'MissingPayGroupError',
      'NextPayPeriodNotFoundError',
      'NextPaycheckDateNotFoundError',
      'Paycheck'
    ],
    AggregateInvoiceResult: [
      'AggregatePayrollInvoiceError',
      'AggregatePayrollInvoiceSuccess'
    ],
    AllEmploymentStatuses: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    ArchiveResponse: ['ArchiveError', 'ArchiveSuccess'],
    BringBackResponse: ['ResponseError', 'ResponseSuccess'],
    ChangeLinkedUsernameResult: [
      'CannotChangeUnlinkedUsername',
      'InvalidUsername',
      'PayrollUser',
      'ToastVersionIgnored',
      'UserNotFound',
      'UsernameExists'
    ],
    ChangeSalaryResponse: [
      'EmployeeNotFoundError',
      'EmptySalaryAllocationsError',
      'InvalidEffectiveDateError',
      'Salary',
      'SalaryAllocationNotFoundError',
      'SalaryNotFoundError'
    ],
    ChangeUnlinkedUsernameResult: [
      'CannotChangeLinkedUsername',
      'InvalidUsername',
      'PayrollUser',
      'UserNotFound',
      'UsernameExists'
    ],
    CheckCodesConfigResponse: [
      'CheckCodesConfigQuerySuccess',
      'QueryErrorResponse'
    ],
    ConfirmVerificationEmailError: [
      'ConfirmationExpired',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    ConfirmVerificationEmailResult: [
      'ConfirmationExpired',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailConfirmed',
      'VerificationEmailNotSent'
    ],
    EcPayrollResponse: ['ResponseError', 'ResponseSuccess'],
    EmailVerificationError: [
      'ConfirmationExpired',
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailNotFound',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    EmailVerificationEvent: [
      'ConfirmedEvent',
      'EmailChangedEvent',
      'EmailVerificationFailed',
      'VerificationEmailSentEvent'
    ],
    EmailVerificationResult: [
      'ConfirmationExpired',
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailNotFound',
      'EmailVerification',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    EmploymentStatus: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    Form8655Response: ['Form8655Error', 'Form8655Success'],
    HourlyRetroPayResponse: [
      'HourlyRetroPay',
      'InvalidStartDateError',
      'JobAssignmentNotFoundError',
      'TimesheetRetrievalError'
    ],
    LinkIfMappedResult: [
      'LinkingNotAvailable',
      'MappedUserMismatchedUsername',
      'PayrollUser',
      'UserAlreadyLinked',
      'UserNotMapped'
    ],
    LinkUserResult: ['PayrollUser', 'UserNotFound'],
    MutationError: [
      'ArchiveError',
      'Form8655Error',
      'OpenError',
      'PostError',
      'ResponseError',
      'SaveDeductionError',
      'SaveEarningError',
      'W2PrintingStatusError'
    ],
    MutationSuccess: [
      'ArchiveSuccess',
      'Form8655Success',
      'OpenSuccess',
      'PostSuccess',
      'ResponseSuccess',
      'SaveDeductionSuccess',
      'SaveEarningSuccess',
      'W2PrintingStatusSuccess'
    ],
    OpenResponse: ['OpenError', 'OpenSuccess'],
    PagedResult: ['PastPayrollPagedResult'],
    PayChangeLogResponse: [
      'AbsentPayChangeLogTokenError',
      'InvalidPayChangeLogTokenError',
      'PayChangeLog'
    ],
    PayGroupCalendarResult: ['PayGroupCalendar', 'Unauthorized'],
    PayrollImportResponse: ['ResponseError', 'ResponseSuccess'],
    PinOnlyUserMergeResult: [
      'PinOnlyUserMergeFailed',
      'PinOnlyUserMergeSuccess'
    ],
    PostResponse: ['PostError', 'PostSuccess'],
    PreviousYearUnprocessedPayrollsResponse: [
      'PreviousYearUnprocessedPayrollsQuerySuccess',
      'QueryErrorResponse'
    ],
    QueryError: [
      'AggregatePayrollInvoiceError',
      'QueryErrorResponse',
      'TimesheetAggregateError'
    ],
    QuerySuccess: [
      'AggregatePayrollInvoiceSuccess',
      'CheckCodesConfigQuerySuccess',
      'PreviousYearUnprocessedPayrollsQuerySuccess',
      'TimesheetAggregateSuccess'
    ],
    SalaryRetroPayResponse: [
      'InvalidStartDateError',
      'SalaryPayFrequencyDoesNotExistError',
      'SalaryPayFrequencyNoneError',
      'SalaryRetroPay',
      'TimesheetRetrievalError'
    ],
    SaveDeductionResponse: ['SaveDeductionError', 'SaveDeductionSuccess'],
    SaveEarningResponse: ['SaveEarningError', 'SaveEarningSuccess'],
    SaveEmployeeSsnResponse: ['ResponseError', 'ResponseSuccess'],
    SendEmailVerificationError: [
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch'
    ],
    SendVerificationEmailResult: [
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailSent'
    ],
    TimesheetAggregateResponse: [
      'TimesheetAggregateError',
      'TimesheetAggregateSuccess'
    ],
    UnlinkUserResult: ['PayrollUser', 'UserNotFound', 'UserNotLinked'],
    UpdateEmployeePaymentMethodEmailResponse: [
      'EmailInvalidError',
      'EmailSendingError',
      'EmployeeNotFoundError',
      'EmployeePaymentMethodEmailResponse'
    ],
    UpdatePendingPayChangeStatusResponse: [
      'EmployeeNotFoundError',
      'PendingPayChangeNotFoundErrors',
      'PendingPayChangeStatusResponse'
    ],
    UserEvent: [
      'UserLinkChanged',
      'UserLinked',
      'UserRoleAdded',
      'UserRoleRemoved',
      'UserUnlinked',
      'UsernameChanged',
      'UsernameChangedWithConflict'
    ],
    UserMigrationConfirmAccountVerificationEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailConfirmationFailed',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationDefaultsResult: [
      'UserMigrationDefaults',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationMigrateResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailNotVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSuccess',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationRegistrationStatusResult: [
      'UserMigrationFailed',
      'UserMigrationRegistrationCheckFailed',
      'UserMigrationRegistrationExpired',
      'UserMigrationRegistrationIdNotFound',
      'UserMigrationRegistrationStatus',
      'UserMigrationRegistrationToastUserNotFound',
      'UserMigrationUserAlreadyRegistered'
    ],
    UserMigrationSendAccountVerificationEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSendAccountVerificationEmailFailed',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound',
      'UserMigrationVerificationEmailSent'
    ],
    UserMigrationSettingsResult: [
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSettings'
    ],
    UserMigrationVerifyAccountEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailNotVerified',
      'UserMigrationEmailVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    W2PrintingStatusResponse: [
      'W2PrintingStatusError',
      'W2PrintingStatusSuccess'
    ]
  }
}
export default result
