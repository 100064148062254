import { useMutation } from '@tanstack/react-query'
import { apiCall } from './apiCall'
import { BulkEmailSendResp } from './types'

type Args = {
  companyCode: string | undefined
  userUuids: Array<string> | undefined
  onSuccess: (resp: BulkEmailSendResp) => void
  onError: (error: unknown) => void
}

const useSendPayrollInviteEmailMutation = (args: Args) => {
  const { companyCode, userUuids, onSuccess, onError } = args

  const mutation = useMutation({
    mutationFn: () => {
      return apiCall<BulkEmailSendResp>({
        url: `/${companyCode}/human-resources/users/invitation-emails`,
        method: 'POST',
        body: { userUuids: userUuids }
      })
    },

    mutationKey: [companyCode, userUuids, 'sendPayrollInvitationEmail'],
    onSuccess,
    onError
  })
  return mutation
}

export default useSendPayrollInviteEmailMutation
