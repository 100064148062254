import { useTranslation } from '@local/translations'
import { AlertModal, Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import useSendPayrollInviteEmailMutation from '../data/useSendPayrollInviteEmailMutation'
import { BulkEmailSendResp, Employee } from '../data/types'
import { useEmployeeListSelected } from '../pages/TeamEmployeesPage/EmployeeListSelectedContext'
import { isEmailValid } from '../helpers/email'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { formatNameObject } from '../helpers/name'

// This is slightly redundant with EmailSendModal -- we can refactor those at some point
// to reduce into a single component
const EmailSendBulkModal = ({
  open = false,
  companyCode,
  updateSentInvites,
  onClose
}: {
  open?: boolean
  companyCode: string
  updateSentInvites: (items: BulkEmailSendResp) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()

  const { showSuccessSnackBar, showErrorSnackBar, showSnackBar } = useSnackBar()

  const { selectedIds, selectedEmployeesMap } = useEmployeeListSelected()

  const employeesToEmail = Object.values(selectedEmployeesMap).filter(
    (ee) =>
      ee &&
      selectedIds.has(ee.employeeGraph?.user?.id!) &&
      ee.employeeGraph?.user?.email &&
      isEmailValid(ee.employeeGraph?.user?.email)
  ) as Employee[]
  const numNoEmails = selectedIds.size - employeesToEmail.length

  const employeesActive = employeesToEmail.filter(
    (ee) =>
      ee.employeeGraph?.employment?.employmentStatus.__typename !==
      'TerminatedEmploymentStatus'
  )

  const employeesTerminated = employeesToEmail.filter(
    (ee) =>
      ee.employeeGraph?.employment?.employmentStatus.__typename ===
      'TerminatedEmploymentStatus'
  )

  const onEmailSendSuccess = (
    { emailResults, sendCount }: BulkEmailSendResp,
    employeesEmailAttempted: Employee[]
  ) => {
    if (sendCount === 0 && employeesEmailAttempted.length === 1) {
      showErrorSnackBar(
        t('employeeTable.emailModal.resultAlert.couldNotInviteSingle', {
          name: formatNameObject(
            employeesEmailAttempted[0].employeeGraph?.user?.name!
          )
        }),
        { showIcon: true }
      )
    } else if (sendCount === 0) {
      showErrorSnackBar(
        t('employeeTable.emailModal.resultAlert.couldNotInviteMultiple', {
          count: employeesEmailAttempted.length
        }),
        {
          showIcon: true
        }
      )
    } else if (employeesEmailAttempted.length > sendCount) {
      showSnackBar(
        t('employeeTable.emailModal.resultAlert.sentInvitePartial', {
          successCount: sendCount,
          failureCount: employeesEmailAttempted.length - sendCount
        })
      )
      updateSentInvites({ emailResults, sendCount })
    } else if (sendCount === 1) {
      const eeSent = employeesEmailAttempted.find(
        (ee) => ee.employeeGraph?.user?.id! === Object.keys(emailResults)[0]
      )!
      showSuccessSnackBar(
        t('employeeTable.emailModal.resultAlert.sentInviteSingle', {
          name: formatNameObject(eeSent.employeeGraph?.user?.name!)
        }),
        { showIcon: true }
      )
      updateSentInvites({ emailResults, sendCount })
    } else {
      showSuccessSnackBar(
        t('employeeTable.emailModal.resultAlert.sentInviteMultiple', {
          count: sendCount
        }),
        {
          showIcon: true
        }
      )
      updateSentInvites({ emailResults, sendCount })
    }
    onClose()
  }

  const sendEmail = useSendPayrollInviteEmailMutation({
    companyCode: companyCode,
    userUuids: employeesToEmail.map((ee) => ee.employeeGraph?.user?.id!),
    onSuccess: (response) => onEmailSendSuccess(response, employeesToEmail),
    onError: () => {
      showErrorSnackBar(
        t('employeeTable.emailModal.resultAlert.couldNotInviteMultiple', {
          count: employeesToEmail.length
        }),
        {
          showIcon: true
        }
      )
      onClose()
    }
  })

  const onClickSend = async () => {
    await sendEmail.mutateAsync().catch((e) => console.log(e))
    onClose()
  }

  return (
    <AlertModal testId={'send-email-modal'} isOpen={open} size='xxl'>
      {!!numNoEmails && (
        <div className='w-full px-6'>
          <Alert showIcon variant='error' className='w-full mb-6'>
            {t(
              numNoEmails === 1
                ? 'employeeTable.emailModal.excludedAlertSingle'
                : 'employeeTable.emailModal.excludedAlertMultiple',
              {
                count: numNoEmails
              }
            )}
          </Alert>
        </div>
      )}
      <Modal.Header>
        {!employeesToEmail.length
          ? t('employeeTable.emailModal.header.noEmployees')
          : t(
              employeesToEmail.length === 1
                ? 'employeeTable.emailModal.header.sendInviteSingle'
                : 'employeeTable.emailModal.header.sendInviteMultiple',
              {
                count: employeesToEmail.length
              }
            )}
      </Modal.Header>
      <Modal.Body>
        <div className='space-y-6 text-default'>
          {!employeesToEmail.length
            ? t('employeeTable.emailModal.body.noEmployees')
            : employeesTerminated.length > 0 && employeesActive.length > 0
            ? employeesTerminated.length === 1 && employeesActive.length === 1
              ? t(
                  'employeeTable.emailModal.body.numSentActiveSingleTerminatedSingle'
                )
              : employeesTerminated.length > 1 && employeesActive.length === 1
              ? t(
                  'employeeTable.emailModal.body.numSentActiveSingleTerminatedMultiple',
                  { countTerminated: employeesTerminated.length }
                )
              : employeesTerminated.length === 1 && employeesActive.length > 1
              ? t(
                  'employeeTable.emailModal.body.numSentActiveMultipleTerminatedSingle',
                  { countActive: employeesActive.length }
                )
              : t(
                  'employeeTable.emailModal.body.numSentActiveMultipleTerminatedMultiple',
                  {
                    countActive: employeesActive.length,
                    countTerminated: employeesTerminated.length
                  }
                )
            : employeesTerminated.length === 1 && employeesActive.length === 0
            ? t('employeeTable.emailModal.body.numSentTerminatedSingle')
            : employeesTerminated.length > 1 && employeesActive.length === 0
            ? t('employeeTable.emailModal.body.numSentTerminatedMultiple', {
                count: employeesTerminated.length
              })
            : t(
                employeesActive.length === 1
                  ? 'employeeTable.emailModal.body.numSentSingle'
                  : 'employeeTable.emailModal.body.numSentMultiple',
                {
                  count: employeesActive.length
                }
              )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant='link'>
          {t('employeeTable.emailModal.footer.cancel')}
        </Button>
        {!!employeesToEmail.length && (
          <Button
            onClick={onClickSend}
            size={screenSize < ScreenSize.MD ? 'lg' : 'sm'}
            disabled={sendEmail.isLoading}
            data-metrics-id='team-page-email-bulk'
          >
            {sendEmail.isLoading ? (
              <>
                <AutorenewIcon
                  accessibility='decorative'
                  className='animate-spin'
                />
                <span className='pl-2'>
                  {t(
                    employeesToEmail.length === 1
                      ? 'employeeTable.emailModal.footer.sendInviteSingle'
                      : 'employeeTable.emailModal.footer.sendInviteMultiple',
                    {
                      count: employeesToEmail.length
                    }
                  )}
                </span>
              </>
            ) : (
              t(
                employeesToEmail.length === 1
                  ? 'employeeTable.emailModal.footer.sendInviteSingle'
                  : 'employeeTable.emailModal.footer.sendInviteMultiple',
                {
                  count: employeesToEmail.length
                }
              )
            )}
          </Button>
        )}
      </Modal.Footer>
    </AlertModal>
  )
}

export default EmailSendBulkModal
