import classnames from 'classnames'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { useTranslation } from '../hooks'

type Props = {
  userInitials: string
  isTerminated: boolean
}

const EmployeeAvatar = ({ userInitials, isTerminated }: Props) => {
  const { t } = useTranslation()

  return (
    <Tooltip accessibility='decorative' placement='left'>
      <Tooltip.Trigger tabIndex={isTerminated ? 0 : -1}>
        <div
          className={classnames(
            'rounded-full overflow-hidden w-10 h-10 basis-10 mr-4 shrink-0 grow-0'
          )}
        >
          <div
            className={`${
              isTerminated
                ? 'bg-gray-25 text-disabled'
                : 'bg-primary-0 text-primary-25'
            } font-effra w-full h-full flex justify-center items-center`}
          >
            {userInitials.toUpperCase()}
          </div>
        </div>
      </Tooltip.Trigger>
      {isTerminated && <Tooltip.Content>{t('terminated')}</Tooltip.Content>}
    </Tooltip>
  )
}

export default EmployeeAvatar
