import * as React from 'react'
import { useState } from 'react'
import * as singleSpa from 'single-spa'
import { PrefetchLink } from '../redirect-links/PrefetchLink'
import { getRedirectUrl, Url } from '../redirect-links/helpers'

type Props = {
  recordLink: Url
  children: React.ReactNode
  mappingStatusFeature?: Boolean
}

const EmployeeLink = ({
  recordLink,
  children,
  mappingStatusFeature
}: Props) => {
  const [hasBeenHovered, setHasBeenHovered] = useState<boolean>(false)

  const onMouseEnter = () => {
    if (!hasBeenHovered) setHasBeenHovered(true)
  }

  const handleClick = async (url: string) => {
    if (!url) return
    const redirectUrl = await getRedirectUrl(url)
    if (redirectUrl) {
      singleSpa.navigateToUrl(redirectUrl)
    } else {
      window.location.href = url
    }
  }

  const fallback = (
    <a
      onMouseEnter={onMouseEnter}
      href={recordLink}
      onClick={async (evt) => {
        if (recordLink) {
          evt.preventDefault()
          handleClick(recordLink)
        }
      }}
      className={mappingStatusFeature ? 'text-link font-semibold' : ''}
    >
      {children}
    </a>
  )

  if (hasBeenHovered) {
    return (
      <PrefetchLink
        url={recordLink}
        fallback={fallback}
        mappingStatusFeature={mappingStatusFeature}
      >
        {children}
      </PrefetchLink>
    )
  }

  return fallback
}

export default EmployeeLink
