import { Translations } from './en-US'

const translations: Translations = {
  aboutThisEmployee: `Sobre este empleado`,
  acaPlan: `Plan de la Ley del Cuidado de Salud a Bajo Precio (ACA)`,
  actions: `Acciones`,
  add: `Agregar`,
  addEmployee: `Agregar empleado`,
  addEmployeeSuccess: `{{ name }} fue contratado exitosamente como un empleado remunerado`,
  addEmail: `+ Añadir correo electrónico`,
  addJob: `Añadir trabajo`,
  addTeamMember: `Añadir miembro del equipo`,
  apply: `Aplicar`,
  back: `Atrás`,
  basicInfo: `Información Básica`,
  benefitPlan: `Plan de beneficios`,
  benefits: `Beneficios`,
  bulkPayChange: `Cambio de pago masivo`,
  business: `Negocio`,
  cancel: `Cancelar`,
  cantReviewDocsNoEmailWarning: `{{ name }} no podrá revisar estos documentos porque no se proporcionó un correo electrónico. Agregue un correo electrónico más tarde para asegurarse de que puedan crear su cuenta y finalizar la incorporación. También puede ingresar su información usted mismo.`,
  cantReviewOnboardingNoEmailWarning: `{{ name }} no podrá completar estas tareas porque no se proporcionó un correo electrónico. Agregue un correo electrónico más tarde para asegurarse de que puedan crear su cuenta y finalizar la incorporación. También puede ingresar su información usted mismo.`,
  createNewProfile: `Agregar empleado a la nómina`,
  chosenName: `Nombre elegido`,
  clear: `Limpiar`,
  clearFilters: `Filtros limpiar`,
  completePayrollAccount: `Cuenta de nómina completa`,
  completePayrollAccountSubtext: `Crear inicio de sesión de nómina`,
  completePersonalInformation: `Información personal completa`,
  completePersonalInformationSubtext: `Dirección, información de contacto, etc.`,
  continue: `Continuar`,
  contractor1099: `Contratista (1099)`,
  contactAdmin: `Por favor contacte al administrador`,
  deleteJob: `Eliminar trabajo`,
  discardExit: `Descartar & salir`,
  documents: `Documentos`,
  employmentDetails: 'Detalles de Empleo',
  taxDesignation: 'Designación fiscal',
  eeoClassification: `Categoría de trabajo EEO-1`,
  eeoClassificationSubtext: `Esto se puede actualizar en el perfil del empleado.`,
  emailAddress: `Dirección de correo electrónico`,
  emailAddressInvalid: `Por favor ingrese su dirección de correo electrónico válida`,
  emailAddressRequired: `Se requiere Dirección de correo electrónico`,
  emailAddressSubtext: `¿No tienes una dirección de correo electrónico?`,
  emailAddressErrorSubtext: `Proporcione una dirección de correo electrónico`,
  employeeLowerCase: `empleado`,
  emailAlreadyInUse: `Esta dirección de correo electrónico ya la utiliza otro empleado`,
  employeeAddedEmailSubtext: `Se envió un correo electrónico de bienvenida a {{ name }} con tareas de incorporación para completar su perfil.`,
  employeeAddedHeader: `{{ name }} fue agregado con éxito`,
  employeeAddedNoEmailSubtext: `Agregue un correo electrónico más tarde para que {{ name }} pueda comenzar su proceso de incorporación.`,
  employeeOnboarding: `Incorporación de empleados`,
  employeeOnboardingSubtext: `Los pasos que completará su empleado cuando inicie sesión por primera vez en Toast Payroll`,
  employeeDetails: `Detalles del empleado`,
  employeeIdAlreadyTaken: `¡La identificación de empleado que ingresó ya estaba en uso! Genere una identificación disponible arriba.`,
  employeeNumber: `Número de identificación del empleado`,
  employeeNumberAlreadyInUse: `Este número de ID de empleado ya está en uso`,
  employeeNumberRegexFailed: `El número de empleado debe tener entre 4 y 8 dígitos`,
  employeeNumberRequired: `Número de empleado es requerido`,
  employeeNumberSubtext: `Autocompletado con el siguiente ID de empleado disponible`,
  employees: `Empleados`,
  employeesLowerCase: `empleados`,
  employeesPerPage: `Empleados por página`,
  employeeW2: `Empleado (W-2)`,
  errorTooltip: `Asegúrese de que su empleado esté asignado o agregado correctamente como empleado de nómina`,
  exitingAddEmployeeBody: `Salir ahora detendrá su progreso y no se guardará.`,
  exitingAddEmployeeHeader: `Vaya, este empleado aún no ha sido agregado.`,
  fieldRequired: `Este campo es obligatorio`,
  fillOutI9: `Complete el I-9 federal`,
  fillOutI9Subtext: `Requerido para comenzar a trabajar`,
  fillOutW4: `Complete el W-4 federal y estatal`,
  fillOutW4Subtext: `Documentos fiscales requeridos`,
  filter: `Filtrar`,
  filters: `Filtros`,
  firstName: `Nombre de pila`,
  firstNameRegexFailed: `Por favor, ingrese un nombre válido`,
  firstNameRequired: `Se requiere el primer nombre`,
  generalJobInfo: `Información general del trabajo`,
  hideDocuments: `Ocultar documentos`,
  hourAbbr: `h`,
  hourly: `Cada hora`,
  hourlyPay: `Pago por hora`,
  hourlyHelpText: 'Pago por hora para todos los trabajos',
  howEmployeeTaxed: `¿Qué tipo de miembro del equipo está agregando?`,
  howEmployeePaid: `¿Cómo se le pagará a {{ name }}?`,
  ignoreEmployee: `Ignorar en la nómina`,
  ignoreEmployeeSuccess: `{{ name }} fue ignorado exitosamente para ser un empleado remunerado`,
  ignoreEmployeeError: `Ignorar los no exitosos.`,
  inviteSentTooltip: `Invitación enviada`,
  isOvertimeEligible: `Is {{ name }} eligible to earn overtime?`,
  itinNotAccepted: `No se aceptan Números de Identificación Personal del Contribuyente`,
  jobsAndPay: `Trabajos y paga`,
  jobTooltip: `El trabajo determina la tasa de pago de un empleado y su capacidad para registrar su entrada y salida para funciones específicas en un dispositivo Toast. Su puesto define cómo se le paga y cómo se le aplican los impuestos, lo cual se puede encontrar en su perfil de empleado de nómina.`,
  lastName: `Apellido`,
  lastNameRegexFailed: `Por favor ingrese un apellido válido`,
  lastNameRequired: `Se requiere apellido`,
  learnMore: `Aprender más`,
  learnMoreDocuments: `Agregar un documento`,
  learnMoreOnboarding: `Obtenga más información sobre la incorporación de nuevos empleados`,
  lengthOfEmployment: `Duración del servicio`,
  location: `Ubicación`,
  mobilePhoneNumber: `Número de teléfono móvil`,
  mergeEmployee: `Asignar perfil de nómina`,
  myEmployees: `Mis empleados`,
  newEmployee: `Nuevo Empleado`,
  no: `No`,
  noEmailTooltip: `Sin correo electrónico`,
  noEmployeeAddedToTeam: `No tienes empleados agregados a tu equipo`,
  noEmployeeAddedToTeamDescription: `Agregue todos los empleados a los que desea pagar en su restaurante`,
  noDocuments: `No hay documentos guardados en Toast Payroll. Agregue documentos para ayudar a los nuevos miembros de su equipo a incorporarse.`,
  onboarding: `Incorporación`,
  onboardingDocuments: `Documentos de incorporación`,
  onboardingDocumentsSubHeader: `A {{ name }} se le asignará revisar estos documentos durante su incorporación.`,
  onboardingTasks: `Tareas de incorporación`,
  onboardingTasksSubHeader: `Estas tareas son necesarias para que cada empleado las complete durante la incorporación en Toast Payroll.`,
  overtimeEligibleHelpText: 'Será un empleado no exento',
  overtimeIneligibleHelpText: 'Serán clasificados como empleados exentos.',
  payGroup: `Grupo de Pago`,
  payGroupLocked: `A un empleado solo se le puede pagar de un grupo de pago por ubicación`,
  payType: `Tipo de Pago`,
  paymentMethod: `Método de pago`,
  payrollInviteTooltip: `Invitar`,
  permissions: `Permisos`,
  personalInformation: `Información personal (dirección, información de contacto)`,
  phoneNumber: `Número de teléfono`,
  phoneNumberInvalid: `El número de teléfono no es válido`,
  posAccessCode: `Código de acceso al POS`,
  posAccessCodeAlreadyInUse: `Este código de acceso al POS ya está en uso`,
  posAccessCodeRegexFailed: `El código de acceso al POS debe tener entre 4 y 8 dígitos`,
  posAccessCodeRequired: `Se requiere código de acceso POS`,
  posAccessCodeSubtext: `Esto está predeterminado en la identificación del empleado, pero puede seleccionar un nuevo código de acceso de 4-8 dígitos`,
  position: `Posicione`,
  positions: `Posiciones`,
  reportEmployeeIncident: `Reportar incidente de empleado`,
  returnToTeamPage: `Volver a la página del equipo`,
  review: `Revisar`,
  reviewAndSignDocuments: `Revisar y firmar documentos.`,
  salary: `Salario`,
  salaryHelpText: 'Pagar un salario anual para todos los trabajos',
  salaryPerPay: 'Salario por periodo de pago',
  securityHelperText: `Esto otorga permiso para Nómina. Los permisos de POS se asignarán automáticamente en función de los trabajos del empleado`,
  securityRole: `Rol de seguridad de nómina`,
  selectACA: `Seleccione un plan de ley del Cuidado de Salud a Bajo Precio`,
  selectAll: `Seleccionar todo`,
  selectBenefitPlan: `Seleccione un plan de beneficios`,
  selectLocations: `seleccionar ubicaciones`,
  selectPaymentMethod: `Seleccione el método de pago`,
  selectPaymentMethodSubtext: `Elija cómo recibir el pago`,
  selectPositions: `Seleccionar posiciones`,
  selectStatuses: `Seleccionar estados`,
  selectTAFW: `Seleccione una política de tiempo fuera del trabajo`,
  selectWLI: `Seleccione una opción de Trabajo e Industrias de Washington`,
  selectWorkersComp: `Seleccione una opción de compensación para trabajadores`,
  send: `Enviar`,
  sendBulkEmailInviteSuccess: `Invitación enviada a {{ employeeCount }} {{ isSingleSuccess }}`,
  sendBulkEmailInviteFailure: `No se pudo invitar a {{ employeeCount }} empleados`,
  sendEmailHeader: `Enviar Correo Electrónico`,
  sendEmailDescription: `¿Estás seguro de que quieres enviar este correo electrónico?`,
  sendEmailInviteSuccess: `Se envió una invitación a {{ employeeName }}`,
  sendEmailInviteFailure: `No se pudo invitar a {{ employeeName }}`,
  socialSecurityNumber: `Número de seguro social`,
  socialSecurityNumberAlreadyInUse: `Este SSN ya está en uso por otro empleado`,
  socialSecurityNumberNotProvided: `Agregue un número de seguro social para evitar cargos al declarar impuestos`,
  socialSecurityNumberRegexFailed: `Ingrese un SSN válido. Los SSN no pueden comenzar con 666, 000 o 9, no pueden tener -00- como el número del medio y deben tener el formato 3-2-4`,
  socialSecurityNumberRequired: `Se requiere número de seguro social`,
  socialSecurityNumberSection: `¿Proporcionará el número de seguro social?`,
  socialSecurityNumberSubtext: `Los SSN se utilizan para la declaración de impuestos y deben ser precisos. Esto puede ser completado más tarde por usted o el empleado.`,
  somethingIsWrong: `Algo está mal`,
  somethingIsWrongSubtext: `No pudimos salvar a este empleado. Inténtalo de nuevo`,
  startDate: `Fecha de contratación`,
  startDateSixMonths: `Elija una fecha dentro de los próximos 6 meses`,
  startDateSubtext: `Esta es la primera fecha en que su empleado será elegible para recibir pago y/o, si corresponde, beneficios.`,
  status: `Estado`,
  submit: `Enviar`,
  successfullyCopied: `Copiado correctamente al portapapeles`,
  taxationTypeRequired: `Este campo es obligatorio`,
  team: `Equipo`,
  teamActions: `Acciones de equipo`,
  tellUsAboutThisTeamMember: `Cuéntanos sobre este miembro del equipo`,
  terminated: `Terminado`,
  tafwPolicy: `Política de tiempo fuera del trabajo`,
  training: `Capacitación`,
  tryAgain: `Intentar otra vez`,
  unpaidEmployee: `Empleado no pagado`,
  unmappedEmployeeError: `{{ docCount }} empleado no está configurados para recibir pagos a través de Nómina y no aparecerán en búsquedas ni informes.`,
  unmappedEmployeesError: `{{ docCount }} empleados no están configurados para recibir pagos a través de Nómina y no aparecerán en búsquedas ni informes.`,
  viewProfileTooltip: `Ver`,
  viewDocuments: `Ver documentos`,
  viewEmployeeProfile: `Ver el perfil de empleado de {{ name }}`,
  w4TaxDocument: `Documento fiscal W-4`,
  willYouProvideEmail: `¿Proveerás una dirección de correo electrónico?`,
  willYouProvideEmailSubtext: `Incluye la dirección de correo electronico de tu empleado para que puedan acceder, self-onboard, y optar por recibir formularios de impuestos electrónicos`,
  willYouProvideSsn: `¿Proveerás un número de seguro social?`,
  willYouProvideSsnSubtext: `El número de seguro social es esencial para la declaración de impuestos`,
  willProvideYesSsnToggle: `Sí, lo tengo`,
  willProvideNoSsnToggle: `No, mi empleado o equipo lo entrará luego`,
  wli: `Industrias y trabajo de Washington`,
  workersComp: `Compensación de trabajadores`,
  workTaxLocation: `Ubicación impuesto del trabajo`,
  workTaxLocationLocked: `Un empleado solo puede tener una ubicación de impuestos laborales por grupo de pago`,
  yearAbbr: `en`,
  yes: `Sí`,
  pleaseRetry: 'Vuelve a cargar la página e inténtalo de nuevo.',
  hmmSomethingWentWrong: "Hmm... No pudimos cargar esta página'.",
  employeeTable: {
    actions: {
      invite: 'Invitar',
      displayPosition: 'Mostrar Posición'
    },
    header: {
      checkboxLabel: 'Seleccionar todo',
      name: 'Nombre',
      job: 'Trabajo',
      position: 'Posición'
    },
    bulkSelectAlert: {
      employeesSelected: 'empleados seleccionados.',
      invite: 'Invitar'
    },
    emailModal: {
      resultAlert: {
        couldNotInviteSingle: 'No se pudo invitar a {{ name }}',
        couldNotInviteMultiple: 'No se pudo invitar a {{ count }} empleados',
        sentInvitePartial:
          'Se enviaron invitaciones a {{ successCount }} empleados. {{ failureCount }} no se pudo enviar.',
        sentInviteSingle: 'Se envió la invitación a {{ Name }}',
        sentInviteMultiple: 'Se enviaron invitaciones a {{ count }} empleados'
      },
      excludedAlertSingle:
        '1 empleado excluido por falta de correo electrónico',
      excludedAlertMultiple:
        '{{ count }} empleados excluidos por falta de correo electrónico',
      header: {
        noEmployees:
          'No se encontraron empleados con correos electrónicos activos',
        sendInviteSingle: '¿Desea enviar invitación a 1 empleado?',
        sendInviteMultiple:
          '¿Desea enviar invitaciones a {{ count }} empleados?'
      },
      body: {
        noEmployees:
          'Seleccione al menos un empleado con un correo electrónico activo',
        numSentActiveSingleTerminatedSingle:
          'Se enviará una invitación a 1 empleado activo y 1 empleado despedido para acceder a su perfil de nómina.',
        numSentActiveSingleTerminatedMultiple:
          'A 1 empleado activo y {{ countTerminated }} empleados despedidos se les enviará una invitación para acceder a su perfil de nómina.',
        numSentActiveMultipleTerminatedSingle:
          'Se enviará una invitación a {{ countActive }} empleados activos y 1 empleado despedido para acceder a su perfil de nómina.',
        numSentActiveMultipleTerminatedMultiple:
          'Se enviará una invitación a {{ countActive }} empleados activos y {{ countTerminated }} despedidos para acceder a su perfil de nómina.',
        numSentTerminatedSingle:
          'A 1 empleado despedido se le enviará una invitación para acceder a su perfil de nómina.',
        numSentTerminatedMultiple:
          'A {{ count }} empleados despedidos se les enviará una invitación para acceder a su perfil de nómina.',
        numSentSingle:
          'Se enviará una invitación a 1 empleado para acceder a su perfil de nómina',
        numSentMultiple:
          'Se enviará una invitación a {{ count }} empleados para acceder a su perfil de nómina'
      },
      footer: {
        cancel: 'Cancelar',
        sendInviteSingle: 'Enviar 1 invitación',
        sendInviteMultiple: 'Enviar {{ count }} invitaciones'
      }
    }
  }
}

export { translations }
